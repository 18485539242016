export default class LobbyMutator {
  private _session: any;
  private _lobbyID: string;
  constructor(session: any, lobby_id: string) {
    this._session = session;
    this._lobbyID = lobby_id;
  }

  async addBot(bot_name: string) {
    return await callLobbyApi(this._lobbyID, 'add_bot', { bot_name });
  }
  // returns gameID
  async startGame(): Promise<string> {
    const res = await callLobbyApi(this._lobbyID, 'start', {});
    return res.gameID as string;
  }
  async setGameOption(option: string, value: any) {
    return await callLobbyApi(this._lobbyID, 'set_game_option', {
      option,
      value,
    });
  }
  async setModule(module: string, enabled: boolean) {
    return await callLobbyApi(this._lobbyID, 'set_module', { module, enabled });
  }
  async joinGame() {
    return await callLobbyApi(this._lobbyID, 'join', {});
  }
  async leaveLobby() {
    return await callLobbyApi(this._lobbyID, 'leave', {});
  }
}

async function callLobbyApi(
  lobby_id: string,
  method: string,
  params: any,
): Promise<any> {
  const res = await fetch(`/api/lobby/${lobby_id}/${method}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });

  if (!res.ok) {
    throw new Error('Error adding calling lobby ' + method + ': ' + res.text);
  }
  return await res.json();
}

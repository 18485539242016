/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import React, { useCallback } from 'react';
import Symbols, { SymbolView } from './Symbols';
import { local } from 'd3';

const Links = [
  { url: '/rules', label: 'Game Rules' },
  { url: '/all-cards', label: 'Cards List' },
  { url: '/all-cards#tributes', label: 'Tributes List' },
];

export default function CollapsibleGameLinksView(props: {}) {
  const [collapsed, setCollapsed] = React.useState(
    localStorage.getItem('CollapsibleGameLinksView_Collapsed') === 'true' ||
      false,
  );
  const onClick = useCallback(() => {
    localStorage.setItem(
      'CollapsibleGameLinksView_Collapsed',
      (!collapsed).toString(),
    );
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  let expandedBody: React.ReactNode = !collapsed && (
    <div css={ViewStyles.list}>
      {Links.map((definition, i) => {
        return (
          <a
            key={i}
            target="_blank"
            href={definition.url}
            css={ViewStyles.link}
          >
            {definition.label}
          </a>
        );
      })}
    </div>
  );

  const symbol_type = collapsed ? Symbols.ARROW_EXPAND : Symbols.ARROW_COLLAPSE;
  return (
    <div css={ViewStyles.container}>
      <span
        onClick={onClick}
        css={[ViewStyles.header, collapsed ? null : ViewStyles.headerExpanded]}
      >
        Mythos Info
        <span css={ViewStyles.button}>
          <SymbolView symbol={symbol_type} css={ViewStyles.symbol} />
        </span>
      </span>
      {expandedBody}
    </div>
  );
}

const ViewStyles = {
  container: css({
    marginBottom: 5,

    fontSize: 13,

    color: 'rgb(231, 231, 231)',
    backgroundColor: 'rgb(91, 91, 91)',
  }),
  list: css({
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
  }),
  link: css({
    margin: 3,

    textDecoration: 'underline',

    color: 'rgb(254, 198, 10)',
  }),
  button: css({
    paddingRight: 5,
  }),
  header: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: 5,

    fontSize: 17,

    backgroundColor: 'rgb(74, 74, 74)',
  }),
  headerExpanded: css({
    borderBottom: '1px solid rgb(151, 151, 151)',
  }),
  symbol: css({
    height: 17,
    width: 17,
  }),
} as const;

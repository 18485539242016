/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';

import ActionStore from './ActionStore';
import GameView from './GameView';
import {
  fetchGame,
  fetchUsers,
  storeGame,
  subscribeToChannelTopic,
  unsubscribeToChannelTopic,
} from './actions';
import Session from './Session';
import { InflatedGame } from '../game/Game';

import AffirmSFX from '../assets/melodic2_affirm.wav';
import NewAgeSFX from '../assets/gong-swells-mkii-38714.mp3';
import { useParams } from 'react-router-dom';
import ChatOverlay from './ChatOverlay';

function createActionStore(
  session: Session | null,
  game: InflatedGame | null,
): ActionStore | null {
  if (!session) {
    return null;
  }
  if (!game) {
    return null;
  }
  const has_session_user = game.players.some(
    (player) => player.userID === session.getUserID(),
  );
  if (!has_session_user) {
    return null;
  }
  return new ActionStore(game, session.getUserID());
}

interface Props {
  dispatch: any;
  gameByID: Map<string, InflatedGame>;
  userByID: any;
  session: Session;
}

function GamePage(props: Props) {
  const { dispatch, gameByID, userByID, session } = props;
  const gameID = useParams().gameID!;
  const game = gameByID.get(gameID) || null;

  useEffect(() => {
    dispatch(fetchGame(gameID, null));
  }, [gameID]);

  const actionStore = useMemo(
    () => createActionStore(session, game),
    [session, game !== null],
  );

  useEffect(() => {
    if (actionStore && game) {
      actionStore.setGame(game);
    }
  }, [actionStore, game]);

  useEffect(() => {
    const topic = getChannelTopic(session, game, gameID);
    const subscription = dispatch(
      subscribeToChannelTopic(topic, (message) => {
        const game = message.data;
        dispatch(storeGame(game));
      }),
    );
    return () => {
      unsubscribeToChannelTopic(subscription);
    };
  }, [session, game, gameID]);

  const userIDs = useMemo(
    () => game?.players.map((player) => player.userID) || [],
    [game],
  );
  const fetchedUserIDs = useRef(new Set<string>()).current;
  useEffect(() => {
    let missing_ids = userIDs.filter((user_id) => {
      return !userByID[user_id] && !fetchedUserIDs.has(user_id);
    });
    if (missing_ids.length === 0) {
      return;
    }

    props.dispatch(fetchUsers(missing_ids));
    missing_ids.forEach((id) => fetchedUserIDs.add(id));
  }, [userByID, userIDs]);

  const lastPhaseRef = useRef(game?.phase);
  const lastAgeRef = useRef(game?.age);
  useEffect(() => {
    if (lastAgeRef.current !== undefined && lastAgeRef.current !== game?.age) {
      const audio = new Audio(NewAgeSFX);
      audio.volume = 0.3;
      audio.play();
    } else if (
      lastPhaseRef.current !== undefined &&
      game?.phase !== lastPhaseRef.current
    ) {
      new Audio(AffirmSFX).play();
    }
    lastPhaseRef.current = game?.phase;
    lastAgeRef.current = game?.age;
  }, [game?.phase, game?.age]);

  if (!game || userIDs.some((user_id) => !userByID[user_id])) {
    return <div className="game-loading-view">Loading...</div>;
  }

  return (
    <>
      <GameView
        key={game.id}
        actionStore={actionStore || undefined}
        game={game}
        session={session}
        userByID={userByID}
      />
      <ChatOverlay />
    </>
  );
}

function getChannelTopic(
  session: Session | null,
  game: InflatedGame | null,
  gameID: string,
): string {
  let topicPrefix = '';
  if (session) {
    const userID = session.getUserID();
    if (game) {
      if (game.players.some((player) => player.userID === userID)) {
        topicPrefix = `inbox/${userID}/`;
      }
    }
  }
  return topicPrefix + `game/${gameID}`;
}

function select(state) {
  return {
    session: state.session,
    userByID: state.userByID.toJSON(),
    gameByID: state.gameByID,
  };
}

export default connect(select)(GamePage);

/** @jsx jsx */
/** @jsxFrag */
import { jsx, css, SerializedStyles } from '@emotion/react';
import { times } from 'underscore';
import React, { ReactElement, useRef } from 'react';

import BaseCardView from './BaseCardView';
import { CardDef } from '../game/GameModel';
import { ageString } from '../game/Utility';
import { CardSubType, CardType } from '../game/CardTypes';
import {
  CardTypeToSymbol,
  CardSubtypeToSymbol,
  ResourceToSymbol,
  BaseResourceToSymbol,
} from './Symbols';

import CardBGResource from '../assets/CardBackgroundResource.jpg';
import CardBGConflict from '../assets/CardBackgroundConflict.jpg';
import CardBGPrayer from '../assets/CardBackgroundPrayer.jpg';
import CardBGTribute from '../assets/CardBackgroundTribute.jpg';
import CardBGBasic from '../assets/CardBackgroundBasicResource.jpg';

import UpgradeBorderResource from '../assets/generated/UpgradeBorderResource.svg';
import UpgradeBorderConflict from '../assets/generated/UpgradeBorderConflict.svg';
import UpgradeBorderPrayer from '../assets/generated/UpgradeBorderPrayer.svg';
import UpgradeBorderLeader from '../assets/generated/UpgradeBorderLeader.svg';
import UpgradeBorderBasic from '../assets/generated/UpgradeBorderBasic.svg';
const CardTypeToUpgradeBorder: Record<CardType, string> = {
  [CardType.Resource]: UpgradeBorderResource,
  [CardType.Conflict]: UpgradeBorderConflict,
  [CardType.Prayer]: UpgradeBorderPrayer,
  [CardType.Leader]: UpgradeBorderLeader,
  [CardType.Basic]: UpgradeBorderBasic,
};

import {
  AbilityOutput,
  BaseCardAbility,
  BaseCountersAbility,
  CardAdditionalCosts,
  DraftCardAbility,
  EachTurnAbility,
  GainOtherCardAbility,
  GainSelfAbility,
  getAdditionalCosts,
  getBaseCountersAbility,
  getDraftCardAbility,
  getEachTurnCardAbility,
  getGainOtherCardAbility,
  getGainSelfCardAbility,
  abilityUpgradeConditionToString,
} from '../game/CardAbilities';
import { P, match } from 'ts-pattern';
import Symbols, { SymbolView } from './Symbols';
import invariant from 'invariant';
import nullthrows from 'nullthrows';
import CardBanner from './CardBanner';
import { AllResourceEffectPill, EffectPill } from './CardHelpers';
import { CardTypeToFrameColor } from './CardTypeToFrameColor';
import { Token } from '../game/Resources';
import { CardArt } from '../generated/CardArt';
import _ from 'underscore';
import { nonNull } from '../utils/utils';

export type CardEventFunc<TCard> = (card: TCard, event: MouseEvent) => void;
export type CardRenderer = (props: Props) => ReactElement;

interface Props<TCard extends CardDef = CardDef> {
  onCardEnter?: CardEventFunc<TCard>;
  onCardLeave?: CardEventFunc<TCard>;
  onLeftClick?: CardEventFunc<TCard>;
  onRightClick?: CardEventFunc<TCard>;

  card: TCard;

  overlayStyle?: React.CSSProperties;
  overlayChildren?: React.ReactNode;
  overlayCSS?: SerializedStyles;
}

const CardView = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { card, overlayStyle, overlayCSS, overlayChildren } = props;

  const handleMouseEnter = (e) => {
    props.onCardEnter && props.onCardEnter(card, e);
  };

  const handleMouseLeave = (e) => {
    props.onCardLeave && props.onCardLeave(card, e);
  };

  const handleLeftClick = (e) => {
    props.onLeftClick && props.onLeftClick(card, e);
  };

  const handleRightClick = (e) => {
    if (props.onRightClick) {
      e.preventDefault();
      props.onRightClick(card, e);
    }
  };

  let card_style: React.CSSProperties = {
    backgroundImage: `url(${CardTypeToBackgroundImage[card.type]})`,
  };

  const art = card.imageName && CardArt[card.imageName];
  let body_style: React.CSSProperties = {
    backgroundImage: (art && `url(${art})`) || undefined,
    position: 'relative',
    border: `1px solid ${CardTypeToFrameColor[card.type]}`,
  };

  let title_view = (
    <div css={CardStyle.name}>
      <div
        css={CardStyle.innerBorder}
        style={{
          border: `1px solid ${CardTypeToFrameColor[card.type]}`,
        }}
      />

      <CardBanner
        css={CardStyle.goldBanner}
        borderColor={CardTypeToFrameColor[card.type]}
        // backgroundColor="rgba(0, 0, 0, 0.5)"
        // backgroundColor={CardTypeToFrameColor[card.type]}
        // backgroundColor="rgba(255, 255, 255, 0.25)"
        // backgroundColor="rgba(0, 0, 0, 0.25)"
        bannerType="left-top-shoulder"
      >
        <EffectPill
          css={CardStyle.titleGoldPill}
          output={{ outputResource: 'gold', outputRatio: card.cost }}
          hideChrome
        />
      </CardBanner>

      {card.name}

      <CardBanner
        css={CardStyle.favorBanner}
        borderColor={CardTypeToFrameColor[card.type]}
        bannerType="right-top-shoulder"
        // backgroundColor={CardTypeToFrameColor[card.type]}
        // backgroundColor="rgba(0, 0, 0, 0.25)"
      >
        <EffectPill
          css={CardStyle.titleFavorPill}
          output={{ outputResource: 'favor', outputRatio: card.favor }}
          hideChrome
        />
      </CardBanner>
    </div>
  );

  return (
    <BaseCardView
      className={'card-' + card.type.toLowerCase()}
      ref={ref}
      bodyStyle={body_style}
      cardStyle={card_style}
      overlayStyle={overlayStyle}
      overlayCSS={overlayCSS}
      overlayChildren={overlayChildren}
      titleView={title_view}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onLeftClick={handleLeftClick}
      onRightClick={handleRightClick}
    >
      <ExtraCostsView
        borderColor={CardTypeToFrameColor[card.type]}
        additionalCosts={getAdditionalCosts(card)}
      />
      <GainSelfEffect
        ability={getGainSelfCardAbility(card)}
        borderColor={CardTypeToFrameColor[card.type]}
      />
      <div style={{ flexGrow: 1 }}></div>

      <CardFooter card={card} />
      {(card.age && (
        <CardBanner
          css={CardStyle.age}
          borderColor={CardTypeToFrameColor[card.type]}
          bannerType="right-bottom-shoulder"
        >
          <div css={CardStyle.ageText}>{ageString(card.age)}</div>
        </CardBanner>
      )) ||
        null}
    </BaseCardView>
  );
});
export function BasicCardRenderer(props: Props) {
  return <CardView {...props} />;
}
export default CardView;

const CardTypeToBackgroundImage = {
  [CardType.Basic]: CardBGBasic,
  [CardType.Resource]: CardBGResource,
  [CardType.Conflict]: CardBGConflict,
  [CardType.Prayer]: CardBGPrayer,
  [CardType.Leader]: CardBGTribute,
} as const;

function CardFooterTypeView(props: { card: CardDef }) {
  const symbol = CardTypeToSymbol[props.card.type];
  return (
    <CardBanner
      css={CardStyle.footerCardTypeBanner}
      bannerType="right-bottom-shoulder"
      borderColor={CardTypeToFrameColor[props.card.type]}
    >
      <SymbolView symbol={symbol} css={CardStyle.footerCardType} />
    </CardBanner>
  );
}
function CardFooterSubTypeView(props: { card: CardDef }) {
  const symbol = CardSubtypeToSymbol[props.card.subType];
  return (
    <CardBanner
      css={CardStyle.footerCardSubTypeBanner}
      bannerType="left-bottom-shoulder"
      borderColor={CardTypeToFrameColor[props.card.type]}
      backgroundColor="rgb(160, 160, 160)"
    >
      <SymbolView symbol={symbol} css={CardStyle.footerCardSubType} />
    </CardBanner>
  );
}

function CardFooter(props: { card: CardDef }) {
  const frameColor = CardTypeToFrameColor[props.card.type];
  const abilities = [
    getBaseCountersAbility(props.card),
    getEachTurnCardAbility(props.card),
    getDraftCardAbility(props.card),
    getGainOtherCardAbility(props.card),
  ].filter(nonNull);
  const effects = abilities.map((ability, i) => {
    return <CardEffect key={i} cardType={props.card.type} ability={ability} />;
  });
  if (effects.length === 0) {
    effects.push(<EmptyCardEffect cardType={props.card.type} />);
  }
  return (
    <div
      css={CardStyle.footer}
      style={{
        borderTop: `1px solid ${frameColor}`,
      }}
    >
      <div
        css={CardStyle.footerAbilities}
        style={CardTypeToEffectBGStyle[props.card.type]}
      >
        {effects}
      </div>
      <CardFooterTypeView card={props.card} />
      <CardFooterSubTypeView card={props.card} />
    </div>
  );
}

const CardTypeToEffectBGStyle = {
  [CardType.Basic]: {
    backgroundColor: 'rgba(230, 213, 191, 0.8)',
  },
  [CardType.Resource]: {
    backgroundColor: 'rgba(202, 227, 190, 0.8)',
  },
  [CardType.Conflict]: {
    backgroundColor: 'rgba(239, 200, 191, 0.8)',
  },
  [CardType.Prayer]: {
    backgroundColor: 'rgba(192, 219, 232, 0.8)',
  },
  [CardType.Leader]: {
    backgroundColor: 'rgba(199, 199, 199, 0.8)',
  },
};

const CardStyle = {
  name: css({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'left',
    // borderRadius: 3,
    backgroundColor: 'rgba(218, 218, 218, 0.85)',

    margin: 3,
    marginTop: 2,
    marginBottom: 0,
    paddingLeft: 16,
    paddingRight: 16,

    height: 14,
    // width: 84,

    flex: '0 0 auto',
    position: 'relative',

    fontFamily: 'Mythos-CardTitle',
    fontSize: 6,
  }),
  innerBorder: css({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: '1px solid rgba(0, 0, 0, 0.5)',
    // zIndex: -1,
  }),
  favorBanner: css({
    position: 'absolute',
    right: -3,
    top: -1,
  }),
  goldBanner: css({
    // display: 'none',
    position: 'absolute',
    top: -1,
    left: -3,
  }),
  titleFavorPill: css({
    margin: 1,
    marginTop: 2,
    marginBottom: 2,
    marginLeft: 2,
  }),
  titleGoldPill: css({
    margin: 1,
    marginTop: 2,
    marginBottom: 2,
    marginRight: 2,
  }),
  age: css({
    position: 'absolute',
    bottom: 7,
    right: -4,
    paddingRight: 2,
    paddingLeft: 3,
    paddingTop: 1.5,
    paddingBottom: 2.5,
  }),
  ageText: css({
    textAlign: 'center',
    width: 5,
    height: 4,
    fontSize: 5,
    fontWeight: 'bold',
    color: 'black',
    // textShadow: '0px 0px 2px black',
  }),
  footer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'rgba(219, 219, 219, 0.8)',

    // ...OverlayCardBGStyle,
  }),
  footerCardTypeBanner: css({
    position: 'absolute',
    right: -4,
    bottom: -4.5,
  }),
  footerCardSubTypeBanner: css({
    position: 'absolute',
    left: -4,
    bottom: -4.5,
  }),
  footerCardType: css({
    fontSize: 8,
    height: 8,
    width: 9,
    padding: 1,
    paddingLeft: 1.5,
    paddingRight: 0.5,
  }),
  footerCardSubType: css({
    fontSize: 8,
    height: 8,
    width: 9,
    padding: 1,
    paddingLeft: 0.5,
    paddingRight: 1.5,
  }),
  footerAbilities: css({
    display: 'flex',
    flexWrap: 'wrap-reverse',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }),
} as const;

export function ExtraCostsView(props: {
  borderColor: string;
  additionalCosts?: CardAdditionalCosts | null;
}) {
  const { borderColor, additionalCosts } = props;
  if (!additionalCosts) {
    return null;
  }

  return (
    <div css={ExtraCostsStyles.container}>
      {additionalCosts.warTokens > 0 && (
        <CardBanner
          css={ExtraCostsStyles.banner}
          borderColor={borderColor}
          backgroundColor="rgba(210, 210, 210, 0.25)"
          bannerType="left"
        >
          <EffectPill
            hideChrome
            output={{
              outputResource: 'warTokens',
              outputRatio: additionalCosts.warTokens,
            }}
            css={ExtraCostsStyles.pill}
          />
        </CardBanner>
      )}
    </div>
  );
}
const ExtraCostsStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',

    position: 'absolute',
    top: 0,
    left: -4,
  }),
  banner: css({}),
  pill: css({
    margin: 1,
  }),
};

export function GainSelfEffect(props: {
  className?: string;
  style?: React.CSSProperties;
  ability?: GainSelfAbility | null;
  borderColor: string;
}) {
  const { className, style, ability, borderColor } = props;
  if (!ability) {
    return null;
  }
  return (
    <div
      css={GainSelfEffectStyles.container}
      className={className}
      style={style}
    >
      {ability.outputs.map((output, i) => (
        <CardBanner
          key={i}
          css={GainSelfEffectStyles.banner}
          bannerType="right"
          borderColor={borderColor}
          backgroundColor="rgba(210, 210, 210, 0.25)"
          // backgroundColor="rgba(0, 0, 0, 0.5)"
        >
          <EffectPill
            output={output}
            hideChrome
            css={GainSelfEffectStyles.pill}
          />
        </CardBanner>
      ))}
    </div>
  );
}
const GainSelfEffectStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
  }),
  banner: css({
    // marginRight: -0.5,
    marginRight: -4,
  }),
  pill: css({
    margin: 1,
  }),
};

export function CardEffect(props: {
  ability: BaseCardAbility | null;
  cardType: CardType;
}): JSX.Element | null {
  const { ability } = props;
  if (!ability) {
    return null;
  }

  let multiresourcePill: JSX.Element | null = null;
  let containerCSS: SerializedStyles | undefined =
    CardEffectStyles.clippedContainer;
  let containerStyle: React.CSSProperties | undefined = {
    backgroundColor: CardTypeToFrameColor[props.cardType],
  };
  let abilitiesByUpgradeCondition = _.groupBy(
    ability.outputs,
    (abilityOutput) => {
      if (!abilityOutput.upgradeCondition) {
        return '';
      }
      return abilityUpgradeConditionToString(abilityOutput.upgradeCondition);
    },
  );
  let effectSections = _.map(
    abilitiesByUpgradeCondition,
    (abilityOutputs, upgradeConditionString) => {
      const prefix = match(ability)
        .when(
          (a) => a instanceof GainSelfAbility,
          () => {
            invariant(false, 'unhandled GainSelfAbility');
          },
        )
        .when(
          (a) => a instanceof GainOtherCardAbility,
          (a: GainOtherCardAbility) => {
            containerCSS = css([
              containerCSS,
              CardEffectStyles.clippedContainerOtherAbilities,
            ]);

            if (a.getTargetType() === 'tribute') {
              if (
                abilityOutputs.length === 3 &&
                abilityOutputs.every(
                  (o) =>
                    o.outputRatio === abilityOutputs[0].outputRatio &&
                    !o.multiplier,
                )
              ) {
                multiresourcePill = (
                  <AllResourceEffectPill
                    amount={`${abilityOutputs[0].outputRatio}`}
                  />
                );
              }
              return (
                <EffectTriggerPrefix symbol={Symbols.M3_ACQUIRE_TRIBUTE} />
              );
            } else if (a.getTargetType() === Token.War) {
              multiresourcePill = (
                <>
                  {abilityOutputs.map((output, i) => {
                    return <EffectPillOrSymbols key={i} output={output} />;
                  })}
                </>
              );
              return <EffectTriggerPrefix symbol={Symbols.ACQUIRE_TOKEN_WAR} />;
            } else {
              return <EffectTriggerPrefix symbol={Symbols.M3_ACQUIRE} />;
            }
          },
        )
        .when(
          (a) => a instanceof EachTurnAbility,
          () => {
            multiresourcePill = (
              <>
                {abilityOutputs.map((output, i) => {
                  return <EffectPillOrSymbols key={i} output={output} />;
                })}
              </>
            );
            return <EffectTriggerPrefix symbol={Symbols.EFFECT_PRODUCTION} />;
          },
        )
        .when(
          (a) => a instanceof DraftCardAbility,
          (a: DraftCardAbility) => {
            const symbol = match(a.getDraftTarget)
              .with(CardType.Conflict, () => Symbols.M3_DRAFT_CONFLICT)
              .with(CardType.Resource, () => Symbols.M3_DRAFT_RESOURCE)
              .with(CardType.Prayer, () => Symbols.M3_DRAFT_PRAYER)
              .otherwise(() => {
                console.warn('no symbol for', a.getDraftTarget);
                return null;
              });
            return symbol && <EffectTriggerPrefix symbol={symbol} />;
          },
        )
        .with(P.instanceOf(BaseCountersAbility), () => {
          containerCSS = undefined;
          containerStyle = undefined;
          multiresourcePill = (
            <>
              {abilityOutputs.map((output, i) => {
                invariant(!output.multiplier, 'unexpected base multiplier');

                return times(output.outputRatio, (j) => (
                  <SymbolView
                    key={j}
                    symbol={nullthrows(
                      BaseResourceToSymbol[output.outputResource],
                    )}
                    css={CardEffectStyles.baseSymbol}
                  />
                ));
              })}
            </>
          );
          return null;
        })
        .otherwise(() => {
          console.warn('no prefix for', ability);
          return null;
        });

      const outputs = abilityOutputs;
      const pills =
        multiresourcePill ??
        outputs.map((output, i) => <EffectPill key={i} output={output} />);
      const upgradeCondition = abilityOutputs[0].upgradeCondition;
      let cardEffect = (
        <div
          css={[CardEffectStyles.cardEffect, containerCSS]}
          style={containerStyle}
        >
          {prefix}
          {pills}
        </div>
      );

      if (upgradeCondition) {
        const upgradeAmount = upgradeCondition.upgradeAmount;
        const upgradeType = upgradeCondition.upgradeType;
        const upgradeSymbol = match(upgradeType)
          .with(CardType.Conflict, () => Symbols.CARD_TYPE_CONFLICT)
          .with(CardType.Resource, () => Symbols.CARD_TYPE_RESOURCE)
          .with(CardType.Prayer, () => Symbols.CARD_TYPE_PRAYER)
          .with(CardSubType.Unit, () => Symbols.CARD_SUBTYPE_UNIT)
          .with(CardSubType.Building, () => Symbols.CARD_SUBTYPE_BUILDING)
          .with(CardSubType.Event, () => Symbols.CARD_SUBTYPE_EVENT)
          .with(Token.War, () => Symbols.TOKEN_WAR)
          .otherwise(() => {
            invariant(false, 'unhandled upgrade type');
          });

        const borderImage = CardTypeToUpgradeBorder[props.cardType];

        return (
          <div
            css={CardEffectStyles.cardEffectBar}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }}
          >
            <img src={borderImage} css={CardEffectStyles.upgradeBorder} />
            <div css={CardEffectStyles.upgradeCondition}>
              <SymbolView
                symbol={Symbols.UPGRADE}
                css={CardEffectStyles.upgradeSymbol}
              />
              {upgradeAmount}
              <SymbolView
                symbol={upgradeSymbol}
                css={CardEffectStyles.upgradeSymbol}
              />
            </div>
            {cardEffect}
          </div>
        );
      }

      return <div css={CardEffectStyles.cardEffectBar}>{cardEffect}</div>;
    },
  );
  if (!abilitiesByUpgradeCondition['']) {
    effectSections.unshift(<div css={CardEffectStyles.cardEffectBar} />);
  }
  return <div css={CardEffectStyles.cardEffectArea}>{effectSections}</div>;
}
function EmptyCardEffect(props: { cardType: CardType }) {
  return (
    <div css={CardEffectStyles.cardEffectArea}>
      <div css={CardEffectStyles.cardEffectBar} />
    </div>
  );
}
function EffectTriggerPrefix(props: { symbol: Symbols; className?: string }) {
  return (
    <>
      <SymbolView
        className={props.className}
        symbol={props.symbol}
        css={CardEffectStyles.triggerSymbol}
      />
      <span css={CardEffectStyles.triggerColon}>:</span>
    </>
  );
}

const CardEffectClipPath = `polygon(
    0% 50%,
    var(--notchSize) 0%,
    calc(100% - var(--notchSize)) 0%,
    100% 50%,
    calc(100% - var(--notchSize)) 100%,
    var(--notchSize) 100%
  )`;

const CardEffectStyles = {
  upgradeSymbol: css({
    height: 5,
    width: 'auto',
    paddingTop: 1,
    paddingLeft: 1,
    paddingRight: 1,
    paddingBottom: 0.5,
  }),
  upgradeBorder: css({
    height: 'auto',
    width: '100%',
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
  }),
  upgradeCondition: css({
    position: 'absolute',
    left: -0,
    paddingLeft: 0,
    paddingRight: 1,
    paddingTop: 0.5,
    top: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 6,
    width: 'fit-content',
    alignItems: 'flex-end',
  }),
  cardEffectArea: css({
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'stretch',
    width: '100%',
  }),
  cardEffectBar: css({
    height: '100%',
    position: 'relative',
    padding: 1,
    minHeight: 16,
  }),
  cardEffect: css({
    display: 'flex',
    alignItems: 'center',
    padding: 2,
    // marginBottom: -1,
    height: '100%',
    width: 'fit-content',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  }),
  clippedContainer: css({
    '--notchSize': '3px',
    clipPath: CardEffectClipPath,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    position: 'relative',

    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 2,
    paddingBottom: 2,

    '&::before': {
      '--notchSize': '2.85px',
      clipPath: CardEffectClipPath,
      background:
        'linear-gradient(180deg, rgba(228,228,228,1) 0%, rgba(220,220,220,1) 74%, rgba(176,176,176,1) 100%);',
      content: '""',

      position: 'absolute',
      left: 1,
      top: 1,
      right: 1,
      bottom: 1,

      zIndex: -1,
    },
  }),
  clippedContainerOtherAbilities: css({
    '&::before': {
      background:
        'linear-gradient(180deg, rgba(255,225,164,1) 0%, rgba(182,139,74,1) 100%);',
    },
  }),

  triggerSymbol: css({
    height: 8.5,
    width: 'auto',
    marginRight: 0,
    marginLeft: 1,
  }),
  triggerColon: css({
    fontSize: 8,
    marginRight: 1,
    verticalAlign: 'middle',
  }),
  resourceContainer: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  baseSymbol: css({
    height: 12,
    width: 'auto',
  }),
  resourceSymbol: css({
    height: 12,
    width: 'auto',
    // marginLeft: -4,
    // '&:first-of-type': {
    //   marginLeft: 0,
    // },
  }),
};

function EffectPillOrSymbols(props: { output: AbilityOutput }) {
  const output = props.output;
  if (output.multiplier) {
    return <EffectPill output={output} />;
  }
  return (
    <div css={CardEffectStyles.resourceContainer}>
      {times(output.outputRatio, (j) => {
        return (
          <SymbolView
            key={j}
            symbol={ResourceToSymbol[output.outputResource]}
            css={CardEffectStyles.resourceSymbol}
          />
        );
      })}
    </div>
  );
}

/** @jsx jsx */
/** @jsxFrag */
import { css, jsx, SerializedStyles } from '@emotion/react';

export default function LobbyButton(props: {
  className?: string;
  children: React.ReactNode;
  onClick?: React.DOMAttributes<HTMLButtonElement>[`onClick`];
  secondary?: boolean;
}) {
  const css: SerializedStyles[] = [];
  css.push(LobbyButtonStyles.button);
  if (props.secondary) {
    css.push(LobbyButtonStyles.secondary);
  }
  return (
    <button className={props.className} css={css} onClick={props.onClick}>
      {props.children}
    </button>
  );
}

export const LobbyButtonStyles = {
  button: css({
    backgroundColor: 'rgb(227, 198, 101)',
    paddingLeft: 50,
    paddingRight: 50,
    minWidth: 160,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 2,
    border: 'none',
    borderBottom: '2px solid rgb(177, 148, 51)',
    outline: 'none',
    '&:hover': {
      backgroundColor: 'rgb(217, 188, 91)',
      borderColor: 'rgb(167, 138, 41)',
    },
    '&:active': {
      backgroundColor: 'rgb(207, 178, 71)',
      borderColor: 'rgb(157, 128, 31)',
    },
  }),
  secondary: css({
    backgroundColor: 'rgb(195, 195, 195)',
    borderColor: 'rgb(150, 150, 150)',
    '&:hover': {
      backgroundColor: 'rgb(185, 185, 185)',
      borderColor: 'rgb(140, 140, 140)',
    },
    '&:active': {
      backgroundColor: 'rgb(175, 175, 175)',
      borderColor: 'rgb(130, 130, 130)',
    },
  }),
};

import { shuffle } from 'underscore';
import invariant from 'invariant';

export default class Deck<TCard extends { id: string }> {
  private cards_: TCard[];

  constructor() {
    this.cards_ = [];
  }

  cycleUntilCardMeetsCondition(
    condition: (card: TCard) => boolean,
  ): TCard | null {
    let new_card = this.drawOne();
    if (!new_card) {
      return new_card;
    }

    let tries = 0;
    while (!condition(new_card) && tries < this.cards_.length) {
      this.addCardsToBottom([new_card]);
      new_card = this.drawOne()!;
      ++tries;
    }
    return new_card;
  }
  addCardsToTop(cards: TCard[]): void {
    this.cards_ = this.cards_.concat(cards);
  }
  addCardsToBottom(cards: TCard[]): void {
    this.cards_ = cards.concat(this.cards_);
  }
  shuffle(): void {
    this.cards_ = shuffle(this.cards_);
  }
  count(): number {
    return this.cards_.length;
  }
  drawOne(): TCard | null {
    if (this.cards_.length > 0) {
      return this.cards_.pop() || null;
    }
    return null;
  }
  drawAll(): TCard[] {
    let ret = this.cards_;
    this.cards_ = [];
    return ret;
  }
  render(show_deck_info: boolean) {
    if (!show_deck_info) {
      return null;
    }

    let topCardID =
      this.cards_.length > 0 ? this.cards_[this.cards_.length - 1].id : null;

    let sortedCardIDs = this.cards_.map((card) => card.id).sort();

    return {
      size: this.cards_.length,
      topCardID,
      sortedCardIDs,
    };
  }
  serialize(): {
    cardIDs: string[];
  } {
    return {
      cardIDs: this.cards_.map((card) => card.id),
    };
  }
  static deserialize<TCard extends { id: string }>(
    serialized: {
      cardIDs: string[];
    },
    cardsByID: { [k: string]: TCard },
  ): Deck<TCard> {
    let ret = new Deck<TCard>();
    ret.cards_ = serialized.cardIDs.map((id: string) => {
      let c = cardsByID[id];
      invariant(c, 'could not find card with id "%s"', id);
      return c;
    });
    return ret;
  }
}

import _ from 'underscore';

import * as CardAbilities from './CardAbilities';

import Piles, { Pile } from './Piles';
import * as Rules from './Rules';
import * as Utility from './Utility';
import { RenderedPlayer } from './Player';
import { InflatedGame, InflatedPlayer } from './Game';
import { CardSubType } from './CardTypes';

export function cardCompositionCounts(player: InflatedPlayer) {
  let board = player.cards;
  let counts = _.countBy(board, (card) => card.type);
  board.forEach((card) => {
    counts[card.subType] = (counts[card.subType] || 0) + 1;
  });
  return counts;
}

export function totalProductionCounters(context: Rules.Context) {
  const production_context = Rules.handleProduction(context);
  return production_context.production_delta;
}

export type CardCostTuple = [Rules.CardWithID, number];
export function affordableCards(
  context: Rules.Context,
  cards: Rules.CardWithID[],
): {
  affordable: CardCostTuple[];
} {
  // TODO: confirm this includes base
  const production = totalProductionCounters(context);

  let card_cost_tuples: CardCostTuple[] = _.map(cards, (card) => {
    let cost = card.cost;
    return [card, cost];
  });

  const affordable = _.filter(card_cost_tuples, ([_, cost]) => {
    return cost <= production.gold;
  });

  return { affordable };
}

/** @jsx jsx */
/** @jsxFrag */
import { jsx, css } from '@emotion/react';
import _, { times } from 'underscore';
import React, {
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';

import Phases, { Phase, PhaseToBackgroundColor } from '../game/Phases';

import GameStateView from './GameStateView';
import SelfPlayerView from './SelfPlayerView';
import EndOfGameView from './EndOfGameView';
import PlayerSummaryView, { PlayerFavorDisplay } from './PlayerSummaryView';
import HoverCardStore from './HoverCardStore';
import { LogView } from './LogView';
import PileView from './PileView';
import PilesContainer from './PilesContainerView';
import { BasicCardRenderer } from './CardView';
import TributeCardView, {
  BasicTributeRenderer,
  MakeHighlightedAgeTributeRenderer,
} from './TributeCardView';
import CollapsibleGameLinksView from './CollapsibleGameLinksView';
import ActionStore from './ActionStore';
import { InflatedGame, InflatedPlayer } from '../game/Game';
import Session from './Session';
import {
  Bid,
  canAffordCard,
  CardWithID,
  TributeCardWithID,
} from '../game/Rules';
import PlayerBoardView from './PlayerBoardView';
import { EventTypes, GameEvent, GameEventOfType } from '../game/GameEvents';
import invariant from 'invariant';
import AnimationSystem, {
  AddAnimationParams,
  AnimationLayer,
  ElementPosition,
  ElementTarget,
  NodeTarget,
  PointPosition,
} from './AnimationSystem';
import CardView from './CardView';
import { CARD_BORDER_RADIUS } from './BaseCardView';
import nullthrows from 'nullthrows';
import { CardType } from '../game/CardTypes';
import Symbols, { SymbolView } from './Symbols';
import {
  CardRowAnimations,
  CountersExplosionAnimation,
  DelayAnimation,
  DiscardCardAnimation,
  GainCardAnimation,
  HideElementAnimation,
  MakePlayerIncrementors,
  tokenExplosionAnimations,
} from './Animations';
import { addToMapOfArrays, makeStringID, nonNull } from '../utils/utils';
import { addCounters, CounterDelta, multiplyCounters } from '../game/Utility';
import { AnimationRefSetter, PlayerAnimationRefs } from './AnimationHooks';
import { CardBack, CardBackType, CardBackView } from './CardBackView';
import { EmptyCardView, EmptyTributeView } from './EmptyCardView';
import {
  CompleteConflictResultsDisplay,
  ConflictResultsDisplay,
  ConflictResultsDisplayRef,
} from './ConflictResultsDisplay';
import { RecursiveItemOrArray } from '../utils/ts_utils';
import { Resource } from '../game/Resources';
import { getUserPrefs } from './UserPreferences';

type User = any;

interface PlayerResourceProps {
  game: InflatedGame;
  players: InflatedPlayer[];
  sessionPlayerID?: string;
  userByID: { [k: string]: User };
  readyByUserID: { [k: string]: boolean };
  animationRefSetter?: AnimationRefSetter;
}
const PlayerResourceViews = (props: PlayerResourceProps) => {
  let {
    game,
    players,
    sessionPlayerID,
    userByID,
    readyByUserID,
    animationRefSetter,
  } = props;

  const [countMode, setCountMode] = React.useState(
    'counters' as 'total' | 'counters',
  );

  let session_player_index = players.findIndex((player) => {
    return player.userID === sessionPlayerID;
  });
  let sorted_players = players;
  if (session_player_index !== -1) {
    let left = sorted_players.slice(session_player_index);
    let right = sorted_players.slice(0, session_player_index);
    sorted_players = left.concat(right);
  }

  let max_favor = _.max(players.map((player) => player.counters.favor));
  let views = sorted_players.map((player) => {
    return (
      <PlayerSummaryView
        key={player.userID}
        game={game}
        player={player}
        user={userByID[player.userID]}
        readyByUserID={readyByUserID}
        isWinning={max_favor === player.counters.favor && max_favor > 0}
        countMode={countMode}
        animationRefSetter={animationRefSetter}
        toggleCountMode={() => {
          setCountMode(countMode === 'total' ? 'counters' : 'total');
        }}
      />
    );
  });
  return <div>{views}</div>;
};

function get_conflict_notes(
  game: InflatedGame,
  user_by_id: { [k: string]: User },
): {
  [cardID: string]: React.ReactNode;
} {
  let events = game.events.filter((event) => {
    return (
      event.type === EventTypes.CONFLICT_RESULTS &&
      event.age === game.age &&
      event.turn === game.turn
    );
  });
  if (events.length === 0) {
    return {};
  }

  return Object.fromEntries(
    events.map((event: GameEvent) => {
      invariant(
        event.type === EventTypes.CONFLICT_RESULTS,
        'expected conflict',
      );
      const cardID = event.payload.cardID;

      return [
        cardID,
        <div css={GameStyles.cardOverlay}>
          <CompleteConflictResultsDisplay event={event} userByID={user_by_id} />
          {/* <ConflictResultsDisplay
            event={event}
            userByID={user_by_id}
            onCompleted={() => {}}
          /> */}
        </div>,
      ];
    }),
  );
}

function GlobalAreaView(props: {
  game: InflatedGame;
  actionStore?: ActionStore;
  userByID: { [k: string]: User };
  selfPlayerView: React.ReactNode;
  cardIDToAnimation?: Map<string, HTMLDivElement>;
  cardIndexToAnimation?: Map<number, HTMLDivElement>;
  tributeRowIndexToAnimation?: Map<number, HTMLDivElement>;
}) {
  let { actionStore, game, userByID } = props;
  const onDraftingCardClick = useCallback(
    (card) => {
      actionStore && actionStore.didClickDraftRowCard(card);
    },
    [actionStore],
  );

  const onBasicCardClick = useCallback(
    (card) => {
      actionStore && actionStore.didClickBasicCard(card);
    },
    [actionStore],
  );

  const forceUpdate = React.useReducer((x) => x + 1, 0)[1];

  useEffect(() => {
    actionStore && actionStore.addListener(forceUpdate);
    return () => {
      actionStore && actionStore.removeListener(forceUpdate);
    };
  }, [actionStore, forceUpdate]);

  const backgroundColor = PhaseToBackgroundColor[game.phase];

  let overlayByCardID: { [cardID: string]: React.ReactNode } = {};
  const addOverlay = (cardID: string, overlay: React.ReactNode) => {
    const existing = overlayByCardID[cardID];
    overlayByCardID[cardID] = existing ? (
      <>
        {existing}
        {overlay}
      </>
    ) : (
      overlay
    );
  };

  actionStore?.getDisallowedCardIDs().forEach((id) =>
    addOverlay(
      id,
      <div
        css={GameStyles.cardOverlay}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      />,
    ),
  );
  if (game.phase === Phases.PLANNING || game.phase === Phases.RESOLUTION) {
    actionStore?.getUnaffordableCards().forEach((reason, id) => {
      const overlay = (
        <div css={GameStyles.cardOverlay}>
          <div css={GameStyles.unaffordableCardText}>{reason}</div>
        </div>
      );
      addOverlay(id, overlay);
    });
  }
  if (game.phase === Phases.RESOLUTION || game.phase === Phases.WAR) {
    Object.entries(get_conflict_notes(game, userByID)).forEach(
      ([cardID, overlay]) => addOverlay(cardID, overlay),
    );
  }

  const cardRenderer = (props) => {
    if (props.card.id === 'deck') {
      return <CardBackView {...props} />;
    } else if (props.card.id.startsWith('empty')) {
      return <EmptyCardView {...props} />;
    }
    return <CardView {...props} />;
  };
  const noteRenderer = (props) => {
    let final_props = { ...props };

    let overlay = overlayByCardID[props.card.id];
    if (overlay) {
      final_props.overlayChildren = overlay;
    }

    return cardRenderer(final_props);
  };
  const tributeRenderer_ = MakeHighlightedAgeTributeRenderer(game.age);
  const tributeRenderer = (props) => {
    if (props.card.id === 'tributedeck') {
      return <CardBackView {...props} />;
    }
    if (props.card.id.startsWith('empty')) {
      return <EmptyTributeView {...props} />;
    }
    return tributeRenderer_(props);
  };

  var highlight_color_by_card_id = {};
  if (actionStore) {
    actionStore.getCompletedTributeIDs().forEach((id) => {
      highlight_color_by_card_id[id] = 'rgb(180, 72, 247)';
    });
    const selectedCardID = actionStore.getSelectedCardID();
    if (selectedCardID) {
      const canAfford = actionStore.canAffordCard(
        game.cardsByID[selectedCardID],
      );
      highlight_color_by_card_id[selectedCardID] = canAfford.canAfford
        ? 'white'
        : 'red';
    }
  }

  const cardBack = useMemo(() => {
    return { type: `age${game.age}` as CardBackType, id: 'deck' } as CardBack;
  }, [game.age]);
  const tradeRow = [
    cardBack,
    ...times(game.boardSize - game.table.length, (i) => ({
      id: `empty${i}`,
    })),
    ...game.table,
  ];
  const tributeBack = useMemo(() => {
    return { type: `tribute` as CardBackType, id: 'tributedeck' } as CardBack;
  }, [game.age]);
  const tributeRow = [
    tributeBack,
    ...times(game.tributeRowSize - game.tributeRow.length, (i) => ({
      id: `emptytribute${i}`,
    })),
    ...game.tributeRow,
  ];

  return (
    <div
      css={GlobalAreaStyles.container}
      className={'global-piles-container'}
      style={{
        backgroundColor,
      }}
    >
      <div css={GlobalAreaStyles.pilesContainer}>
        <PilesContainer title="Trade Row">
          <PileView
            key="Trade Row"
            cards={tradeRow}
            onCardClick={onDraftingCardClick}
            renderer={noteRenderer}
            hoverRenderer={cardRenderer}
            highlightColorByCardID={highlight_color_by_card_id}
            cardIDToAnimation={props.cardIDToAnimation}
            cardIndexToAnimation={props.cardIndexToAnimation}
          />
          {props.selfPlayerView}
        </PilesContainer>
        <PilesContainer title="Basic Cards">
          <PileView
            key="Basic Cards"
            cards={game.basicPile}
            onCardClick={onBasicCardClick}
            renderer={noteRenderer}
            hoverRenderer={BasicCardRenderer}
            highlightColorByCardID={highlight_color_by_card_id}
            cardIDToAnimation={props.cardIDToAnimation}
          />
        </PilesContainer>
        <PilesContainer title="Tributes">
          <PileView
            key="Tributes"
            cards={tributeRow}
            renderer={tributeRenderer}
            hoverRenderer={tributeRenderer}
            highlightColorByCardID={highlight_color_by_card_id}
            cardIDToAnimation={props.cardIDToAnimation}
            cardIndexToAnimation={props.tributeRowIndexToAnimation}
          />
        </PilesContainer>
      </div>
    </div>
  );
}

const GlobalAreaStyles = {
  container: css({
    backgroundColor: 'rgb(50, 120, 80)',
    display: 'block',
    margin: 5,
    marginTop: 0,
    marginBottom: 0,
    paddingRight: 2,
    userSelect: 'none',

    transition: 'background-color 0.5s',
  }),
  pilesContainer: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap-reverse',
    justifyContent: 'center',
  }),
};

interface Props {
  game: InflatedGame;
  userByID: { [k: string]: User };
  actionStore?: ActionStore;
  session?: Session;
}

export default function GameView(props: Props) {
  let { game: incomingGame, userByID, actionStore } = props;

  const cardIDToAnimationRef = useRef(new Map<string, HTMLDivElement>());
  const tradeRowIndexToAnimationRef = useRef(new Map<number, HTMLDivElement>());
  const tributeRowIndexToAnimationRef = useRef(
    new Map<number, HTMLDivElement>(),
  );
  const userIDToAnimationRefs = useRef(
    new Map<string, Map<PlayerAnimationRefs, HTMLDivElement>>(),
  );
  const animationsNeedNotifyRef = useRef<boolean>(false);
  const animationRefSetter: AnimationRefSetter = (playerID, type, ref) => {
    if (ref) {
      if (!userIDToAnimationRefs.current.has(playerID)) {
        userIDToAnimationRefs.current.set(playerID, new Map());
      }
      userIDToAnimationRefs.current.get(playerID)!.set(type, ref);
    } else {
      userIDToAnimationRefs.current.get(playerID)?.delete(type);
    }
  };

  const processedEventCountRef = useRef<number>(incomingGame.events.length);
  const referenceGameRef = useRef<InflatedGame>(incomingGame);
  const animatingKeyRef = useRef<string | null>(null);
  const holdAnimationRef = useRef<boolean>(false);

  const forceUpdate = React.useReducer((x) => x + 1, 0)[1];

  const useAnimations = !getUserPrefs().disableAnimations;

  const referenceGame = referenceGameRef.current;
  if (animatingKeyRef.current) {
  } else if (
    useAnimations &&
    processedEventCountRef.current !== incomingGame.events.length
  ) {
    invariant(
      incomingGame.events.length >= referenceGame.events.length,
      'Events went backwards',
    );
    const newEvents = incomingGame.events.slice(processedEventCountRef.current);

    const COMBINE_PHASES = false;
    const COMBINE_ANIMATION_PHASES = new Set<Phase>(
      COMBINE_PHASES ? [Phases.TRIBUTE, Phases.SETUP] : [],
    );

    const tributeCardIDToGainingPlayerIDs = new Map<string, string[]>();
    const cardAnimationData: {
      playerID: string;

      gameCard: CardWithID;
      reward: CounterDelta;
      goldCost: number;
    }[] = [];
    const productionAnimationData: {
      playerID: string;
      counters: CounterDelta;
    }[] = [];
    let gainedCardIDs = new Set<string>();
    let conflictInfo: {
      playerIDToBid: Map<string, Bid>;
      conflictResults: GameEventOfType<EventTypes.CONFLICT_RESULTS>[];
    } | null = null;
    let addedTableCardIDs: string[] = [];
    let removedTableCardIDs = new Set<string>();
    let addedTributeCardIDs: string[] = [];
    let removedTributeCardIDs = new Set<string>();

    let nextProcessedEventCount = incomingGame.events.length;
    for (let i = 0; i < newEvents.length; i++) {
      const event = newEvents[i];
      if (event.type === EventTypes.GAIN_CARD) {
        const userID = nullthrows(event.payload.userID);
        let cardID = nullthrows(event.payload.cardID);
        gainedCardIDs.add(cardID);
        let gameCard = incomingGame.cardsByID[cardID] as CardWithID;
        if (gameCard.type === CardType.Basic) {
          const basicCard = referenceGame.basicPile.find(
            (card) => card.index === gameCard.index,
          );
          if (basicCard) {
            gameCard = basicCard;
            cardID = basicCard.id;
          }
        }
        const reward = event.payload;
        const gold_cost = event.payload.cost?.gold || 0;

        cardAnimationData.push({
          playerID: userID,
          gameCard,
          reward,
          goldCost: gold_cost,
        });
        removedTableCardIDs.add(event.payload.cardID);
      } else if (event.type === EventTypes.GAIN_TRIBUTES) {
        const userID = nullthrows(event.payload.userID);
        const tributeIDs = event.payload.tributeIDs!;
        tributeIDs.forEach((cardID) => {
          addToMapOfArrays(tributeCardIDToGainingPlayerIDs, cardID, userID);
          removedTributeCardIDs.add(cardID);
        });
      } else if (event.type === EventTypes.SET_UP_PHASE) {
        if (COMBINE_ANIMATION_PHASES.has(event.payload.phase)) {
          continue;
        }
        nextProcessedEventCount = processedEventCountRef.current + i + 1;
        console.log(
          'breaking on finish phase',
          event.phase,
          nextProcessedEventCount,
          incomingGame.events.length,
        );
        break;
      } else if (event.type === EventTypes.END_OF_PHASE) {
        // nop
      } else if (event.type === EventTypes.SLIDE_CARD) {
        removedTableCardIDs.add(event.payload.cardID);
      } else if (event.type === EventTypes.DEAL_CARD) {
        addedTableCardIDs.push(event.payload.cardID);
      } else if (event.type === EventTypes.DEAL_TRIBUTE) {
        addedTributeCardIDs.push(event.payload.cardID);
      } else if (event.type === EventTypes.DISCARD_TRIBUTE) {
        removedTributeCardIDs.add(event.payload.cardID);
      } else if (event.type === EventTypes.CONFLICT_RESULTS) {
        if (!conflictInfo) {
          conflictInfo = {
            playerIDToBid: new Map(
              incomingGame.players.map((p) => [p.userID, p.bid!]),
            ),
            conflictResults: [],
          };
        }
        conflictInfo.conflictResults.push(event);
      } else if (event.type === EventTypes.PRODUCTION) {
        const userID = event.payload.userID;
        productionAnimationData.push({
          playerID: userID,
          counters: event.payload,
        });
      }
    }

    if (referenceGame.events.length < processedEventCountRef.current) {
      referenceGameRef.current.cardsByID = incomingGame.cardsByID;
      referenceGameRef.current.tributeCardsByID = incomingGame.tributeCardsByID;

      // catch up game to current state
      for (
        let i = referenceGame.events.length;
        i < processedEventCountRef.current;
        i++
      ) {
        const event = incomingGame.events[i];
        referenceGame.events.push(event);

        if (event.type === EventTypes.SET_UP_PHASE) {
          referenceGame.phase = event.payload.phase;
        } else if (event.type === EventTypes.END_OF_PHASE) {
          // nop
        } else if (event.type === EventTypes.SET_UP_TURN) {
          referenceGame.turn = event.payload.turn;
        } else if (event.type === EventTypes.SET_UP_AGE) {
          referenceGame.age = event.payload.age;
        } else if (event.type === EventTypes.BID) {
          const player = referenceGame.players.find(
            (p) => p.userID === event.payload.userID,
          )!;
          player.counters.military -= event.payload.military;
        } else if (event.type === EventTypes.CONFLICT_RESULTS) {
          // nop
        } else if (event.type === EventTypes.PRODUCTION) {
          const player = referenceGame.players.find(
            (p) => p.userID === event.payload.userID,
          )!;
          player.counters = addCounters(player.counters, event.payload);
        } else if (event.type === EventTypes.GAIN_CARD) {
          const cardID = event.payload.cardID;
          referenceGame.table = referenceGame.table.filter(
            (x) => x.id !== cardID,
          );
          referenceGame.tableIDs = referenceGame.tableIDs.filter(
            (x) => x !== cardID,
          );
          const player = referenceGame.players.find(
            (p) => p.userID === event.payload.userID,
          )!;
          player.cardIDs.push(cardID);
          player.cards.push(referenceGame.cardsByID[cardID]);

          player.counters = addCounters(
            player.counters,
            multiplyCounters(event.payload.cost, -1),
          );
          player.counters = addCounters(player.counters, event.payload);
        } else if (event.type === EventTypes.SLIDE_CARD) {
          const cardID = event.payload.cardID;
          referenceGame.table = referenceGame.table.filter(
            (x) => x.id !== cardID,
          );
          referenceGame.tableIDs = referenceGame.tableIDs.filter(
            (x) => x !== cardID,
          );
        } else if (event.type === EventTypes.DEAL_CARD) {
          const cardID = event.payload.cardID;
          referenceGame.table.unshift(incomingGame.cardsByID[cardID]);
          referenceGame.tableIDs.unshift(cardID);
        } else if (event.type === EventTypes.GAIN_TRIBUTES) {
          const tributeIDs = event.payload.tributeIDs!;
          tributeIDs.forEach((cardID) => {
            referenceGame.tributeRow = referenceGame.tributeRow.filter(
              (x) => x.id !== cardID,
            );
            referenceGame.tributeRowIDs = referenceGame.tributeRowIDs.filter(
              (x) => x !== cardID,
            );
          });

          const player = referenceGame.players.find(
            (p) => p.userID === event.payload.userID,
          )!;
          player.counters.favor += event.payload.favor;
        } else if (event.type === EventTypes.DISCARD_TRIBUTE) {
          const cardID = event.payload.cardID;
          referenceGame.tributeRow = referenceGame.tributeRow.filter(
            (x) => x.id !== cardID,
          );
          referenceGame.tributeRowIDs = referenceGame.tributeRowIDs.filter(
            (x) => x !== cardID,
          );
        } else if (event.type === EventTypes.DEAL_TRIBUTE) {
          const cardID = event.payload.cardID;
          referenceGame.tributeRow.unshift(
            incomingGame.tributeCardsByID[cardID],
          );
          referenceGame.tributeRowIDs.unshift(cardID);
        }
      }
    }

    processedEventCountRef.current = nextProcessedEventCount;

    let animationKey = makeStringID();
    const animations: AddAnimationParams[] = [];
    let blockingAnimation:
      | RecursiveItemOrArray<AddAnimationParams>
      | undefined = undefined;

    let playerIDToResourceIncrementors = new Map<
      string,
      Map<Resource, (delta: number) => void>
    >();
    if (
      cardAnimationData.length > 0 ||
      tributeCardIDToGainingPlayerIDs.size > 0 ||
      productionAnimationData.length > 0 ||
      conflictInfo
    ) {
      referenceGame.players.forEach((player) => {
        const userID = player.userID;
        const [incrementors, anims] = MakePlayerIncrementors({
          player,
          playerAnimationRefs: userIDToAnimationRefs.current.get(userID)!,
          game: referenceGame,
        });
        playerIDToResourceIncrementors.set(userID, incrementors);
        animations.push(...anims);
      });
    }

    if (cardAnimationData.length > 0) {
      cardAnimationData.sort((a, b) => {
        let aScore = incomingGame.players.findIndex(
          (player) => player.userID === a.playerID,
        );
        let bScore = incomingGame.players.findIndex(
          (player) => player.userID === b.playerID,
        );

        return aScore - bScore;
      });

      cardAnimationData.forEach((data) => {
        const userID = data.playerID;
        const gameCard = data.gameCard;
        const reward = data.reward;
        const goldCost = data.goldCost;
        const ret = GainCardAnimation({
          card: gameCard,
          reward,
          goldCost,
          cardIDToAnimationRef: cardIDToAnimationRef.current,
          playerAnimationRefs: userIDToAnimationRefs.current.get(userID)!,
          playerResourceIncrementors:
            playerIDToResourceIncrementors.get(userID)!,

          delayUntilFinished: blockingAnimation,
        });
        if (ret) {
          animations.push(...ret.animations);
          blockingAnimation = ret.blockingAnimation;
        }
      });
    }

    if (tributeCardIDToGainingPlayerIDs.size > 0) {
      referenceGame.tributeRowIDs.forEach((cardID) => {
        const playerIDs = tributeCardIDToGainingPlayerIDs.get(cardID);
        if (!playerIDs || playerIDs.length === 0) {
          return;
        }
        let card = referenceGame.tributeCardsByID[cardID];
        let startCard = cardIDToAnimationRef.current.get(cardID);
        if (!startCard) {
          console.warn('No start card for', cardID);
          return;
        }

        const discardBP = DiscardCardAnimation({
          node: <TributeCardView card={card} />,
          cardElement: startCard,

          delayUntilFinished: blockingAnimation,
        });
        if (discardBP) {
          animations.push(...discardBP.animations);
        }

        const favor = card.favor[referenceGame.age - 1] || 0;

        let delay = 0;
        playerIDs.forEach((userID) => {
          animations.push(
            ...tokenExplosionAnimations({
              symbol: Symbols.FAVOR,
              count: favor,

              startElement: ElementPosition(startCard, {
                anchorPoint: [0.5, 0.5],
              }),
              endElement: ElementPosition(
                userIDToAnimationRefs.current
                  .get(userID)
                  ?.get(PlayerAnimationRefs.favor)!,
              ),
              delayUntilFinished: blockingAnimation,
              delaySeconds: delay,

              style: 'l_explosion',

              incrementor: playerIDToResourceIncrementors
                .get(userID)
                ?.get('favor')!,
            }),
          );
          delay += 0.1;
        });
        blockingAnimation = discardBP.blockingAnimation || blockingAnimation;
      });
    }

    if (conflictInfo) {
      conflictInfo.playerIDToBid.forEach((bid, playerID) => {
        const bidAmount = bid.military;
        const playerAnimationRefs =
          userIDToAnimationRefs.current.get(playerID)!;

        const favorView = playerAnimationRefs.get(PlayerAnimationRefs.favor)!;
        const startElement = ElementPosition(favorView);
        const endElement = ElementPosition(
          nullthrows(
            cardIDToAnimationRef.current.get(
              referenceGame.table[bid.tradeRowIndex].id,
            ),
          ),
        );

        if (bidAmount > 0) {
          const incrementor = playerIDToResourceIncrementors
            .get(playerID)!
            .get('military')!;
          animations.push(
            ...tokenExplosionAnimations({
              symbol: Symbols.MILITARY,
              count: bidAmount,

              startElement,
              endElement,
              // startScale: 0.5,

              delayUntilFinished: blockingAnimation,
              style: 'train',
              incrementor: (count) => incrementor(-count),
            }),
          );
        }
      });

      holdAnimationRef.current = true;

      let conflictAnimations: AddAnimationParams[] = [];
      let currentResultIndex = 0;
      let resultsRefs: Map<number, ConflictResultsDisplayRef> = new Map();
      conflictInfo.conflictResults.forEach((event, i) => {
        const cardID = event.payload.cardID;

        const cardElement = cardIDToAnimationRef.current.get(cardID);
        if (!cardElement) {
          console.warn('No card element for', cardID);
          return;
        }

        holdAnimationRef.current = true;
        const conflictAnimation: AddAnimationParams = {
          target: NodeTarget(
            <ConflictResultsDisplay
              event={event}
              userByID={userByID}
              onCompleted={() => {
                currentResultIndex++;
                if (currentResultIndex == conflictInfo.conflictResults.length) {
                  setTimeout(() => {
                    if (animatingKeyRef.current === animationKey) {
                      holdAnimationRef.current = false;
                      AnimationSystem.clearAnimations();
                    }
                  }, 500);
                } else if (
                  currentResultIndex < conflictInfo.conflictResults.length
                ) {
                  resultsRefs.get(currentResultIndex)?.startAnimation();
                } else {
                  invariant(false, 'unexpected');
                }
              }}
              ref={(ref) => {
                if (ref) {
                  resultsRefs.set(i, ref);
                  if (i === currentResultIndex) {
                    ref.startAnimation();
                  }
                } else {
                  resultsRefs.delete(i);
                }
              }}
            />,
            { anchorPoint: [0.5, 0.5] },
          ),
          startElement: ElementPosition(cardElement, {
            anchorPoint: [0.5, 0.5],
          }),

          delayUntilFinished: blockingAnimation,
          durationSeconds: 1,
          finishPauseSeconds: 15,
        };

        animations.push(conflictAnimation);
        conflictAnimations.push(conflictAnimation);
      });
      if (conflictAnimations.length > 0) {
        animations.push(
          DelayAnimation({
            delaySeconds: 0.1,
            delayUntilFinished: conflictAnimations,
          }),
        );
        blockingAnimation = animations[animations.length - 1];
      }
    }

    let cardRowBlockingAnimations: AddAnimationParams[] = [];
    if (
      cardAnimationData.length > 0 ||
      removedTableCardIDs.size > 0 ||
      addedTableCardIDs.length > 0
    ) {
      const bp = CardRowAnimations({
        cardIDsToAdd: addedTableCardIDs,
        cardIDsToRemove: removedTableCardIDs,
        alreadyRemovedCardIDs: new Set(
          cardAnimationData.map((x) => x.gameCard.id),
        ),
        cardIDToAnimationRef: cardIDToAnimationRef.current,
        rowIndexToAnimationRef: tradeRowIndexToAnimationRef.current,
        row: referenceGame.table,
        rowSize: referenceGame.boardSize,
        cardsByID: incomingGame.cardsByID,
        cardRenderer: (card) => BasicCardRenderer({ card }),

        delayUntilFinished: blockingAnimation,
      });
      if (bp) {
        animations.push(...bp.animations);
        cardRowBlockingAnimations.push(bp.blockingAnimation);
      }
    }

    if (
      addedTributeCardIDs.length > 0 ||
      removedTributeCardIDs.size > 0 ||
      tributeCardIDToGainingPlayerIDs.size > 0
    ) {
      const tributeRenderer = MakeHighlightedAgeTributeRenderer(
        referenceGame.age,
      );
      const bp = CardRowAnimations({
        cardIDsToAdd: addedTributeCardIDs,
        cardIDsToRemove: removedTributeCardIDs,
        alreadyRemovedCardIDs: new Set(tributeCardIDToGainingPlayerIDs.keys()),
        cardIDToAnimationRef: cardIDToAnimationRef.current,
        rowIndexToAnimationRef: tributeRowIndexToAnimationRef.current,
        row: referenceGame.tributeRow,
        rowSize: referenceGame.tributeRowSize,
        cardsByID: incomingGame.tributeCardsByID,
        cardRenderer: (card) => tributeRenderer({ card }),

        delayUntilFinished: blockingAnimation,
      });
      if (bp) {
        animations.push(...bp.animations);
        cardRowBlockingAnimations.push(bp.blockingAnimation);
      }
    }
    blockingAnimation = cardRowBlockingAnimations;

    if (productionAnimationData.length > 0) {
      let productionAnimations = productionAnimationData.map((data) => {
        const userID = data.playerID;

        const ret = CountersExplosionAnimation({
          counters: data.counters,
          playerAnimationRefs: userIDToAnimationRefs.current.get(userID)!,
          playerResourceIncrementors:
            playerIDToResourceIncrementors.get(userID)!,

          style: 'l_explosion',

          delayUntilFinished: blockingAnimation,
        });
        if (ret) {
          animations.push(...ret.animations);
        }
        return ret?.blockingAnimation;
      });
      let filteredProductionAnimations = productionAnimations.filter(nonNull);
      if (filteredProductionAnimations.length > 0) {
        blockingAnimation = filteredProductionAnimations;
      }
    }

    if (animations.length > 0) {
      animations.push({
        target: null,
        startElement: PointPosition([0, 0]),
        delayUntilFinished: blockingAnimation,
        durationSeconds: 0.25,
      });

      console.log('submitting animations', animations.length);
      AnimationSystem.addAnimations(animations);
      animationsNeedNotifyRef.current = true;
      animatingKeyRef.current = animationKey;
    } else {
      console.log('new events, no animations, moving to new game');
      // XXX: this should recurse before setting the incoming game
      // referenceGameRef.current = incomingGame;
      forceUpdate();
    }
  } else {
    referenceGameRef.current = incomingGame;
    processedEventCountRef.current = incomingGame.events.length;
  }
  const game = referenceGameRef.current;
  console.log('rendering game', game.phase);

  useEffect(() => {
    if (animationsNeedNotifyRef.current) {
      // AnimationSystem_.addAnimations(animationsToAddRef.current);
      AnimationSystem._notifyListeners();
      animationsNeedNotifyRef.current = false;
    }
  });
  const onAnimationComplete = useCallback(() => {
    if (
      animatingKeyRef.current &&
      !AnimationSystem.hasAnimations() &&
      !holdAnimationRef.current
    ) {
      animatingKeyRef.current = null;
      forceUpdate();
    }
  }, [animatingKeyRef, holdAnimationRef, forceUpdate]);
  useEffect(() => {
    const listener = onAnimationComplete;
    AnimationSystem.addListener(listener);
    return () => {
      AnimationSystem.removeListener(listener);
    };
  }, [onAnimationComplete]);

  let sessionUserID = actionStore && actionStore.getPlayer().userID;
  let session_player: InflatedPlayer | null = null;
  let sortedPlayers: InflatedPlayer[] = [];
  for (let player of game.players) {
    if (player.userID === sessionUserID) {
      session_player = player;
      sortedPlayers.unshift(player);
    } else {
      sortedPlayers.push(player);
    }
  }

  let self_player_view: React.ReactNode = null;
  if (session_player && !game.gameEndTimestamp) {
    sessionUserID = session_player.userID;
    self_player_view = (
      <SelfPlayerView
        actionStore={actionStore!}
        phase={game.phase}
        ready={game.readyByUserID[sessionUserID]}
        rolls={game.rollsByPlayerID[sessionUserID] || undefined}
        waitingForAnimation={!!animatingKeyRef.current}
        onSkipAnimation={() => {
          if (animatingKeyRef.current) {
            holdAnimationRef.current = false;
            AnimationSystem.clearAnimations();

            referenceGameRef.current = incomingGame;
            processedEventCountRef.current = incomingGame.events.length;
          }
        }}
      />
    );
  }

  let game_content: React.ReactNode = null;
  if (game.gameEndTimestamp) {
    game_content = <EndOfGameView game={game} userByID={userByID} />;
  } else {
    game_content = (
      <GlobalAreaView
        actionStore={actionStore}
        game={game}
        userByID={userByID}
        selfPlayerView={self_player_view}
        cardIDToAnimation={cardIDToAnimationRef.current}
        cardIndexToAnimation={tradeRowIndexToAnimationRef.current}
        tributeRowIndexToAnimation={tributeRowIndexToAnimationRef.current}
      />
    );
  }
  return (
    <div css={GameStyles.container}>
      <div css={GameStyles.leftPane}>
        <GameStateView game={game} />
        {game_content}
        <div css={GameStyles.playersContainer}>
          {sortedPlayers.map((player) => (
            <PlayerBoardView
              key={player.userID}
              player={player}
              game={game}
              name={userByID[player.userID].name}
              ref={(node) =>
                animationRefSetter(
                  player.userID,
                  PlayerAnimationRefs.board,
                  node,
                )
              }
            />
          ))}
        </div>
      </div>
      <div css={GameStyles.rightPane}>
        <PlayerResourceViews
          sessionPlayerID={sessionUserID}
          game={game}
          players={game.players}
          userByID={userByID}
          readyByUserID={game.readyByUserID}
          animationRefSetter={animationRefSetter}
        />
        <LogView game={game} userByID={userByID} actionStore={actionStore} />
        <div css={GameStyles.rightPaneBuffer} />
        <CollapsibleGameLinksView />
      </div>
      {HoverCardStore.getHoverCardComponent()}
      <AnimationLayer />
    </div>
  );
}

const RIGHT_PANE_WIDTH = 325;

const GameStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
  }),
  leftPane: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginRight: 5 + RIGHT_PANE_WIDTH,
  }),
  rightPane: css({
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,
    width: RIGHT_PANE_WIDTH,
    minWidth: RIGHT_PANE_WIDTH,
    height: '100vh',
    top: 0,
    right: 0,
    position: 'fixed',
  }),
  playersLogsContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  playersContainer: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(326px, 1fr))',
    gap: 5,
    margin: 5,
  }),
  rightPaneBuffer: css({
    flexGrow: 1,
  }),
  cardOverlay: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',

    width: '100%',
    height: '100%',

    borderRadius: CARD_BORDER_RADIUS,
  }),
  unaffordableCardText: css({
    fontSize: 14,
    textAlign: 'center',
    color: 'rgb(210, 210, 210)',
    textShadow: '0px 1px rgba(0, 0, 0, 0.8)',
    backgroundColor: 'rgba(150, 0, 0, 0.8)',
    // margin: 10,
    width: '120%',
    padding: 4,
    transform: 'rotate(-15deg)',
  }),
};

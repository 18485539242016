/** @jsx jsx */
/** @jsxFrag */
import { jsx, css } from '@emotion/react';
import BaseModal, { BaseModalProps } from '@restart/ui/Modal';

export const Modal = (props: {
  children: React.ReactElement;
  show: boolean;
  onHide: () => void;
}) => {
  if (!props.show) {
    return null;
  }

  return (
    <BaseModal
      show={props.show}
      onHide={props.onHide}
      renderBackdrop={(props) => <div css={ModalStyles.backdrop} {...props} />}
      css={ModalStyles.content}
    >
      {props.children}
    </BaseModal>
  );
};

const ModalStyles = {
  backdrop: css({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 300,
  }),
  content: css({
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 5,
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 301,
  }),
};

/** @jsx jsx */
/** @jsxFrag */
import { jsx, css } from '@emotion/react';

import BaseCardView from './BaseCardView';
import { forwardRef } from 'react';

export const EmptyCardView = forwardRef<HTMLDivElement>((props: {}, ref) => {
  return (
    <BaseCardView
      ref={ref}
      cardStyle={{
        border: 'none',
        boxShadow: 'none',
      }}
      bodyStyle={{
        boxShadow: 'none',
      }}
      titleView={null}
    ></BaseCardView>
  );
});

export const EmptyTributeView = forwardRef<HTMLDivElement>((props: {}, ref) => {
  return (
    <BaseCardView
      ref={ref}
      landscape={true}
      cardStyle={{
        border: 'none',
        boxShadow: 'none',
      }}
      bodyStyle={{
        boxShadow: 'none',
      }}
      titleView={null}
    ></BaseCardView>
  );
});

import _ from 'underscore';
import * as Utility from './Utility';
import { TributeDef } from './GameModel';
import { Context, CardWithID, Bid, computeBaseCounters } from './Rules';
import { InflatedPlayer } from './Game';
import {
  CardSubType,
  CardType,
  assertCardSubType,
  isCardSubType,
  isCardType,
} from './CardTypes';
import invariant from 'invariant';
import { Resource, ResourceToDisplayName } from './Resources';
import { match } from 'ts-pattern';
import nullthrows from 'nullthrows';
import { EventTypes, GameEventOfType } from './GameEvents';

export type TributeLogicPlanningData = any | null;
export type TributeLogicResolutionData = any | null;
export type TributeLogicProductionData = any | null;

export type PlanningDataExtra = {
  results: PlanningResults;
  players: InflatedPlayer[];
  table: CardWithID[];
};
export type PlanningResults = {
  winningPlayerID: string;
  rounds: { [k: string]: { playerID: string; total: number } }[];
  participantPlayerIDs: string[];
};

export abstract class TributeLogic {
  private _favor: [number, number, number];
  private _thresholds: [number, number, number] | null;

  constructor(card: TributeDef) {
    this._favor = card.favor;
    this._thresholds = card.value || null;
  }

  public get favor(): [number, number, number] {
    return this._favor;
  }
  public get thresholds(): [number, number, number] | null {
    return this._thresholds;
  }

  abstract description(): string;
  abstract shouldGainCard(
    context: Context,
    planning_data: TributeLogicPlanningData | null,
    resolution_data: TributeLogicResolutionData | null,
    production_data: TributeLogicProductionData | null,
    players: InflatedPlayer[] | null,
    conflictResults: GameEventOfType<EventTypes.CONFLICT_RESULTS> | null,
  ): boolean;

  planningDataFunction(
    context: Context,
    extra: PlanningDataExtra,
  ): TributeLogicPlanningData {
    return null;
  }
  resolutionDataFunction(
    context: Context,
    table: CardWithID[],
    cardToGain: CardWithID,
  ): TributeLogicResolutionData {
    return null;
  }
  productionDataFunction(context: Context): TributeLogicProductionData {
    return null;
  }
}

export function getTributeLogic(card: TributeDef): TributeLogic | null {
  const def = TributeDefinitions[card.function];
  return def ? def(card) : null;
}

type TributeLogicObtainCardType =
  | {
      type: 'type';
      cardType: CardType;
    }
  | {
      type: 'subtype';
      cardSubtype: CardSubType;
    }
  | {
      type: 'count';
      countType: 'most' | 'least';
    };
function parseTributeLogicObtainCardType(
  params: string,
): TributeLogicObtainCardType {
  if (params === 'Most') {
    return { type: 'count', countType: 'most' };
  } else if (params === 'Least') {
    return { type: 'count', countType: 'least' };
  } else if (isCardType(params)) {
    return { type: 'type', cardType: params };
  } else if (isCardSubType(params)) {
    return {
      type: 'subtype',
      cardSubtype: params,
    };
  } else {
    invariant(false, 'invalid TributeLogicObtainCardType: %s', params);
  }
}
class ObtainCardTributeLogic extends TributeLogic {
  private _cardType: TributeLogicObtainCardType;
  constructor(card: TributeDef) {
    super(card);

    this._cardType = parseTributeLogicObtainCardType(card.params);
  }

  description(): string {
    return match(this._cardType)
      .with(
        { type: 'type' },
        (cardType) => `Obtain a ${cardType.cardType} Trade Row Card.`,
      )
      .with(
        { type: 'subtype' },
        (cardType) => `Obtain a ${cardType.cardSubtype} Trade Row Card.`,
      )
      .with({ type: 'count' }, (cardType) => {
        return `Obtain a card of the type you have the ${cardType.countType} of from the Trade Row.`;
      })
      .exhaustive();
  }

  private _isCompleted(
    context: Context,
    cardToGain: CardWithID,
    table: CardWithID[],
  ): boolean {
    return (
      // Trade row condition
      table.some((c) => c.id === cardToGain.id) &&
      // type condition
      match(this._cardType)
        .with(
          { type: 'type' },
          (cardType) => cardToGain.type === cardType.cardType,
        )
        .with(
          { type: 'subtype' },
          (cardType) => cardToGain.subType === cardType.cardSubtype,
        )
        .with({ type: 'count' }, ({ countType }) => {
          const acquiredTypeCount = Utility.countIf(
            context.player.cards,
            (card) =>
              card.type === cardToGain.type && card.id !== cardToGain.id,
          );
          const otherTypeCounts = [
            Utility.countIf(
              context.player.cards,
              (card) => card.type === 'Resource' && card.id !== cardToGain.id,
            ),
            Utility.countIf(
              context.player.cards,
              (card) => card.type === 'Conflict' && card.id !== cardToGain.id,
            ),
            Utility.countIf(
              context.player.cards,
              (card) => card.type === 'Prayer' && card.id !== cardToGain.id,
            ),
          ];
          if (countType === 'most') {
            return acquiredTypeCount === Math.max(...otherTypeCounts);
          } else if (countType === 'least') {
            return acquiredTypeCount === Math.min(...otherTypeCounts);
          } else {
            invariant(false, 'invalid countType: %s', countType);
          }
        })
        .exhaustive()
    );
  }

  resolutionDataFunction(
    context: Context,
    table: CardWithID[],
    cardToGain: CardWithID,
  ): TributeLogicResolutionData {
    return this._isCompleted(context, cardToGain, table);
  }

  shouldGainCard(
    context: Context,
    planning_data: TributeLogicPlanningData | null,
    resolution_data: TributeLogicResolutionData | null,
  ): boolean {
    return !!resolution_data;
  }
}

type TributeLogicHaveCardsType =
  | {
      type: 'type';
      cardType: CardType;
    }
  | {
      type: 'subtype';
      cardSubtype: CardSubType;
    }
  | {
      type: 'single';
    };
function parseTributeLogicHaveCardsType(
  params: string,
): TributeLogicHaveCardsType {
  if (params === 'SingleType') {
    return { type: 'single' };
  } else if (isCardType(params)) {
    return { type: 'type', cardType: params };
  } else {
    return {
      type: 'subtype',
      cardSubtype: assertCardSubType(params),
    };
  }
}
function formatTributeLogicHaveCardsType(
  cardType: TributeLogicHaveCardsType,
): string {
  return match(cardType)
    .with({ type: 'type' }, (cardType) => `${cardType.cardType} cards`)
    .with({ type: 'subtype' }, (cardType) => `${cardType.cardSubtype} cards`)
    .with(
      { type: 'single' },
      () => `cards of the same type (Prayer, Conflict, or Resource)`,
    )
    .exhaustive();
}
class HaveCardsTypeTributeLogic extends TributeLogic {
  private _cardType: TributeLogicHaveCardsType;

  constructor(card: TributeDef) {
    super(card);

    this._cardType = parseTributeLogicHaveCardsType(card.params);
  }

  description(): string {
    return `Own X\n${formatTributeLogicHaveCardsType(this._cardType)}.`;
  }

  private _isCompleted(cards: CardWithID[], age: number): boolean {
    const count = match(this._cardType)
      .with({ type: 'type' }, ({ cardType }) =>
        Utility.countIf(cards, (card) => card.type === cardType),
      )
      .with({ type: 'subtype' }, ({ cardSubtype }) =>
        Utility.countIf(cards, (card) => card.subType === cardSubtype),
      )
      .with({ type: 'single' }, () => {
        return Math.max(
          Utility.countIf(cards, (card) => card.type === 'Resource'),
          Utility.countIf(cards, (card) => card.type === 'Conflict'),
          Utility.countIf(cards, (card) => card.type === 'Prayer'),
        );
      })
      .exhaustive();

    return count >= this.thresholds![age - 1];
  }

  shouldGainCard(context: Context): boolean {
    return this._isCompleted(context.player.cards, context.age);
  }
}

class HaveSetsTributeLogic extends TributeLogic {
  description(): string {
    return 'Have X Sets of a Resource, Conflict, and Prayer card.';
  }

  private _isCompleted(cards: CardWithID[], age: number): boolean {
    const count = Utility.countBoardSets(cards);
    return count >= this.thresholds![age - 1];
  }

  shouldGainCard(context: Context): boolean {
    return this._isCompleted(context.player.cards, context.age);
  }
}

type Direction = 'first' | 'last';
class ObtainDirectionTributeLogic extends TributeLogic {
  constructor(
    card: TributeDef,
    private _direction: Direction,
  ) {
    super(card);
    invariant(
      this._direction === 'first' || this._direction === 'last',
      'invalid direction: %s',
      this._direction,
    );
  }

  description(): string {
    return `Obtain the ${this._direction} Trade Row Card.`;
  }

  resolutionDataFunction(
    context: Context,
    table: CardWithID[],
    cardToGain: CardWithID,
  ): TributeLogicResolutionData {
    const index = table.indexOf(cardToGain);
    return this._direction === 'first'
      ? index === 0
      : index === table.length - 1;
  }

  shouldGainCard(
    context: Context,
    planning_data: TributeLogicPlanningData | null,
    resolution_data: TributeLogicResolutionData | null,
  ): boolean {
    return !!resolution_data;
  }
}

class ObtainMostExpensiveCardTributeLogic extends TributeLogic {
  description(): string {
    return `Obtain a Trade Row Card with the highest Cost.`;
  }

  private _isCompleted(table: CardWithID[], cardToGain: CardWithID): boolean {
    if (!table.includes(cardToGain)) {
      return false;
    }
    const highestTotalCost = _.max(table.map((card) => card.cost));
    return cardToGain.cost >= highestTotalCost;
  }

  resolutionDataFunction(
    context: Context,
    table: CardWithID[],
    cardToGain: CardWithID,
  ): TributeLogicResolutionData {
    return this._isCompleted(table, cardToGain);
  }

  shouldGainCard(
    context: Context,
    planning_data: TributeLogicPlanningData | null,
    resolution_data: TributeLogicResolutionData | null,
  ): boolean {
    return !!resolution_data;
  }
}

class ObtainMostValuableCardTributeLogic extends TributeLogic {
  description(): string {
    return `Obtain a Trade Row Card with the highest Base Favor.`;
  }

  private _isCompleted(table: CardWithID[], cardToGain: CardWithID): boolean {
    if (!table.includes(cardToGain)) {
      return false;
    }
    const highestFavorValue = _.max(table.map((card) => card.favor));
    return cardToGain.favor >= highestFavorValue;
  }

  resolutionDataFunction(
    context: Context,
    table: CardWithID[],
    cardToGain: CardWithID,
  ): TributeLogicResolutionData {
    return this._isCompleted(table, cardToGain);
  }

  shouldGainCard(
    context: Context,
    planning_data: TributeLogicPlanningData | null,
    resolution_data: TributeLogicResolutionData | null,
  ): boolean {
    return !!resolution_data;
  }
}

class ContestMostWinningOpponentTributeLogic extends TributeLogic {
  description(): string {
    return `Clash with the player with the most Favor.`;
  }

  override planningDataFunction(
    context: Context,
    extra: PlanningDataExtra,
  ): TributeLogicPlanningData {
    const { results, players } = extra;
    if (!results || results.participantPlayerIDs.length === 1) {
      return false;
    }
    const maxFavor = _.max(players.map((player) => player.counters.favor));
    const ret = results.participantPlayerIDs.some(
      (playerID) =>
        playerID !== (context.player as unknown as InflatedPlayer).userID &&
        players.find((player) => player.userID === playerID)?.counters.favor ===
          maxFavor,
    );
    return ret;
  }

  shouldGainCard(
    context: Context,
    planning_data: TributeLogicPlanningData | null,
    resolution_data: TributeLogicResolutionData | null,
    production_data: TributeLogicProductionData | null,
    players: InflatedPlayer[] | null,
  ): boolean {
    return !!planning_data;
  }
}

class DefeatOpponentTributeLogic extends TributeLogic {
  description(): string {
    return `Defeat an opponent in a conflict.`;
  }

  shouldGainCard(
    context: Context,
    planning_data: TributeLogicPlanningData | null,
    resolution_data: TributeLogicResolutionData | null,
    production_data: TributeLogicProductionData | null,
    players: InflatedPlayer[] | null,
    conflictResults: GameEventOfType<EventTypes.CONFLICT_RESULTS> | null,
  ): boolean {
    if (!conflictResults) {
      return false;
    }
    if (conflictResults.payload.winningPlayerID !== context.player.userID) {
      return false;
    }
    if (Object.keys(conflictResults.payload.rounds[0]).length === 1) {
      return false;
    }
    return true;
  }
}

class GainCardWithoutMilitaryTributeLogic extends TributeLogic {
  description(): string {
    return `Obtain a Trade Row Card without spending any Military Tokens.`;
  }

  resolutionDataFunction(
    context: Context,
    table: CardWithID[],
    cardToGain: CardWithID,
  ): TributeLogicResolutionData {
    const bid = nullthrows(context.player.bid);
    return bid.military === 0 && table.includes(cardToGain);
  }

  shouldGainCard(
    context: Context,
    planning_data: TributeLogicPlanningData | null,
    resolution_data: TributeLogicResolutionData | null,
  ): boolean {
    return !!resolution_data;
  }
}

class HaveCountersTributeLogic extends TributeLogic {
  private _counterType: Resource;
  private _includeBase: boolean;

  constructor(card: TributeDef, includeBase: boolean) {
    super(card);

    this._counterType = card.params as Resource;
    this._includeBase = includeBase;
  }

  description(): string {
    return `Have\nX ${ResourceToDisplayName[this._counterType]} Tokens${this._includeBase ? ` + ${ResourceToDisplayName[this._counterType]} Base` : ''}\nat the end of turn.`;
  }

  private _isCompleted(context: Context): boolean {
    let counters = context.player.counters[this._counterType];
    if (this._includeBase) {
      const baseCounters = computeBaseCounters(context)[this._counterType];
      counters += baseCounters;
    }
    return counters >= this.thresholds![context.age - 1];
  }

  shouldGainCard(context: Context): boolean {
    return this._isCompleted(context);
  }
}

class GainResourceTributeLogic extends TributeLogic {
  private _resource: Resource;

  constructor(card: TributeDef) {
    super(card);

    this._resource = card.params as Resource;
  }

  description(): string {
    if (this._resource === 'favor') {
      return `Gain\nX Favor Tokens from\n other sources.`;
    }
    return `Gain X ${this._resource} tokens.`;
  }

  planningDataFunction(
    context: Context,
    extra: PlanningDataExtra,
  ): TributeLogicPlanningData {
    return context.production_delta[this._resource];
  }

  resolutionDataFunction(
    context: Context,
    table: CardWithID[],
    cardToGain: CardWithID,
  ): TributeLogicResolutionData {
    return context.production_delta[this._resource];
  }

  productionDataFunction(context: Context): TributeLogicPlanningData {
    return context.production_delta[this._resource];
  }

  shouldGainCard(
    context: Context,
    planning_data: TributeLogicPlanningData | null,
    resolution_data: TributeLogicResolutionData | null,
    production_data: TributeLogicProductionData | null,
  ): boolean {
    const total =
      (planning_data || 0) +
      (resolution_data || 0) +
      (production_data || 0) +
      context.production_delta[this._resource];
    return total >= this.thresholds![context.age - 1];
  }
}

class ClashAgainstHigherBidOpponentTributeLogic extends TributeLogic {
  description(): string {
    return `Clash with an opponent who bid more total Military.`;
  }

  shouldGainCard(
    context: Context,
    planning_data: TributeLogicPlanningData | null,
    resolution_data: TributeLogicResolutionData | null,
    production_data: TributeLogicProductionData | null,
    players: InflatedPlayer[] | null,
    conflictResults: GameEventOfType<EventTypes.CONFLICT_RESULTS> | null,
  ): boolean {
    if (!conflictResults) {
      return false;
    }

    const firstRound = conflictResults.payload.rounds[0];
    let playerIDs = Object.keys(firstRound);
    const playerIDToBonus = Object.fromEntries(
      playerIDs.map((playerID) => [playerID, firstRound[playerID].bonus]),
    );

    const myBonus = playerIDToBonus[context.player.userID];
    if (myBonus === undefined) {
      return false;
    }

    const maxBonus = _.max(Object.values(playerIDToBonus));
    return myBonus < maxBonus;
  }
}

class HaveFewestCountersTributeLogic extends TributeLogic {
  private _counterType: Resource;

  constructor(card: TributeDef) {
    super(card);

    this._counterType = card.params as Resource;
  }

  description(): string {
    return `Have fewer ${ResourceToDisplayName[this._counterType]} tokens than all other players.`;
  }

  shouldGainCard(
    context: Context,
    planning_data: TributeLogicPlanningData | null,
    resolution_data: TributeLogicResolutionData | null,
    production_data: TributeLogicProductionData | null,
    players: InflatedPlayer[] | null,
  ): boolean {
    return !!players?.every((player) => {
      return (
        player.userID === context.player.userID ||
        player.counters[this._counterType] >
          context.player.counters[this._counterType]
      );
    });
  }
}

const TributeDefinitions: Record<string, (card: TributeDef) => TributeLogic> = {
  ObtainCard: (card) => new ObtainCardTributeLogic(card),
  HaveCards: (card) => new HaveCardsTypeTributeLogic(card),
  HaveSets: (card) => new HaveSetsTributeLogic(card),
  HaveCounters: (card) => new HaveCountersTributeLogic(card, false),
  HaveCountersAndBase: (card) => new HaveCountersTributeLogic(card, true),
  ObtainFirst: (card) => new ObtainDirectionTributeLogic(card, 'first'),
  ObtainLast: (card) => new ObtainDirectionTributeLogic(card, 'last'),
  ObtainMostExpensive: (card) => new ObtainMostExpensiveCardTributeLogic(card),
  ObtainMostValuable: (card) => new ObtainMostValuableCardTributeLogic(card),
  DraftSameAsMostFavor: (card) =>
    new ContestMostWinningOpponentTributeLogic(card),
  DefeatOpponent: (card) => new DefeatOpponentTributeLogic(card),
  GainCardWithoutMilitary: (card) =>
    new GainCardWithoutMilitaryTributeLogic(card),
  GainResource: (card) => new GainResourceTributeLogic(card),
  DraftAgainstHigherMilitary: (card) =>
    new ClashAgainstHigherBidOpponentTributeLogic(card),
  HaveFewestCounters: (card) => new HaveFewestCountersTributeLogic(card),
};

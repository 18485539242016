export type SessionUser = {
  id: string;
  name?: string;
  profilePictureURL?: string;
  canChangeName: boolean;
};

export default class Session {
  sessionUser_: SessionUser;

  constructor(sessionUser: SessionUser) {
    this.sessionUser_ = sessionUser;
  }

  getUser(): SessionUser {
    return this.sessionUser_;
  }
  getUserID(): string {
    return this.sessionUser_.id;
  }
  toJSON(): any {
    return {
      user: this.sessionUser_,
    };
  }
  static fromJSON(json: any) {
    return new Session(json.user);
  }
}

/** @jsx jsx */
/** @jsxFrag */
import { jsx, css, SerializedStyles } from '@emotion/react';

import { CardSubType, CardType } from '../game/CardTypes';
import { CardWithID } from '../game/Rules';
import CardView from './CardView';
import React from 'react';
import { validateCard } from '../game/GameModel';

const DefaultCard: CardWithID = {
  id: '100',
  index: 0,
  module: 'core',
  name: 'Leader',
  type: CardType.Leader,
  subType: CardSubType.Unit,
  age: 3,
  cost: 0,
  favor: 0,
  baseEffect: '',
  turnEffect: '1G 1M',
  gainSelfEffect: '2G 2M',
  draftOtherEffect: '',
  gainOtherEffect: '',
  imageName: 'ArtLeader.jpg',
  additionalCosts: '',
};

const LOCAL_STORAGE_KEY = 'debug_card';

export default function CardDebugPage() {
  const [card, setCard] = React.useState<CardWithID>(() => {
    const localCard = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (localCard) {
      try {
        const parsedCard = JSON.parse(localCard);
        validateCard(parsedCard);
        return parsedCard;
      } catch (e) {}
    }
    return DefaultCard;
  });
  const [cardText, setCardText] = React.useState<string>(() =>
    JSON.stringify(card, null, 2),
  );

  return (
    <div css={CardDebugStyles.container}>
      <div css={CardDebugStyles.previewContainer}>
        <div
          style={{
            zoom: 4,
          }}
        >
          <CardView card={card} />
        </div>
        <div
          style={{
            zoom: 2,
          }}
        >
          <CardView card={card} />
        </div>
        <div
          style={{
            zoom: 1,
          }}
        >
          <CardView card={card} />
        </div>
      </div>
      <div css={CardDebugStyles.controlsContainer}>
        <textarea
          style={{
            width: '100%',
            height: '100%',
          }}
          value={cardText}
          onChange={(e) => {
            console.log(e.target.value);
            setCardText(e.target.value);

            try {
              const newCard = JSON.parse(e.target.value);

              validateCard(newCard);

              setCard(newCard);
              localStorage.setItem(
                LOCAL_STORAGE_KEY,
                JSON.stringify(newCard, null, 2),
              );
            } catch (e) {}
          }}
        />
      </div>
    </div>
  );
}

const CardDebugStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
  }),
  previewContainer: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  controlsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,

    backgroundColor: 'white',
    minWidth: 200,
  }),
};

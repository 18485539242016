/** @jsx jsx */
/** @jsxFrag */
import { jsx, css } from '@emotion/react';
import { useCallback, useState } from 'react';

export type UserPrefs = {
  disableAnimations?: boolean;
};

export function getUserPrefs(): UserPrefs {
  const prefs = localStorage.getItem('userPrefs');
  if (prefs) {
    return JSON.parse(prefs);
  }
  return {};
}

export function setUserPrefs(prefs: Partial<UserPrefs>) {
  const existing = getUserPrefs();
  localStorage.setItem('userPrefs', JSON.stringify({ ...existing, ...prefs }));
}

type User = any;
export function UserPreferenceMenu(props: { user: User }) {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [newName, setNewName] = useState(props.user.name);
  const onNameSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (requestInProgress) {
        return;
      }
      setRequestInProgress(true);
      fetch('/auth/change-name', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newName }),
      }).then((response) => {
        setRequestInProgress(false);
        location.reload();
      });
    },
    [newName, requestInProgress, setRequestInProgress],
  );

  return (
    <div css={UserPreferenceMenuStyles.container}>
      <h2 css={UserPreferenceMenuStyles.title}>User Settings</h2>
      {props.user.canChangeName && (
        <form onSubmit={onNameSubmit}>
          <label>
            New Name:
            <input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </label>
          <input type="submit" value="Submit" />
        </form>
      )}
      <label>
        <input
          type="checkbox"
          checked={getUserPrefs().disableAnimations}
          onChange={(e) => {
            setUserPrefs({ disableAnimations: e.target.checked });
          }}
        />
        Disable Animations
      </label>
    </div>
  );
}
const UserPreferenceMenuStyles = {
  container: css({}),
  title: css({}),
};

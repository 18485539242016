
// generated/CardArt.ts
// @generated
import Academy from '../assets/card_images/ArtAcademy.jpg';
import Acolyte from '../assets/card_images/ArtAcolyte.jpg';
import AltarofBounty from '../assets/card_images/ArtAltarOfBounty.jpg';
import AltarofWar from '../assets/card_images/ArtAltarOfWar.jpg';
import Ambush from '../assets/card_images/ArtAmbush.jpg';
import Amphitheater from '../assets/card_images/ArtAmphitheater.jpg';
import Aqueduct from '../assets/card_images/ArtAqueduct.jpg';
import Archer from '../assets/card_images/ArtArcher.jpg';
import ArcheryRange from '../assets/card_images/ArtArcheryRange.jpg';
import Armory from '../assets/card_images/ArtArmoury.jpg';
import Ascension from '../assets/card_images/ArtAscension.jpg';
import AutocraticOath from '../assets/card_images/ArtAutocraticOath.jpg';
import Barracks from '../assets/card_images/ArtBarracks.jpg';
import Bathhouse from '../assets/card_images/ArtTempleDerion.jpg';
import BattleHymn from '../assets/card_images/ArtBattleHymn.jpg';
import BattlePriest from '../assets/card_images/ArtWarPriest.jpg';
import BellicoseLeader from '../assets/card_images/ArtBellicoseLeader.jpg';
import BlessedBounty from '../assets/card_images/ArtBlessedBounty.jpg';
import BlessedMight from '../assets/card_images/ArtBlessedMight.jpg';
import Bowman from '../assets/card_images/ArtBowman.jpg';
import BurialRites from '../assets/card_images/ArtBurialRites.jpg';
import Captain from '../assets/card_images/ArtCaptain.jpg';
import Caravan from '../assets/card_images/ArtCaravan.jpg';
import Charioteer from '../assets/card_images/ArtCharioteer.jpg';
import ChosenHero from '../assets/card_images/ArtChosenHero.jpg';
import CoercedNegotiation from '../assets/card_images/ArtCoercedNegotiation.jpg';
import Commander from '../assets/card_images/ArtCommander.jpg';
import Conscription from '../assets/card_images/ArtConscription.jpg';
import ContestedLand from '../assets/card_images/ArtContestedLand.jpg';
import ContestedMine from '../assets/card_images/ArtContestedMine.jpg';
import Craftsman from '../assets/card_images/ArtCraftsman.jpg';
import Cultist from '../assets/card_images/ArtCultist.jpg';
import DemocraticOath from '../assets/card_images/ArtDemocraticOath.jpg';
import DespoticLeader from '../assets/card_images/ArtDespoticLeader.jpg';
import DiplomaticLeader from '../assets/card_images/ArtPiousLeader.jpg';
import Diversion from '../assets/card_images/ArtDiversion.jpg';
import DivineBlessing from '../assets/card_images/ArtDivineBlessing.jpg';
import DivineOrator from '../assets/card_images/ArtDivineOrator.jpg';
import Druid from '../assets/card_images/ArtDruid.jpg';
import Embargo from '../assets/card_images/ArtEmbargo.jpg';
import Exploration from '../assets/card_images/ArtExploration.jpg';
import Farm from '../assets/card_images/ArtFarm.jpg';
import FavoredRains from '../assets/card_images/ArtFavoredRains.jpg';
import FestivalGrounds from '../assets/card_images/ArtFestivalGrounds.jpg';
import Festivities from '../assets/card_images/ArtFestivities.jpg';
import Fortifications from '../assets/card_images/ArtFortifications.jpg';
import FuneralPyre from '../assets/card_images/ArtFuneralPyre.jpg';
import GalaofSeasons from '../assets/card_images/ArtCeremonyOfHarvest.jpg';
import GalaofVictory from '../assets/card_images/ArtCeremonyOfPower.jpg';
import Gladiator from '../assets/card_images/ArtGladiator.jpg';
import Glory from '../assets/card_images/ArtGlory.jpg';
import Goliath from '../assets/card_images/ArtGoliath.jpg';
import Granary from '../assets/card_images/ArtGranary.jpg';
import Gravesite from '../assets/card_images/ArtGravesite.jpg';
import Guardsman from '../assets/card_images/ArtGuardsman.jpg';
import Guildhall from '../assets/card_images/ArtTownHall.jpg';
import HarvestFestival from '../assets/card_images/ArtHarvestFestival.jpg';
import HarvestSeason from '../assets/card_images/ArtHarvestSeason.jpg';
import HeroesFeast from '../assets/card_images/ArtHeroesFeast.jpg';
import HighPriest from '../assets/card_images/ArtHighPriest.jpg';
import HonortheFallen from '../assets/card_images/ArtHonorTheFallen.jpg';
import Horseman from '../assets/card_images/ArtHorseman.jpg';
import Hunter from '../assets/card_images/ArtHunter.jpg';
import InspiringBattle from '../assets/card_images/ArtInspiringBattle.jpg';
import IronMine from '../assets/card_images/ArtIronMine.jpg';
import Leader from '../assets/card_images/ArtLeader.jpg';
import Legionnaire from '../assets/card_images/ArtLegionnaire.jpg';
import Library from '../assets/card_images/ArtLibrary.jpg';
import Lighthouse from '../assets/card_images/ArtLighthouse.jpg';
import LumberCamp from '../assets/card_images/ArtCamp.jpg';
import MarchOnward from '../assets/card_images/ArtMarchOnward.jpg';
import Marketplace from '../assets/card_images/ArtMarketplace.jpg';
import Mausoleum from '../assets/card_images/ArtMausoleum.jpg';
import Memorial from '../assets/card_images/ArtMemorial.jpg';
import MercantileLeader from '../assets/card_images/ArtMercantileLeader.jpg';
import MercenaryI from '../assets/card_images/ArtMercenary.jpg';
import MercenaryII from '../assets/card_images/ArtMercenary2.jpg';
import MercenaryIII from '../assets/card_images/ArtMercenary3.jpg';
import Merchant from '../assets/card_images/ArtMerchant.jpg';
import MilitantLeader from '../assets/card_images/ArtConqueringLeader.jpg';
import Militia from '../assets/card_images/ArtMilitia.jpg';
import Mill from '../assets/card_images/ArtMill.jpg';
import MiningCamp from '../assets/card_images/ArtMine.jpg';
import MonolithofMight from '../assets/card_images/ArtMonolithOfMight.jpg';
import Monument from '../assets/card_images/ArtStatue.jpg';
import NewRoads from '../assets/card_images/ArtNewRoads.jpg';
import Oppressor from '../assets/card_images/ArtOppressor.jpg';
import Oracle from '../assets/card_images/ArtOracle.jpg';
import Outpost from '../assets/card_images/ArtOutpost.jpg';
import Palisades from '../assets/card_images/ArtPalisades.jpg';
import Pantheon from '../assets/card_images/ArtPantheon.jpg';
import Pilgrimage from '../assets/card_images/ArtPilgrimage.jpg';
import Pillage from '../assets/card_images/ArtPillage.jpg';
import Plunderer from '../assets/card_images/ArtPlunderer.jpg';
import PriestofPassage from '../assets/card_images/ArtPriestOfPassage.jpg';
import Profiteer from '../assets/card_images/ArtProfiteer.jpg';
import Prophet from '../assets/card_images/ArtProphet.jpg';
import Quarry from '../assets/card_images/ArtQuarry.jpg';
import Ranger from '../assets/card_images/ArtRanger.jpg';
import RavagesofWar from '../assets/card_images/ArtRavagesOfWar.jpg';
import RelicTrade from '../assets/card_images/ArtRelicTrade.jpg';
import RichesTrade from '../assets/card_images/ArtRichesTrade.jpg';
import RitualofHeroes from '../assets/card_images/ArtRitualOfHeroes.jpg';
import RitualofMyths from '../assets/card_images/ArtRitual.jpg';
import RitualofPolis from '../assets/card_images/ArtRitualOfPolis.jpg';
import Sacrifice from '../assets/card_images/ArtSacrifice.jpg';
import ScavengeI from '../assets/card_images/ArtScavenge.jpg';
import ScavengeII from '../assets/card_images/ArtScavenge2.jpg';
import ScavengeIII from '../assets/card_images/ArtScavenge3.jpg';
import Scavenger from '../assets/card_images/ArtScavenger.jpg';
import Scout from '../assets/card_images/ArtScout.jpg';
import Scribe from '../assets/card_images/ArtScribe.jpg';
import SeizetheSpoils from '../assets/card_images/ArtSeizeTheSpoils.jpg';
import SettlementI from '../assets/card_images/ArtLaborCamp.jpg';
import SettlementII from '../assets/card_images/ArtLaborCamp2.jpg';
import SettlementIII from '../assets/card_images/ArtLaborCamp3.jpg';
import ShortSwordsman from '../assets/card_images/ArtShortSwordsman.jpg';
import ShrineofBounty from '../assets/card_images/ArtShrineOfBounty.jpg';
import ShrineofTribute from '../assets/card_images/ArtShrineOfTribute.jpg';
import ShrineofWar from '../assets/card_images/ArtShrineOfWar.jpg';
import Slinger from '../assets/card_images/ArtSlinger.jpg';
import Spearman from '../assets/card_images/ArtSpearman.jpg';
import Stable from '../assets/card_images/ArtStable.jpg';
import Stonemason from '../assets/card_images/ArtStonemason.jpg';
import Strategist from '../assets/card_images/ArtStrategist.jpg';
import StrikeGold from '../assets/card_images/ArtStrikeGold.jpg';
import Swordsman from '../assets/card_images/ArtSwordsman.jpg';
import Tavern from '../assets/card_images/ArtTavern.jpg';
import TempestofWar from '../assets/card_images/ArtTempestOfWar.jpg';
import Temple from '../assets/card_images/ArtTempleBaeren.jpg';
import TempleGuard from '../assets/card_images/ArtTempleGuard.jpg';
import TerritorialDispute from '../assets/card_images/ArtTerritorialDispute.jpg';
import TheocraticOath from '../assets/card_images/ArtTheocraticOath.jpg';
import Tracker from '../assets/card_images/ArtTracker.jpg';
import TradeDeal from '../assets/card_images/ArtTradeDeal.jpg';
import TradingPost from '../assets/card_images/ArtTradingPost.jpg';
import TrainingCamp from '../assets/card_images/ArtTrainingCamp.jpg';
import TreasureVault from '../assets/card_images/ArtTreasureVault.jpg';
import Veteran from '../assets/card_images/ArtVeteran.jpg';
import Villager from '../assets/card_images/ArtVillager.jpg';
import Vineyard from '../assets/card_images/ArtVineyard.jpg';
import Visions from '../assets/card_images/ArtVisions.jpg';
import Walls from '../assets/card_images/ArtWalls.jpg';
import WanderingLeader from '../assets/card_images/ArtWanderingLeader.jpg';
import WarHistorian from '../assets/card_images/ArtWarHistorian.jpg';
import Warlord from '../assets/card_images/ArtWarlord.jpg';
import Warrior from '../assets/card_images/ArtConscriptionCamp.jpg';
import Warsinger from '../assets/card_images/ArtWarsinger.jpg';
import Watchtower from '../assets/card_images/ArtWatchtower.jpg';
import WeaponsTrade from '../assets/card_images/ArtArmsTrade.jpg';
import Worship from '../assets/card_images/GenericPrayer.jpg';

export const CardArt = {
  "ArtAcademy.jpg": Academy,
  "ArtAcolyte.jpg": Acolyte,
  "ArtAltarOfBounty.jpg": AltarofBounty,
  "ArtAltarOfWar.jpg": AltarofWar,
  "ArtAmbush.jpg": Ambush,
  "ArtAmphitheater.jpg": Amphitheater,
  "ArtAqueduct.jpg": Aqueduct,
  "ArtArcher.jpg": Archer,
  "ArtArcheryRange.jpg": ArcheryRange,
  "ArtArmoury.jpg": Armory,
  "ArtAscension.jpg": Ascension,
  "ArtAutocraticOath.jpg": AutocraticOath,
  "ArtBarracks.jpg": Barracks,
  "ArtTempleDerion.jpg": Bathhouse,
  "ArtBattleHymn.jpg": BattleHymn,
  "ArtWarPriest.jpg": BattlePriest,
  "ArtBellicoseLeader.jpg": BellicoseLeader,
  "ArtBlessedBounty.jpg": BlessedBounty,
  "ArtBlessedMight.jpg": BlessedMight,
  "ArtBowman.jpg": Bowman,
  "ArtBurialRites.jpg": BurialRites,
  "ArtCaptain.jpg": Captain,
  "ArtCaravan.jpg": Caravan,
  "ArtCharioteer.jpg": Charioteer,
  "ArtChosenHero.jpg": ChosenHero,
  "ArtCoercedNegotiation.jpg": CoercedNegotiation,
  "ArtCommander.jpg": Commander,
  "ArtConscription.jpg": Conscription,
  "ArtContestedLand.jpg": ContestedLand,
  "ArtContestedMine.jpg": ContestedMine,
  "ArtCraftsman.jpg": Craftsman,
  "ArtCultist.jpg": Cultist,
  "ArtDemocraticOath.jpg": DemocraticOath,
  "ArtDespoticLeader.jpg": DespoticLeader,
  "ArtPiousLeader.jpg": DiplomaticLeader,
  "ArtDiversion.jpg": Diversion,
  "ArtDivineBlessing.jpg": DivineBlessing,
  "ArtDivineOrator.jpg": DivineOrator,
  "ArtDruid.jpg": Druid,
  "ArtEmbargo.jpg": Embargo,
  "ArtExploration.jpg": Exploration,
  "ArtFarm.jpg": Farm,
  "ArtFavoredRains.jpg": FavoredRains,
  "ArtFestivalGrounds.jpg": FestivalGrounds,
  "ArtFestivities.jpg": Festivities,
  "ArtFortifications.jpg": Fortifications,
  "ArtFuneralPyre.jpg": FuneralPyre,
  "ArtCeremonyOfHarvest.jpg": GalaofSeasons,
  "ArtCeremonyOfPower.jpg": GalaofVictory,
  "ArtGladiator.jpg": Gladiator,
  "ArtGlory.jpg": Glory,
  "ArtGoliath.jpg": Goliath,
  "ArtGranary.jpg": Granary,
  "ArtGravesite.jpg": Gravesite,
  "ArtGuardsman.jpg": Guardsman,
  "ArtTownHall.jpg": Guildhall,
  "ArtHarvestFestival.jpg": HarvestFestival,
  "ArtHarvestSeason.jpg": HarvestSeason,
  "ArtHeroesFeast.jpg": HeroesFeast,
  "ArtHighPriest.jpg": HighPriest,
  "ArtHonorTheFallen.jpg": HonortheFallen,
  "ArtHorseman.jpg": Horseman,
  "ArtHunter.jpg": Hunter,
  "ArtInspiringBattle.jpg": InspiringBattle,
  "ArtIronMine.jpg": IronMine,
  "ArtLeader.jpg": Leader,
  "ArtLegionnaire.jpg": Legionnaire,
  "ArtLibrary.jpg": Library,
  "ArtLighthouse.jpg": Lighthouse,
  "ArtCamp.jpg": LumberCamp,
  "ArtMarchOnward.jpg": MarchOnward,
  "ArtMarketplace.jpg": Marketplace,
  "ArtMausoleum.jpg": Mausoleum,
  "ArtMemorial.jpg": Memorial,
  "ArtMercantileLeader.jpg": MercantileLeader,
  "ArtMercenary.jpg": MercenaryI,
  "ArtMercenary2.jpg": MercenaryII,
  "ArtMercenary3.jpg": MercenaryIII,
  "ArtMerchant.jpg": Merchant,
  "ArtConqueringLeader.jpg": MilitantLeader,
  "ArtMilitia.jpg": Militia,
  "ArtMill.jpg": Mill,
  "ArtMine.jpg": MiningCamp,
  "ArtMonolithOfMight.jpg": MonolithofMight,
  "ArtStatue.jpg": Monument,
  "ArtNewRoads.jpg": NewRoads,
  "ArtOppressor.jpg": Oppressor,
  "ArtOracle.jpg": Oracle,
  "ArtOutpost.jpg": Outpost,
  "ArtPalisades.jpg": Palisades,
  "ArtPantheon.jpg": Pantheon,
  "ArtPilgrimage.jpg": Pilgrimage,
  "ArtPillage.jpg": Pillage,
  "ArtPlunderer.jpg": Plunderer,
  "ArtPriestOfPassage.jpg": PriestofPassage,
  "ArtProfiteer.jpg": Profiteer,
  "ArtProphet.jpg": Prophet,
  "ArtQuarry.jpg": Quarry,
  "ArtRanger.jpg": Ranger,
  "ArtRavagesOfWar.jpg": RavagesofWar,
  "ArtRelicTrade.jpg": RelicTrade,
  "ArtRichesTrade.jpg": RichesTrade,
  "ArtRitualOfHeroes.jpg": RitualofHeroes,
  "ArtRitual.jpg": RitualofMyths,
  "ArtRitualOfPolis.jpg": RitualofPolis,
  "ArtSacrifice.jpg": Sacrifice,
  "ArtScavenge.jpg": ScavengeI,
  "ArtScavenge2.jpg": ScavengeII,
  "ArtScavenge3.jpg": ScavengeIII,
  "ArtScavenger.jpg": Scavenger,
  "ArtScout.jpg": Scout,
  "ArtScribe.jpg": Scribe,
  "ArtSeizeTheSpoils.jpg": SeizetheSpoils,
  "ArtLaborCamp.jpg": SettlementI,
  "ArtLaborCamp2.jpg": SettlementII,
  "ArtLaborCamp3.jpg": SettlementIII,
  "ArtShortSwordsman.jpg": ShortSwordsman,
  "ArtShrineOfBounty.jpg": ShrineofBounty,
  "ArtShrineOfTribute.jpg": ShrineofTribute,
  "ArtShrineOfWar.jpg": ShrineofWar,
  "ArtSlinger.jpg": Slinger,
  "ArtSpearman.jpg": Spearman,
  "ArtStable.jpg": Stable,
  "ArtStonemason.jpg": Stonemason,
  "ArtStrategist.jpg": Strategist,
  "ArtStrikeGold.jpg": StrikeGold,
  "ArtSwordsman.jpg": Swordsman,
  "ArtTavern.jpg": Tavern,
  "ArtTempestOfWar.jpg": TempestofWar,
  "ArtTempleBaeren.jpg": Temple,
  "ArtTempleGuard.jpg": TempleGuard,
  "ArtTerritorialDispute.jpg": TerritorialDispute,
  "ArtTheocraticOath.jpg": TheocraticOath,
  "ArtTracker.jpg": Tracker,
  "ArtTradeDeal.jpg": TradeDeal,
  "ArtTradingPost.jpg": TradingPost,
  "ArtTrainingCamp.jpg": TrainingCamp,
  "ArtTreasureVault.jpg": TreasureVault,
  "ArtVeteran.jpg": Veteran,
  "ArtVillager.jpg": Villager,
  "ArtVineyard.jpg": Vineyard,
  "ArtVisions.jpg": Visions,
  "ArtWalls.jpg": Walls,
  "ArtWanderingLeader.jpg": WanderingLeader,
  "ArtWarHistorian.jpg": WarHistorian,
  "ArtWarlord.jpg": Warlord,
  "ArtConscriptionCamp.jpg": Warrior,
  "ArtWarsinger.jpg": Warsinger,
  "ArtWatchtower.jpg": Watchtower,
  "ArtArmsTrade.jpg": WeaponsTrade,
  "GenericPrayer.jpg": Worship,
};

import React from 'react';

interface Props {
  title: string;
  additionalClassName?: string;
  id?: string;
}
export default class PilesContainerView extends React.Component<Props> {
  render() {
    let { id, additionalClassName } = this.props;
    let cssClassName = 'pile ' + (additionalClassName || '');
    return (
      <div id={id} className={cssClassName}>
        <div className="pile-title">{this.props.title}</div>
        {this.props.children}
      </div>
    );
  }
}

/** @jsx jsx */
/** @jsxFrag */
import _, { Dictionary } from 'underscore';
import * as d3 from 'd3';
import React from 'react';
import { Link } from 'react-router-dom';
import { jsx } from '@emotion/react';
import { InflatedGame } from '../game/Game';

import Stats, { MilitaryStats } from '../game/Stats';
import { AGES_PER_GAME } from '../game/GameConstants';

import { Colors as PlayerColors } from './PlayerColors';

import '../assets/css/end-of-game.css';
import { CardSubType, CardSubTypes, CardType } from '../game/CardTypes';

import { CardSubtypeToSymbol, SymbolView } from './Symbols';
import HoverCardStore from './HoverCardStore';
import CardView, { BasicCardRenderer } from './CardView';
import { css } from '@emotion/react';
import { ageString } from '../game/Utility';
import { LobbyButtonStyles } from './LobbyButton';
import { CardWithID } from '../game/Rules';
import invariant from 'invariant';

type User = any;

function createPieChart(dom: HTMLDivElement, props: PieChartProps) {
  var width = props.width;
  var height = props.height;
  var data = props.data;
  var sum = data.reduce(function (memo, num) {
    return memo + num.count;
  }, 0);
  var chart = d3
    .select(dom)
    .append('svg')
    .attr('class', 'd3')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + props.width / 2 + ',' + height / 2 + ')');
  var outerRadius = props.width / 2.2;
  var innerRadius = props.width / 7;

  type Datum = PieChartProps['data'][number];

  var colors = props.colors;
  var pieData = d3.pie<Datum>().value(function (d) {
    return d.count;
  })(data);
  type PieDatum = (typeof pieData)[number];
  var arc = d3
    .arc<PieDatum>()
    .outerRadius(outerRadius)
    .innerRadius(innerRadius);

  var g = chart
    .selectAll('.arc')
    .data(pieData)
    .enter()
    .append('g')
    .attr('class', 'arc')
    .on('click', function (d) {
      //alert('you clicked ' + d.data.name)
    })
    .on('mouseover', function (event, i) {
      d3.select<SVGElement, PieDatum>(this)
        .transition()
        .duration(500)
        // .ease('bounce')
        .attr('transform', (d) => {
          var dist = 5;
          const midAngle = (d.endAngle + d.startAngle) / 2;
          var x = Math.sin(midAngle) * dist;
          var y = -Math.cos(midAngle) * dist;
          return 'translate(' + x + ',' + y + ')';
        });
      d3.select<SVGElement, PieDatum>(this)
        .append('text')
        .attr('id', 'percent')
        .attr('transform', 'translate(0,0)')
        .attr('text-anchor', 'middle')
        .attr('dy', '.30em')
        .style('font', 'bold 8px Arial')
        .text(function (d) {
          return ((d.value / sum) * 100).toFixed(0) + '%';
        });
      g.filter(function (e) {
        return e != event;
      }).style('opacity', 0.7);
    })
    .on('mouseout', function (d, i) {
      d3.select<SVGElement, PieDatum>(this)
        .transition()
        .duration(500)
        // .ease('bounce')
        .attr('transform', 'translate(0,0)');
      d3.select('#percent').remove();
      g.filter(function (e) {
        return e != d;
      }).style('opacity', 1);
    });

  g.append('path')
    .style('fill', function (d, i) {
      return colors[i];
    })
    .transition()
    .delay(function (d, i) {
      return i * 400;
    })
    .duration(400)
    .attrTween('d', (d) => {
      var i = d3.interpolate(d.startAngle, d.endAngle);
      return (t) => {
        d.endAngle = i(t);
        return arc(d)!;
      };
    });
  g.filter((d) => {
    return d.endAngle - d.startAngle > 0.1;
  })
    .append('text')
    .style('fill', 'white')
    .attr('transform', (d) => {
      return 'translate(' + arc.centroid(d) + ')';
    })
    .attr('text-anchor', 'middle')
    .attr('dy', '.35em')
    .text(function (d) {
      return d.value;
    });
}

function removePieChart(dom: HTMLDivElement): void {
  d3.select(dom).selectAll('svg').remove();
}

interface PieChartProps {
  width: number;
  height: number;
  title: string;
  data: { name: string; count: number }[];
  colors: string[];
  color?: string;
}
class PieChart extends React.Component<PieChartProps> {
  private _ref: React.RefObject<HTMLDivElement> = React.createRef();
  static defaultProps = {
    width: 100,
    height: 100,
    title: '',
    colors: ['#FD9827', '#DA3B21', '#3669C9', '#1D9524', '#971497'],
  };

  render() {
    var name_style = { color: this.props.color };
    return (
      <div ref={this._ref}>
        <h4 className="piechart-title" style={name_style}>
          {' '}
          {this.props.title}{' '}
        </h4>
      </div>
    );
  }

  componentDidMount() {
    createPieChart(this._ref.current!, this.props);
  }
  componentWillUnmount(): void {
    this._ref.current && removePieChart(this._ref.current);
  }
}

function createLineChart(dom: HTMLDivElement, props: LineChartProps) {
  var data = props.data;

  //** Domain
  var xExtend = d3.extent(data[0].values, function (d) {
      return d.turn;
    }),
    yMin = d3.min(data, function (d) {
      return d3.min(d.values, function (v) {
        return v.value;
      });
    }),
    yMax = d3.max(data, function (d) {
      return d3.max(d.values, function (v) {
        return v.value;
      });
    });
  let tickLabels = _.map(data[0].values, (val, i) => {
    if (i === 0) return 'Start';
    // 'End' not rendered for now
    if (i === props.turnsPerAge * AGES_PER_GAME + 1) return 'End';
    return `${((i - 1) % props.turnsPerAge) + 1}`;
  });
  //** Chart Dimension
  var margin = { top: 20, left: 50, right: 50, bottom: 20 },
    svgWidth = 710,
    svgHeight = 185,
    width = svgWidth - margin.left - margin.right,
    height = svgHeight - margin.top - margin.bottom,
    //** Scale
    xScale = d3.scaleLinear().range([0, width]),
    yScale = d3.scaleLinear().range([height, 0]),
    //** Axis
    xAxis = d3
      .axisBottom(xScale)
      .ticks(Math.max(1, _.size(data[0].values) - 1))
      .tickFormat((d, i) => tickLabels[i]),
    yAxis = d3
      .axisLeft(yScale)
      .ticks(Math.min(props.turnsPerAge, yMax! - yMin! + 1)),
    //** Line function
    line = d3
      .line<{ turn: Number; value: number }>()
      .x(function (d) {
        return xScale(d.turn);
      })
      .y(function (d) {
        return yScale(d.value);
      });

  //** Color
  //colors = d3.scale.category10(),
  let playerIDToColor = props.playerIDToColor;

  invariant(xExtend[0] !== undefined, 'ts');
  xScale.domain(xExtend);
  yScale.domain([yMin!, yMax!]);

  //** Setup container and skeleton of chart
  var svg = d3.select(dom).selectAll('svg').data([data]),
    svgEnter = svg
      .enter()
      .append('svg')
      .attr('width', svgWidth)
      .attr('height', svgHeight)
      .append('g')
      .attr('class', 'graph')
      // .attr('background-color', 'black')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

  // Draw axis
  svgEnter.append('g').attr('class', 'axis x');

  svgEnter
    .select<SVGSVGElement>('g.axis.x')
    .attr('transform', 'translate(0, ' + height + ')')
    .call(xAxis);

  svgEnter.append('g').attr('class', 'axis y').call(yAxis);

  // Line

  const lines = svgEnter
    .append('g')
    .attr('class', 'line-container')
    .selectAll('.line')
    .data(data);

  lines
    .enter()
    .append('path')
    .attr('class', function (d, i) {
      return 'line' + d.id;
    })
    .attr('fill', 'none')
    .attr('stroke', function (d, i) {
      return playerIDToColor[d.id];
    })
    .attr('d', function (d) {
      return line(d.values);
    });

  // Hover line & invisible rect
  var hoverLineGroup = svgEnter.append('g').attr('class', 'hover-line');

  // Add the line to the group
  var hoverLine = hoverLineGroup
    .append('line')
    .attr('id', 'hover-line')
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', 0)
    .attr('y2', height)
    .style('stroke-opacity', 0);

  // Tooltip
  var toolTip = d3.select('body').append('div').attr('class', 'chart-tooltip');
  var toolTipTurn = toolTip.append('text').attr('class', 'chart-tooltip-turn');
  var toolTipPlayerList = toolTip
    .append('ul')
    .attr('class', 'chart-tooltip-player-list');

  const playerText = data.map((playerData) => {
    return toolTipPlayerList
      .append('li')
      .attr('class', 'chart-tooltip-player-text')
      .style('color', playerIDToColor[playerData.id]);
  });

  // Mouse action helpers
  const mouseOut = () => {
    // Hide Tooltip and Hover line
    toolTip.style('visibility', 'hidden');
    hoverLine.style('stroke-opacity', 0);
  };

  const mouseMove = (event, d) => {
    var mouse = d3.pointer(event),
      mouseX = mouse[0],
      mouseY = mouse[1],
      turn = Math.round(xScale.invert(mouseX));

    var arr = data[0].values;
    arr.sort(function (a, b) {
      return a.turn - b.turn;
    });

    _.each(
      playerText,
      function (obj, i) {
        obj.text(data[i].series + ' : ' + data[i].values[turn].value);
      },
      this,
    );

    // Display Hover line
    hoverLine.attr('x1', mouseX).attr('x2', mouseX).style('stroke-opacity', 1);

    const getVirtualPosition = (el) => {
      if (el.parentNode.nodeName === 'svg') {
        return el.parentNode.getBoundingClientRect();
      }
      return getVirtualPosition(el.parentNode);
    };
    var virtual_position = getVirtualPosition(event.target);

    // Display tool tip
    toolTip
      .style('visibility', 'visible')
      .style('left', virtual_position.left + mouseX + 70 + 'px')
      .style('top', virtual_position.top + mouseY + 20 + 'px');
    toolTipTurn.text('Turn ' + turn);
  };

  // Create a invisible rect for mouse tracking
  svgEnter
    .append('rect')
    .attr('width', width)
    .attr('height', height)
    .attr('fill', 'none')
    .style('pointer-events', 'all')
    .on('mouseout', mouseOut)
    .on('mousemove', mouseMove);
}

interface LineChartProps {
  title: string;
  data: {
    series: any;
    values: { turn: number; value: number }[];
    id: string;
  }[];
  playerIDToColor: { [k: string]: string };
  turnsPerAge: number;
}
class LineChart extends React.Component<LineChartProps> {
  static defaultProps = {
    title: '',
  };
  private _ref: React.RefObject<HTMLDivElement> = React.createRef();

  render() {
    return (
      <div ref={this._ref}>
        <h4> {this.props.title} </h4>
      </div>
    );
  }

  componentDidMount() {
    createLineChart(this._ref.current!, this.props);
  }
}

interface PlayerTurnTableProps<TDatum> {
  title: string;
  data: { [k: string]: TDatum }[];
  playerIDToName: { [k: string]: string };
  playerIDToColor: { [k: string]: string };
  turnsPerAge: number;
  datumToContentFunc: (datum: TDatum) => any;
  includeTurn0?: boolean;
  datumToCellClassFunc?: (datum: TDatum) => string;
  datumToCellStyleFunc?: (datum: TDatum) => React.CSSProperties;
  onEnterDatum?: (datum: TDatum) => void;
  onLeaveDatum?: (datum: TDatum) => void;
  className?: string;
}
class PlayerTurnTable<TDatum = any> extends React.Component<
  PlayerTurnTableProps<TDatum>
> {
  render() {
    const {
      data,
      playerIDToName,
      playerIDToColor,
      datumToContentFunc,
      datumToCellClassFunc,
      datumToCellStyleFunc,
      onEnterDatum,
      onLeaveDatum,
    } = this.props;

    const turns = data.length;

    var player_data: { [k: string]: TDatum[] } = {};
    data.forEach((entry) => {
      _.each(entry, (result, player) => {
        if (!player_data[player]) {
          player_data[player] = [];
        }
        player_data[player].push(result);
      });
    });

    var columns = _.times(turns, (i) => {
      return <col key={i + 1} />;
    });

    var xaxis = _.times(turns, (turn) => {
      let turn_in_age = 0;
      if (this.props.includeTurn0) {
        if (turn > 0) {
          turn_in_age = ((turn - 1) % this.props.turnsPerAge) + 1;
        }
      } else {
        turn_in_age = (turn % this.props.turnsPerAge) + 1;
      }
      return (
        <th className={'turn_' + turn_in_age} key={turn}>
          {turn_in_age}
        </th>
      );
    });
    xaxis = [<th key="header">Player</th>].concat(xaxis);

    var table_contents = [<tr key="xaxis">{xaxis}</tr>];

    table_contents = table_contents.concat(
      _.map(
        player_data,
        (entries, playerID) => {
          var name_style = { color: playerIDToColor[playerID] };
          var row = [
            <td key="name" style={name_style}>
              {playerIDToName[playerID]}
            </td>,
          ];

          row = row.concat(
            entries.map((datum, i) => {
              const content = datumToContentFunc(datum);
              const class_name = datumToCellClassFunc?.(datum);
              const style = datumToCellStyleFunc?.(datum);

              return (
                <td
                  key={i}
                  className={class_name}
                  style={style}
                  onMouseEnter={onEnterDatum && (() => onEnterDatum(datum))}
                  onMouseLeave={onLeaveDatum && (() => onLeaveDatum(datum))}
                >
                  {content}
                </td>
              );
            }),
          );

          return <tr key={playerID}>{row}</tr>;
        },
        this,
      ),
    );

    let tableClassName =
      'player-turn-table ' +
      this.props.className +
      (this.props.includeTurn0 ? ' stats-table-include-turn0' : '');
    return (
      <div>
        <h4 className="graphs-title">{this.props.title}</h4>
        <table className={tableClassName}>
          <colgroup>{columns}</colgroup>
          <tbody>{table_contents}</tbody>
        </table>
      </div>
    );
  }
}

type PlayerLineGraphProps = {
  userByID: { [k: string]: User };
  playerIDToColor: { [k: string]: string };
  data: any[];
  turnsPerAge: number;
  title: string;
};
const PlayerLineGraph: React.FC<PlayerLineGraphProps> = (props) => {
  let { userByID, data, title, playerIDToColor } = props;
  let series = _.map(userByID, (user, id) => {
    let values = _.map(data, (data_by_player, i) => {
      let value = data_by_player[id];
      let turn = i;
      return { turn, value };
    });
    return { series: user.name, values, id };
  });

  return (
    <div className="graphs-favorgained-line">
      <LineChart
        key={title}
        data={series}
        playerIDToColor={playerIDToColor}
        title={title}
        turnsPerAge={props.turnsPerAge}
      />
    </div>
  );
};

const LineChartTabContainer = (props: {
  children: React.ReactElement<PlayerLineGraphProps>[];
}) => {
  const { children } = props;
  const [selectedTab, setSelectedTab] = React.useState(0);
  return (
    <div className="line-chart-tab-container">
      <div className="line-chart-tab-buttons">
        {React.Children.map(children, (chart, i) => (
          <button
            key={chart.key || undefined}
            onClick={() => setSelectedTab(i)}
            className={selectedTab === i ? 'selected' : ''}
          >
            {chart.props.title}
          </button>
        ))}
      </div>
      <div className="line-chart-tab-content">{children[selectedTab]}</div>
    </div>
  );
};

const CARD_TYPE_TO_COLOR: Record<CardType, string> = {
  Basic: 'rgb(128,98,50)',
  Resource: 'rgb(55,150,95)',
  Conflict: 'rgb(193,105,60)',
  Prayer: 'rgb(70,130,195)',
  Leader: 'rgb(140,140,140)',
  // Epic: 'rgb(90,25,140)',
};

function create_player_time_pie_chart(
  user_by_id,
  title,
  count_by_id,
  color_by_id,
) {
  let colors: string[] = [];
  let data = _.map(user_by_id, (user: any, id) => {
    colors.push(color_by_id[id]);
    return { name: user.name, count: Math.floor(count_by_id[id] / 1000) };
  });
  return <PieChart data={data} colors={colors} title={title} />;
}

export default class EndOfGameView extends React.Component<{
  game: InflatedGame;
  userByID: { [k: string]: User };
}> {
  state = {
    minimize: false,
  };

  onClick = () => {
    this.setState({ minimize: !this.state.minimize });
  };

  getCardTypePieCharts = () => {
    var cardtypes_piecharts = _.map(
      this.props.game.players,
      function (player) {
        var playerCards = player.cards;
        var typeCounts: Partial<Record<CardType, number>> = _.reduce(
          CardType,
          (memo, cardType) => {
            memo[cardType] = 0;
            return memo;
          },
          {},
        );
        var total = 0;
        _.each(
          playerCards,
          function (card) {
            total++;
            typeCounts[card.type]!++;
          },
          this,
        );
        var colors: string[] = [];
        var data: any[] = _.reduce(
          typeCounts as Dictionary<number>,
          (memo, value, key: string) => {
            if (value > 0) {
              // @ts-ignore
              if (key == 'BASIC_RESOURCE') {
                key = CardType.Basic;
              }
              colors.push(CARD_TYPE_TO_COLOR[key]);
              // @ts-ignore
              memo.push({ name: key, count: value });
            }
            return memo;
          },
          [] as { name: CardType; count: number }[],
        );
        var playerIDToColor = {};
        _.each(
          this.props.game.players,
          function (player: any, i) {
            playerIDToColor[player.userID] = PlayerColors[i];
          },
          this,
        );
        var username = this.props.userByID[player.userID].name;
        var usercolor = playerIDToColor[player.userID];
        return (
          <div key={player.userID} className="graph-cardtypes-pie">
            <PieChart
              data={data}
              colors={colors}
              title={username}
              color={usercolor}
            />
          </div>
        );
      },
      this,
    );
    return cardtypes_piecharts;
  };

  getFavorCharts = () => {};

  render() {
    let { game, userByID } = this.props;
    let players = this.props.game.players;
    if (game.gameEndTimestamp === null) {
      return null;
    }
    let cardtypes_piecharts = this.getCardTypePieCharts();

    const userIDs = new Set(players.map((player) => player.userID));
    userByID = { ...userByID };
    Object.keys(userByID).forEach((id) => {
      if (!userIDs.has(id)) {
        delete userByID[id];
      }
    });

    var playerIDToName = _.mapObject(userByID, (user) => {
      return user.name;
    });
    var playerIDToColor = {};
    _.each(
      players,
      function (player, i) {
        playerIDToColor[player.userID] = PlayerColors[i];
      },
      this,
    );

    let extract_field = (raw_data, f) => {
      return _.map(raw_data, (data_by_player) => {
        return _.mapObject(data_by_player, f);
      });
    };

    let raw_favor_data = Stats.computeGainedFavorStats(game);
    let total_favor_data = extract_field(raw_favor_data, (data) => {
      return data.total_favor;
    });
    let tribute_favor_data = extract_field(raw_favor_data, (data) => {
      return data.total_tribute_favor;
    });
    let card_favor_data = extract_field(raw_favor_data, (data) => {
      return data.total_card_favor;
    });
    let trigger_favor_data = extract_field(raw_favor_data, (data) => {
      return data.total_triggered_favor;
    });

    let favor_data_charts = [
      <PlayerLineGraph
        data={tribute_favor_data}
        userByID={userByID}
        playerIDToColor={playerIDToColor}
        title={'Favor from Tributes'}
        turnsPerAge={game.turnsPerAge}
      />,
      <PlayerLineGraph
        data={card_favor_data}
        userByID={userByID}
        playerIDToColor={playerIDToColor}
        title={'Favor from Cards'}
        turnsPerAge={game.turnsPerAge}
      />,
    ];
    console.log(trigger_favor_data);
    // only use trigger favor data if there is any
    if (
      _.any(
        _.reduce(
          trigger_favor_data,
          (data, turn_data) => data.concat(_.values(turn_data) as any),
          [],
        ),
        (favor_value) => !!favor_value,
      )
    ) {
      favor_data_charts.push(
        <PlayerLineGraph
          data={trigger_favor_data}
          userByID={userByID}
          playerIDToColor={playerIDToColor}
          title={'Favor from Triggers'}
          turnsPerAge={game.turnsPerAge}
        />,
      );
    }

    let production_data = Stats.computeProductionData(game);
    let production_gold = extract_field(production_data, (data) => {
      return data.gold;
    });
    let production_military = extract_field(production_data, (data) => {
      return data.military;
    });
    let gold_income = extract_field(production_data, (data) => {
      return data.gold_income;
    });
    let military_income = extract_field(production_data, (data) => {
      return data.military_income;
    });
    let gold_base = extract_field(production_data, (data) => {
      return data.gold_base;
    });
    let military_base = extract_field(production_data, (data) => {
      return data.military_base;
    });
    let max_favor = _.max(_.map(players, (player) => player.counters.favor));
    let winning_players = _.filter(
      players,
      (player) => max_favor === player.counters.favor,
    );
    let winning_players_content = _.map(winning_players, (player, i) => {
      let style = {
        color: playerIDToColor[player.userID],
        display: 'inline-block',
      };
      let name = playerIDToName[player.userID];
      let conjuction =
        i !== _.size(winning_players) - 1 ? <span> and </span> : null;
      return (
        <span key={player.userID}>
          <span style={style}>{name}</span>
          {conjuction}
        </span>
      );
    });

    let sum_phase_time_data = function (timing_data) {
      return _.map(timing_data, (turn: any) => {
        return _.reduce(
          turn,
          (memo, phase: any) => {
            _.each(phase, (time, user_id) => {
              memo[user_id] = (memo[user_id] || 0) + time;
            });
            return memo;
          },
          {},
        );
      });
    };

    let time_data = Stats.getTimingDataByTurn(game);
    let total_turn_time_data = sum_phase_time_data(time_data);
    let time_per_turn_data = extract_field(total_turn_time_data, (data) => {
      return Math.round(data / 1000);
    });
    let blame_time_data = Stats.computeBlameTimingDataByTurn(game);
    let total_turn_blame_time_data = sum_phase_time_data(blame_time_data);
    let blame_time_per_turn_data = extract_field(
      total_turn_blame_time_data,
      (data) => {
        return Math.round(data / 1000);
      },
    );
    let solo_time_data = Stats.computeSoloThinkingTimingDataByTurn(game);
    let total_turn_solo_time_data = sum_phase_time_data(solo_time_data);
    let solo_time_per_turn_data = extract_field(
      total_turn_solo_time_data,
      (data) => {
        return Math.round(data / 1000);
      },
    );

    var cssGameView =
      'end-game-view ' + (this.state.minimize ? 'minimize' : '');
    var militaryData = Stats.computeMilitaryStats(game);
    var cardStatsData = Stats.computeGainedCard(game);
    const conflictResultsRenderer = makeConflictResultsHoverRenderer({
      playerIDToName,
      cardsByID: game.cardsByID as any,
    });
    return (
      <div className={cssGameView}>
        <div className="end-game-title">
          <div className="end-game-title-top">
            <div className="end-game-winners">
              {winning_players_content} wins!
            </div>
            <div className="end-game-return-wrapper">
              <Link
                css={LobbyButtonStyles.button}
                className="end-game-return"
                to={'/'}
              >
                Return to Lobby
              </Link>
            </div>
          </div>
          <div className="end-game-title-bottom">
            <div className="">
              <span> End of Game Stats </span>
              <button
                className="end-game-minimize-button"
                onClick={this.onClick}
              >
                {' '}
                -{' '}
              </button>
            </div>
          </div>
        </div>
        <div className="end-game-view-content">
          <PlayerLineGraph
            data={total_favor_data}
            userByID={userByID}
            playerIDToColor={playerIDToColor}
            title={'Total Favor'}
            turnsPerAge={game.turnsPerAge}
          />
          <PlayerTurnTable
            title="Conflict Results"
            className="montecarlo-table"
            data={militaryData}
            playerIDToName={playerIDToName}
            playerIDToColor={playerIDToColor}
            turnsPerAge={game.turnsPerAge}
            datumToContentFunc={(result) => {
              return result?.text || '';
            }}
            datumToCellClassFunc={(result) => {
              return result
                ? result.text.replace('+', '_plus').replace('-', '_minus')
                : '';
            }}
            onEnterDatum={(datum) => {
              if (!datum) {
                return;
              }
              HoverCardStore.setCard(datum, conflictResultsRenderer);
            }}
            onLeaveDatum={() => {
              HoverCardStore.setCard(null, null);
            }}
          />
          <h4 className="graphs-title">Card Type Distribution</h4>
          <div className="graphs-cardtypes-pie">{cardtypes_piecharts}</div>
          <PlayerTurnTable
            title="Card gained by turn"
            className="card-stats-table"
            data={cardStatsData}
            playerIDToName={playerIDToName}
            playerIDToColor={playerIDToColor}
            includeTurn0={true}
            turnsPerAge={game.turnsPerAge}
            datumToContentFunc={(result) => {
              return (
                <SymbolView
                  symbol={CardSubtypeToSymbol[result.subType]!}
                  style={{
                    height: 12,
                    width: 12,
                  }}
                />
              );
            }}
            datumToCellStyleFunc={(result) => {
              return {
                backgroundColor: CARD_TYPE_TO_COLOR[result.type],
              };
            }}
            onEnterDatum={(datum) => {
              HoverCardStore.setCard(datum, BasicCardRenderer);
            }}
            onLeaveDatum={() => {
              HoverCardStore.setCard(null, null);
            }}
          />
          <LineChartTabContainer>{favor_data_charts}</LineChartTabContainer>
          <LineChartTabContainer>
            <PlayerLineGraph
              data={production_military}
              userByID={userByID}
              playerIDToColor={playerIDToColor}
              title={'military tokens + base'}
              turnsPerAge={game.turnsPerAge}
            />
            <PlayerLineGraph
              data={military_income}
              userByID={userByID}
              playerIDToColor={playerIDToColor}
              title={'military generated'}
              turnsPerAge={game.turnsPerAge}
            />
            <PlayerLineGraph
              data={military_base}
              userByID={userByID}
              playerIDToColor={playerIDToColor}
              title={'base military'}
              turnsPerAge={game.turnsPerAge}
            />
          </LineChartTabContainer>
          <LineChartTabContainer>
            <PlayerLineGraph
              data={production_gold}
              userByID={userByID}
              playerIDToColor={playerIDToColor}
              title={'gold tokens + base'}
              turnsPerAge={game.turnsPerAge}
            />
            <PlayerLineGraph
              data={gold_income}
              userByID={userByID}
              playerIDToColor={playerIDToColor}
              title={'gold generated'}
              turnsPerAge={game.turnsPerAge}
            />
            <PlayerLineGraph
              data={gold_base}
              userByID={userByID}
              playerIDToColor={playerIDToColor}
              title={'base gold'}
              turnsPerAge={game.turnsPerAge}
            />
          </LineChartTabContainer>
          <LineChartTabContainer>
            <PlayerLineGraph
              data={time_per_turn_data}
              userByID={userByID}
              playerIDToColor={playerIDToColor}
              title={'Time Spent'}
              turnsPerAge={game.turnsPerAge}
            />
            <PlayerLineGraph
              data={blame_time_per_turn_data}
              userByID={userByID}
              playerIDToColor={playerIDToColor}
              title={'Blame Time'}
              turnsPerAge={game.turnsPerAge}
            />
            <PlayerLineGraph
              data={solo_time_per_turn_data}
              userByID={userByID}
              playerIDToColor={playerIDToColor}
              title={'Solo Thinking Time'}
              turnsPerAge={game.turnsPerAge}
            />
          </LineChartTabContainer>

          <div className="graphs-cardtypes-pie">
            {create_player_time_pie_chart(
              userByID,
              'Total Time',
              Stats.computeTotalTimeByPlayer(game),
              playerIDToColor,
            )}
            {create_player_time_pie_chart(
              userByID,
              'Blame Time',
              Stats.computeTotalBlameTimeByPlayer(game),
              playerIDToColor,
            )}
            {create_player_time_pie_chart(
              userByID,
              'Solo Time',
              Stats.computeTotalSoloThinkingTimeByPlayer(game),
              playerIDToColor,
            )}
          </div>
          <div>
            <span className="disclaimer"> More graphs to come! </span>
          </div>
        </div>
      </div>
    );
  }
}

function makeConflictResultsHoverRenderer(data: {
  playerIDToName: {
    [k: string]: string;
  };
  cardsByID: { [k: string]: CardWithID };
}) {
  const { playerIDToName, cardsByID } = data;
  return (props: { card: MilitaryStats }) => {
    const stats = props.card;
    return (
      <div>
        <CardView card={cardsByID[stats.cardID]} />
        <div css={ConflictResultsStyle.container}>
          Conflict Results - Age {ageString(stats.age)} Turn {stats.turn}
          {Object.keys(stats.playerIDToBonus).map((playerID) => {
            const bonus = stats.playerIDToBonus[playerID];
            const probability = stats.playerIDToProbability[playerID];
            return (
              <div key={playerID}>
                <span
                  css={ConflictResultsStyle.player}
                  style={{
                    color:
                      playerID === stats.winnerID
                        ? 'rgb(225, 95, 50)'
                        : 'black',
                  }}
                >
                  {playerIDToName[playerID]}
                </span>{' '}
                +{bonus} ({Math.round(probability * 100)}%)
              </div>
            );
          })}
        </div>
      </div>
    );
  };
}

const ConflictResultsStyle = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    zoom: 1 / 2.5,
    padding: 5,
  }),
  player: css({
    fontWeight: 'bold',
  }),
};

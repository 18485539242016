export const ResourceNameSet = {
  gold: 'gold',
  favor: 'favor',
  military: 'military',
  warTokens: 'warTokens',
} as const;

export type Resource = keyof typeof ResourceNameSet;

export const BasicResources = ['gold'] as const;

export const NameToResourceCharacter: Record<Resource, string> = {
  gold: 'G',
  favor: 'V',
  military: 'M',
  warTokens: 'W',
};

export const CharacterToResourceName: Record<string, Resource> = {
  G: 'gold',
  V: 'favor',
  M: 'military',
  W: 'warTokens',
};

export const ResourceToDisplayName: Record<Resource, string> = {
  gold: 'Gold',
  favor: 'Favor',
  military: 'Military',
  warTokens: 'Clash',
};

export enum Token {
  War = 'War',
}
export function isToken(char: string): char is Token {
  return Token.hasOwnProperty(char);
}
export const TokenToDisplayName: Record<Token, string> = {
  War: 'War',
};

const GameMutator = {
  sendAction: async function (
    gameID: string,
    action_type: string,
    payload: Object,
  ) {
    const action = { type: action_type, payload: payload };

    const res = await fetch(`/api/game/${gameID}/add_action`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ action: action }),
    });
    if (!res.ok) {
      console.log('error sending action', res.text);
      return;
    }
  },
};

export default GameMutator;

/** @jsx jsx */
/** @jsxFrag */
import { jsx, css } from '@emotion/react';
import LogoBannerImg from '../assets/logo_banner.svg';

export default function LogoBanner(props: {}) {
  return (
    <div css={LogoBannerStyles.container}>
      <img css={LogoBannerStyles.banner} src={LogoBannerImg} />
    </div>
  );
}

const LogoBannerStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  }),
  banner: css({
    alignSelf: 'center',
    height: 80,
    width: 700,

    margin: '20 0 20 0',
  }),
};

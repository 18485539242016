import { shuffle } from 'underscore';

export function addToMapOfArrays<K, V>(
  m: Map<K, V[]>,
  k: K,
  v: V,
): Map<K, V[]> {
  const existing = m.get(k);
  if (existing) {
    existing.push(v);
  } else {
    m.set(k, [v]);
  }
  return m;
}

export function nonNull<T>(x: T | null | undefined): x is T {
  return x !== null && x !== undefined;
}

export function makeStringID(length: number = 12): string {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function findReverse<T>(
  arr: ReadonlyArray<T>,
  predicate: (t: T) => boolean,
): T | undefined {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i])) {
      return arr[i];
    }
  }
  return undefined;
}

/**
 *
 * @param min Inclusive min
 * @param max Inclusive max
 */
export function randomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomFloatRange(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

export function randomNormal(mean: number, stdDev: number): number {
  // Box Meuller
  const u = Math.random(),
    v = Math.random();
  // Also Math.sin
  const z = Math.sqrt(-2 * Math.log(u)) * Math.cos(2 * Math.PI * v);

  return mean + stdDev * z;
}

export function coinFlip(headsFraction: number): boolean {
  return Math.random() < headsFraction;
}

export function randomSelect<T>(arr: Readonly<Array<T>>): T {
  return arr[randomInt(0, arr.length - 1)];
}

export function randomChoose<T>(arr: ReadonlyArray<T>, n: number): T[] {
  return shuffle<T>(arr).slice(0, n);
}

export function clamp(s: number, min: number, max: number): number {
  return Math.min(Math.max(s, min), max);
}

export function lerp(a: number, b: number, u: number): number {
  return a * (1 - u) + b * u;
}

export function smoothstep(a: number, b: number, u: number): number {
  const t = clamp((u - a) / (b - a), 0, 1);
  return t * t * (3 - 2 * t);
}

/** @jsx jsx */
/** @jsxFrag */
import { css, Global, jsx } from '@emotion/react';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CardDef, GameDefinitions, TributeDef } from '../game/GameModel';
import TributeCardView from './TributeCardView';
import CardView from './CardView';
import { CardType } from '../game/CardTypes';
import { CardBack, CardBackView } from './CardBackView';
import { fetchGameDefinitions } from './actions';
import { connect } from 'react-redux';

function PrintCardsPage(props: {
  gameDefinitions: GameDefinitions | null;
  dispatch: React.Dispatch<any>;
}) {
  const { gameDefinitions, dispatch } = props;
  const params = useParams();

  useEffect(() => {
    dispatch(fetchGameDefinitions());
  }, []);

  if (!gameDefinitions) {
    return <div>Loading...</div>;
  }

  const { cardDefs, tributeCardDefs } = gameDefinitions;
  // const module = gameDefinitions.modules.find(
  //   (module) => module.key === 'core_quick',
  // )!;
  // const { cardDefs, tributeCardDefs } = module;

  const basiccards = cardDefs.filter((card) => card.type === CardType.Basic);
  const leadercards = cardDefs.filter((card) => card.type === CardType.Leader);
  const age1cards = cardDefs.filter(
    (card) => card.type !== CardType.Basic && card.age === 1,
  );
  const age2cards = cardDefs.filter(
    (card) => card.type !== CardType.Basic && card.age === 2,
  );
  const age3cards = cardDefs.filter(
    (card) => card.type !== CardType.Basic && card.age === 3,
  );
  const cardbacks: CardBack[] = [
    { id: 'age1back', type: 'age1' },
    { id: 'age2back', type: 'age2' },
    { id: 'age3back', type: 'age3' },
    { id: 'tributeback', type: 'tribute' },
  ];

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // flexWrap: 'wrap',
    // breakAfter: 'page',
  };

  return (
    <div className="App">
      <Global styles={PrintStyles.global} />
      <div id="tributecards" style={containerStyle}>
        {tributeCardDefs.map((card, i) => (
          <PrintCardContainer name={tributeName(card, i)} landscape={true}>
            <TributeCardView key={tributeName(card, i)} card={card} />
          </PrintCardContainer>
        ))}
      </div>
      <div id="leadercards" style={containerStyle}>
        {leadercards.map((card, i) => (
          <PrintCardContainer
            name={cardName(card, i)}
            count={card.name === 'Leader' ? 5 : 1}
            // count={0}
          >
            <CardView key={cardName(card, i)} card={card} />
          </PrintCardContainer>
        ))}
      </div>
      <div id="basiccards" style={containerStyle}>
        {basiccards.map((card, i) => (
          <PrintCardContainer name={cardName(card, i)} count={6}>
            <CardView key={cardName(card, i)} card={card} />
          </PrintCardContainer>
        ))}
      </div>
      <div id="age1cards" style={containerStyle}>
        {age1cards.map((card, i) => (
          <PrintCardContainer name={cardName(card, i)}>
            <CardView key={cardName(card, i)} card={card} />
          </PrintCardContainer>
        ))}
      </div>
      <div id="age2cards" style={containerStyle}>
        {age2cards.map((card, i) => (
          <PrintCardContainer name={cardName(card, i)}>
            <CardView key={cardName(card, i)} card={card} />
          </PrintCardContainer>
        ))}
      </div>
      <div id="age3cards" style={containerStyle}>
        {age3cards.map((card, i) => (
          <PrintCardContainer name={cardName(card, i)}>
            <CardView key={cardName(card, i)} card={card} />
          </PrintCardContainer>
        ))}
      </div>
      <div id="cardbacks" style={containerStyle}>
        {cardbacks.map((cardBack, i) => (
          <PrintCardContainer name={cardBackName(cardBack, i)} count={0}>
            <CardBackView key={cardBackName(cardBack, i)} card={cardBack} />
          </PrintCardContainer>
        ))}
      </div>
    </div>
  );
}
function mapStateToProps(state: any) {
  return {
    gameDefinitions: state.gameDefinitions,
  };
}
export default connect(mapStateToProps)(PrintCardsPage);

function cardName(card: CardDef, index: number): string {
  let prefix = `age${card.age}`;
  if (card.type === CardType.Leader) {
    prefix = 'leader';
  } else if (card.type === CardType.Basic) {
    prefix = 'basic';
  }
  return cardNameHelper(prefix, index, card.name);
}
function tributeName(card: TributeDef, index: number): string {
  return cardNameHelper('tribute', index, card.name);
}
function cardBackName(card: CardBack, index: number): string {
  return cardNameHelper('back', index, card.type);
}
function cardNameHelper(prefix: string, index: number, name: string): string {
  return `${prefix}-${index + 1}-${name.toLowerCase().replace(/ /g, '_')}`;
}

const PrintCardContainer = (props: {
  name: string;
  count?: number;
  landscape?: boolean;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <div
      css={PrintStyles.cardContainer}
      className="print-card-container"
      data-name={props.name}
      data-count={props.count}
    >
      <div
        css={
          props.landscape
            ? PrintStyles.cardSubContainerLandscape
            : PrintStyles.cardSubContainer
        }
      >
        {props.children}
      </div>
    </div>
  );
};

const PrintStyles = {
  global: css({
    '@media print': {
      body: {
        backgroundImage: 'none',
        backgroundColor: 'inherit',
      },
      '@page': {
        size: 'letter portrait',
        margin: 0,
      },
    },
  }),
  cardContainer: css({
    position: 'relative',

    // 300 dpi
    width: 816,
    height: 1110,

    backgroundColor: 'black',
  }),
  cardSubContainer: css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(7.44)',
  }),
  cardSubContainerLandscape: css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(-90deg) scale(7.44)',
  }),
};

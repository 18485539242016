import React from 'react';
import { formatDuration } from './ViewUtilities';

interface Props {
  splitStartTimestamp?: number;
  extraSplitTime?: number;
  extraPreSplitTime?: number;
  className?: string;
  style?: React.CSSProperties;
}

interface State {
  intervalID?: NodeJS.Timeout;
}

export default class GameTimeView extends React.Component<Props, State> {
  componentDidMount() {
    var intervalID = setInterval(
      function () {
        this.forceUpdate();
      }.bind(this),
      216,
    );
    this.setState({ intervalID: intervalID });
  }

  componentWillUnmount() {
    this.state && this.state.intervalID && clearInterval(this.state.intervalID);
  }

  render() {
    var splitTime = this.props.extraSplitTime || 0;
    if (this.props.splitStartTimestamp) {
      splitTime += Math.max(Date.now() - this.props.splitStartTimestamp, 0);
    }
    var extraTime = this.props.extraPreSplitTime
      ? this.props.extraPreSplitTime + splitTime
      : 0;
    var extraString = extraTime ? ' (' + formatDuration(extraTime) + ')' : '';
    return (
      <span className={this.props.className} style={this.props.style}>
        {formatDuration(splitTime) + extraString}
      </span>
    );
  }
}

/** @jsx jsx */
/** @jsxFrag */
import { jsx, css } from '@emotion/react';
import { connect } from 'react-redux';
import Session from './Session';
import { Portal } from '@restart/ui';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { getChannelListener } from './ChannelListener';
import { ChatMessage, fetchUsers } from './actions';
import { Map } from 'immutable';

import SendButtonImg from '../assets/chat_send.svg';
import { useLocation } from 'react-router-dom';

type User = any;

const ChatOverlay = (props: {
  session: Session | undefined;
  messagesByRoom: Map<string, ChatMessage[]>;
  userByID: Map<string, User>;
  dispatch: React.Dispatch<any>;
}) => {
  const { session, messagesByRoom, userByID, dispatch } = props;

  const location = useLocation();

  if (!session) {
    return null;
  }

  let locationChat: React.ReactNode = null;
  if (location.pathname.startsWith('/game/')) {
    const gameID = location.pathname.match(/\/game\/([^/]+)/)?.[1];
    const room = `game/${gameID}`;
    locationChat = (
      <ChatWindow
        room={room}
        title="Game Chat"
        messages={messagesByRoom.get(room) || []}
        userByID={userByID}
        dispatch={dispatch}
      />
    );
  } else if (location.pathname.startsWith('/lobby')) {
    const lobbyID = location.pathname.match(/\/lobby\/([^/]+)/)?.[1];
    const room = `lobby/${lobbyID}`;
    locationChat = (
      <ChatWindow
        room={room}
        title="Lobby Chat"
        messages={messagesByRoom.get(room) || []}
        userByID={userByID}
        dispatch={dispatch}
      />
    );
  }

  return (
    <Portal container={document.body}>
      <div css={ChatOverlayStyles.overlay}>
        {locationChat}
        {/* <ChatWindow
          room="global"
          title="Global Chat"
          messages={messagesByRoom.get('global') || []}
          userByID={userByID}
          dispatch={dispatch}
        /> */}
      </div>
    </Portal>
  );
};

const session = (state: any) => {
  return {
    session: state.session as Session | undefined,
    messagesByRoom: state.chatMessagesByRoom,
    userByID: state.userByID,
  };
};
export default connect(session)(ChatOverlay);

function ChatWindow(props: {
  room: string;
  title: string;
  messages: ChatMessage[];
  userByID: Map<string, User>;
  dispatch: React.Dispatch<any>;
}) {
  const { messages, userByID, dispatch } = props;

  const topic = `chat/${props.room}`;
  useEffect(() => {
    getChannelListener().subscribeToTopic(topic);
    return () => {
      getChannelListener().unsubscribeToTopic(topic);
    };
  }, []);

  const formRef = useRef<{ focus: () => void }>(null);
  const [collapsed, setCollapsed] = useState(true);
  const [seenMessageCount, setSeenMessageCount] = useState(messages.length);
  useEffect(() => {
    if (!collapsed) {
      setSeenMessageCount(messages.length);
    }
  }, [collapsed, messages.length]);
  useEffect(() => {
    if (!collapsed) {
      formRef.current?.focus();
    }
  }, [collapsed]);

  return (
    <div
      css={ChatOverlayStyles.chatWindow}
      className={collapsed ? 'collapsed' : ''}
    >
      <ChatHeader
        title={props.title}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        unreadMessageCount={messages.length - seenMessageCount}
      />
      {!collapsed && (
        <>
          <ChatBody
            userByID={userByID}
            messages={messages}
            dispatch={dispatch}
          />
          <ChatForm ref={formRef} room={props.room} />
        </>
      )}
    </div>
  );
}

const ChatForm = forwardRef(
  (
    props: {
      room: string;
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      },
    }));

    return (
      <form
        css={ChatOverlayStyles.form}
        onSubmit={(e) => {
          e.preventDefault();

          if (!inputRef.current) {
            return;
          }
          let message = inputRef.current.value;
          inputRef.current.value = '';

          message = message.trim();
          if (!message) {
            return;
          }

          getChannelListener().sendChatMessage(props.room, message);
        }}
      >
        <input css={ChatOverlayStyles.input} type="text" ref={inputRef} />
        <button css={ChatOverlayStyles.sendButton} type="submit">
          <img css={ChatOverlayStyles.sendButtonImage} src={SendButtonImg} />
        </button>
      </form>
    );
  },
);

function ChatHeader(props: {
  title: string;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  unreadMessageCount: number;
}) {
  return (
    <div
      css={ChatOverlayStyles.header}
      onClick={() => {
        props.setCollapsed(!props.collapsed);
      }}
    >
      {props.title}
      {props.unreadMessageCount > 0 && ` (${props.unreadMessageCount})`}
      {!props.collapsed && <span>X</span>}
    </div>
  );
}

function ChatBody(props: {
  userByID: Map<string, User>;
  messages: ChatMessage[];
  dispatch: React.Dispatch<any>;
}) {
  const { userByID, messages, dispatch } = props;
  return (
    <div
      ref={(node) => {
        if (node) {
          node.scrollTop = node.scrollHeight;
        }
      }}
      css={ChatOverlayStyles.body}
    >
      {messages.map((message, i) => {
        let user = userByID.get(message.senderID);
        if (!user) {
          dispatch(fetchUsers([message.senderID]));
        }
        return (
          <div key={i}>
            {user ? user.name : 'unknown'}: {message.message}
          </div>
        );
      })}
    </div>
  );
}

const ChatOverlayStyles = {
  overlay: css({
    label: 'ChatOverlay',
    position: 'fixed',
    bottom: 0,
    right: 0,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  }),
  chatWindow: css({
    label: 'ChatWindow',
    width: 200,
    // height: 300,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',

    backgroundColor: 'white',
    border: '1px solid black',
    borderRadius: 2,
    padding: 2,

    '&.collapsed': {
      opacity: 0.5,
    },
  }),

  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    // backgroundColor: 'rgb(200, 200, 200)',
    borderBottom: '1px solid black',
    // borderRadius: 2,
    paddingLeft: 4,
    paddingBottom: 2,
  }),

  body: css({
    height: 250,
    overflowY: 'auto',
  }),

  form: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
  }),

  input: css({
    flexGrow: 1,
    flexShrink: 1,
    marginRight: 2,
  }),
  sendButton: css({
    height: 20,
    padding: 0,
    backgroundColor: 'none',
    border: 'none',
  }),
  sendButtonImage: css({
    height: 16,
    width: 'auto',
  }),
};

/** @jsx jsx */
/** @jsxFrag */
import { jsx, css, SerializedStyles } from '@emotion/react';
import invariant from 'invariant';
import { times } from 'underscore';
import {
  AbilityOutput,
  AbilityOutputMultiplierType,
} from '../game/CardAbilities';
import {
  isCardSubType,
  getCardSubTypeChar,
  getCardTypeChar,
  CardType,
} from '../game/CardTypes';
import { Resource, Token } from '../game/Resources';
import Symbols, { SymbolView, ResourceToSymbol } from './Symbols';
import { match } from 'ts-pattern';
import { CardTypeToFrameColor } from './CardTypeToFrameColor';

export function EffectPill(props: {
  output: AbilityOutput;
  className?: string;
  style?: React.CSSProperties;
  hideChrome?: boolean;
}): JSX.Element | null {
  const output = props.output;

  let prefix: React.ReactNode;
  let extraChromeCSS: SerializedStyles | null = null;
  if (output.multiplier) {
    extraChromeCSS =
      PillChromeByMultiplierType[output.multiplier.resource] ||
      DefaultPillChrome;

    let midfix: React.ReactNode;
    const count = output.multiplier.resourceDivisor;
    const multiplierSymbolChar = match(output.multiplier.resource)
      .with('card_set', () => {
        invariant(count === 1, 'no asset for N card sets');
        return 'S';
      })
      .with(Token.War, () => {
        if (count !== 1) {
          midfix = <span css={PillStyles.resourceDivisor}>/{count}</span>;
        }
        return 'W';
      })
      .otherwise((cardType) => {
        let symbolChar = isCardSubType(cardType)
          ? getCardSubTypeChar(cardType)
          : getCardTypeChar(cardType);

        if (count > 1) {
          invariant(count === 2, 'no asset for N card subtypes');
          symbolChar += symbolChar;
        }
        return symbolChar;
      });
    const multiplierSymbol = MultiplierCharToSymbolImage[multiplierSymbolChar];
    if (!multiplierSymbol) {
      console.warn('no symbol for', multiplierSymbolChar);
      return null;
    }
    prefix = (
      <div css={PillStyles.prefixContainer}>
        <SymbolView
          symbol={multiplierSymbol}
          css={PillStyles.multiplierSymbol}
        />
        {midfix}
        <span css={PillStyles.x}>×</span>
      </div>
    );
  }
  // disable extra chrome for now
  // extraChromeCSS = DefaultPillChrome;
  extraChromeCSS = null;

  return (
    <div
      css={[
        PillStyles.container,
        props.hideChrome
          ? undefined
          : [
              PillChromeByResource[output.outputResource],
              PillStyles.pillChrome,
              extraChromeCSS,
            ],
      ]}
      className={props.className}
      style={{
        ...props.style,
      }}
    >
      {prefix}
      <div css={PillStyles.symbolWithNumberContainer}>
        <SymbolView
          symbol={ResourceToSymbol[output.outputResource]}
          css={PillStyles.symbol}
        />

        <span css={PillStyles.symbolNumber}>{output.outputRatio}</span>
      </div>
    </div>
  );
}

export function AllResourceEffectPill(props: {
  amount: string;
}): JSX.Element | null {
  return (
    <div
      css={PillStyles.container}
      style={{
        background:
          'linear-gradient(90deg, rgba(230,230,230,1) 0%, rgba(230,230,230,1) 13.37%, rgba(207,167,33,1) 42.0%, rgba(224,79,16,1) 69%, rgba(150,65,255,1) 100%)',
      }}
    >
      <span css={PillStyles.text}>{props.amount}</span>
      <SymbolView symbol={Symbols.GOLD} css={PillStyles.symbol} />
      <SymbolView symbol={Symbols.MILITARY} css={PillStyles.symbol} />
      <SymbolView symbol={Symbols.FAVOR} css={PillStyles.symbol} />
    </div>
  );
}

const PillStyles = {
  container: css({
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',

    position: 'relative',

    borderRadius: '12px',
  }),
  pillChrome: css({
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      borderRadius: '12px',
    },
  }),
  prefixContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 3,
  }),
  text: css({
    marginLeft: 2.5,
    marginRight: 0.5,
    marginBottom: -1,

    fontSize: 10,
    fontWeight: 300,
  }),
  symbol: css({
    height: 12,
    width: 'auto',
    margin: 0,
  }),
  multiplierSymbol: css({
    height: 8,
    width: 'auto',
  }),
  resourceDivisor: css({
    fontSize: 4,
    fontWeight: 'bold',
    marginTop: 3,
    marginLeft: -1,
  }),
  x: css({
    fontSize: 6,
    marginTop: 1,
  }),
  outputRatio: css({
    fontSize: 8,
    marginTop: 1,
  }),
  symbolWithNumberContainer: css({
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  }),
  symbolNumber: css({
    fontSize: 9,
    fontWeight: 600,
    position: 'absolute',
    right: '50%',
    top: '50%',
    transform: 'translate(50%, -50%)',
    color: 'white',
    textShadow: '0px 0px 2px rgba(0, 0, 0, 1)',
  }),
} as const;
const PillChromeByResource: Record<Resource, SerializedStyles> = {
  gold: css({
    backgroundColor: 'rgb(207, 167, 33)',
  }),
  military: css({
    backgroundColor: 'rgb(224, 79, 16)',

    '&::before': {
      border: '1px solid rgb(117, 35, 3)',
    },
  }),
  favor: css({
    backgroundColor: 'rgb(150, 65, 255)',
  }),
  warTokens: css({
    backgroundColor: 'pink',
  }),
};
const PillChromeByMultiplierType: Partial<
  Record<AbilityOutputMultiplierType, SerializedStyles>
> = {
  [CardType.Resource]: css({
    backgroundColor: CardTypeToFrameColor[CardType.Resource],
  }),
  [CardType.Conflict]: css({
    backgroundColor: CardTypeToFrameColor[CardType.Conflict],
  }),
  [CardType.Prayer]: css({
    backgroundColor: CardTypeToFrameColor[CardType.Prayer],
  }),
};
const DefaultPillChrome = css({
  backgroundColor: 'rgb(180, 180, 180)',
});

const MultiplierCharToSymbolImage: Record<string, Symbols> = {
  R: Symbols.CARD_TYPE_RESOURCE,
  RR: Symbols.CARD_TYPE_RESOURCE_DOUBLE,
  C: Symbols.CARD_TYPE_CONFLICT,
  CC: Symbols.CARD_TYPE_CONFLICT_DOUBLE,
  P: Symbols.CARD_TYPE_PRAYER,
  PP: Symbols.CARD_TYPE_PRAYER_DOUBLE,

  D: Symbols.CARD_SUBTYPE_BUILDING,
  DD: Symbols.CARD_SUBTYPE_BUILDING_DOUBLE,
  U: Symbols.CARD_SUBTYPE_UNIT,
  UU: Symbols.CARD_SUBTYPE_UNIT_DOUBLE,
  E: Symbols.CARD_SUBTYPE_EVENT,
  EE: Symbols.CARD_SUBTYPE_EVENT_DOUBLE,

  W: Symbols.TOKEN_WAR,
};

/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react';
import React, { useCallback } from 'react';

export interface BaseCardViewProps {
  onMouseEnter?: React.EventHandler<React.MouseEvent>;
  onMouseLeave?: React.EventHandler<React.MouseEvent>;
  onLeftClick?: React.EventHandler<React.MouseEvent>;
  onRightClick?: React.EventHandler<React.MouseEvent>;
  onTouchEnd?: React.EventHandler<React.TouchEvent>;

  bodyStyle?: React.CSSProperties;
  cardStyle?: React.CSSProperties;

  overlayStyle?: React.CSSProperties;
  overlayCSS?: SerializedStyles;
  overlayChildren?: React.ReactNode;

  titleView: React.ReactNode;

  className?: string;
  landscape?: boolean;
}
const BaseCardView = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<BaseCardViewProps>
>((props, ref) => {
  const {
    bodyStyle,
    cardStyle,
    overlayStyle,
    overlayCSS,
    overlayChildren,
    titleView,
    className,
    onLeftClick,
    onRightClick,
  } = props;

  const onTouchEnd = useCallback(
    (event) => {
      if (event.changedTouches.length === 2) {
        onRightClick && onRightClick(event);
      } else {
        onLeftClick && onLeftClick(event);
      }
    },
    [onLeftClick, onRightClick],
  );

  return (
    <div
      className={'base-card ' + (className || '')}
      css={[
        CardStyle.container,
        props.landscape && CardStyle.container_landscape,
      ]}
      ref={ref}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onLeftClick}
      onTouchEnd={onTouchEnd}
      onContextMenu={props.onRightClick}
    >
      <div css={CardStyle.card} style={cardStyle}>
        <div css={CardStyle.body} style={bodyStyle}>
          {props.children}
        </div>
        {titleView}
      </div>
      {(overlayChildren || overlayStyle || overlayCSS) && (
        <div
          css={overlayCSS ? [CardStyle.overlay, overlayCSS] : CardStyle.overlay}
          style={overlayStyle}
        >
          {overlayChildren}
        </div>
      )}
    </div>
  );
});
export default BaseCardView;

export const BaseCardBoxShadow = 'inset 0px -15px 30px -15px #000000';
export const CARD_BORDER_RADIUS = 5;
const CardStyle = {
  container: css({
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',

    // 2.48mm x 3.48mm standard poker size
    width: 100,
    height: '139.51612903225px', //'1.453293in'

    color: 'black',

    borderRadius: CARD_BORDER_RADIUS,
  }),
  container_landscape: css({
    width: '139.51612903225px', //'1.453293in',
    height: 100,
  }),
  card: css({
    display: 'flex',
    flexDirection: 'column-reverse',

    border: 'solid black 4.5px',
    borderRadius: CARD_BORDER_RADIUS,

    flexGrow: 1,

    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',

    backgroundSize: '100% 100%',

    boxShadow: BaseCardBoxShadow,
  }),
  body: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',

    flexGrow: 1,
    margin: 3,
    marginTop: 0,

    backgroundSize: '100% 100%',
    boxShadow: 'inset 0px 0px 4px 0px rgba(0, 0, 0, 0.5)',
  }),
  overlay: css({
    position: 'absolute',

    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    borderRadius: CARD_BORDER_RADIUS,
  }),
} as const;

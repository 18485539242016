export const Phases = {
  SETUP: 'SETUP',
  PRODUCTION: 'PRODUCTION',
  PLANNING: 'PLANNING',
  WAR: 'WAR',
  RESOLUTION: 'RESOLUTION',
  TRIBUTE: 'TRIBUTE',
} as const;

export type Phase = keyof typeof Phases;

export function getPhaseName(phase: Phase) {
  return PhaseToName[phase];
}

export const PhaseOrder = [
  Phases.SETUP,
  Phases.PRODUCTION,
  Phases.PLANNING,
  Phases.WAR,
  Phases.RESOLUTION,
  Phases.TRIBUTE,
] as const;

export function getPhaseOrder() {
  return PhaseOrder;
}
export function nextPhase(phase: Phase) {
  var index = PhaseOrder.indexOf(phase);
  var new_index = (index + 1) % PhaseOrder.length;
  return PhaseOrder[new_index];
}

export default Phases;

export const PhaseToName: Record<Phase, string> = {
  SETUP: 'Setup',
  PRODUCTION: 'Production',
  PLANNING: 'Planning',
  WAR: 'War',
  RESOLUTION: 'Resolution',
  TRIBUTE: 'Tribute',
};

export const PhaseToBackgroundColor: Record<Phase, string> = {
  SETUP: 'rgb(50, 50, 50)',
  PRODUCTION: 'rgb(50, 110, 50)',
  PLANNING: 'rgb(50, 80, 120)',
  WAR: 'rgb(220, 80, 20)',
  RESOLUTION: 'rgb(70, 50, 120)',
  TRIBUTE: 'rgb(70, 50, 120)',
};

export type Constructor<T, Args extends any[] = any[]> = new (
  ...args: Args
) => T;

export type Filter<T, U> = T extends U ? T : never;

// type for recursively representing a T or T[]
export type RecursiveItemOrArray<T> = T | RecursiveItemOrArray<T>[];
export function* recursiveArrayIterator<T>(
  item: RecursiveItemOrArray<T>,
): Generator<T> {
  if (Array.isArray(item)) {
    for (const subItem of item) {
      yield* recursiveArrayIterator(subItem);
    }
  } else {
    yield item;
  }
}

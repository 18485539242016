/** @jsx jsx */
/** @jsxFrag */
import { jsx, css, SerializedStyles } from '@emotion/react';

import CardBackAge1 from '../assets/card_images/CardBackAge1.jpg';
import CardBackAge2 from '../assets/card_images/CardBackAge2.jpg';
import CardBackAge3 from '../assets/card_images/CardBackAge3.jpg';
import CardBackTribute from '../assets/card_images/CardBackTribute.jpg';
import BaseCardView from './BaseCardView';
import React from 'react';

export type CardBack = {
  type: CardBackType;
  id: string;
};
export type CardBackType = 'age1' | 'age2' | 'age3' | 'tribute'; // | 'basic';
export type CardBackEventFunc = (card: CardBack, event: MouseEvent) => void;

const TypeToCardBackImage: Record<CardBackType, string> = {
  age1: CardBackAge1,
  age2: CardBackAge2,
  age3: CardBackAge3,
  tribute: CardBackTribute,
};
type CardBackProps = {
  card: CardBack;
  onCardEnter?: CardBackEventFunc;
  onCardLeave?: CardBackEventFunc;
  overlayStyle?: React.CSSProperties;
};
export const CardBackView = React.forwardRef<HTMLDivElement, CardBackProps>(
  (props, ref) => {
    return (
      <BaseCardView
        ref={ref}
        landscape={props.card.type === 'tribute'}
        onMouseEnter={(e: any) => props.onCardEnter?.(props.card, e)}
        onMouseLeave={(e: any) => props.onCardLeave?.(props.card, e)}
        cardStyle={{
          backgroundImage: `url(${TypeToCardBackImage[props.card.type]})`,
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
          backgroundSize: '100% 100%',
          // border: 'solid black 6px',
          border: 'none',
          boxShadow: 'none',
        }}
        bodyStyle={{ boxShadow: 'none' }}
        overlayStyle={props.overlayStyle}
        titleView={null}
      ></BaseCardView>
    );
  },
);

function calculateQuadraticBezierSplinePoint(
  t: number,
  points: number[][],
): number[] {
  if (points.length === 1) {
    return points[0];
  }

  const newPoints: number[][] = [];
  for (let i = 0; i < points.length - 1; i++) {
    const x = (1 - t) * points[i][0] + t * points[i + 1][0];
    const y = (1 - t) * points[i][1] + t * points[i + 1][1];
    newPoints.push([x, y]);
  }

  return calculateQuadraticBezierSplinePoint(t, newPoints);
}

export function generateQuadraticBezierSpline(
  controlPoints: number[][],
  numPoints: number,
): number[][] {
  const spline: number[][] = [];

  for (let i = 0; i <= numPoints; i++) {
    const t = i / numPoints;
    const point = calculateQuadraticBezierSplinePoint(t, controlPoints);
    spline.push(point);
  }

  return spline;
}

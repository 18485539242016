import React, { ReactNode } from 'react';
import {
  AddAnimationParams,
  AnimationPosition,
  ElementPosition,
  NodeTarget,
  AnimationPoint,
  AnimationElement,
  ElementTarget,
  CalculateAnimationPosition,
  AnimationCurveType,
} from './AnimationSystem';
import Symbols, { SymbolView } from './Symbols';
import { times } from 'underscore';
import nullthrows from 'nullthrows';
import {
  CardWithID,
  computeBaseCounters,
  makeContext,
  makeContextFromGame,
} from '../game/Rules';
import { PlayerAnimationRefs } from './AnimationHooks';
import { CounterDelta } from '../game/Utility';
import { CardType } from '../game/CardTypes';
import CardView from './CardView';
import { RecursiveItemOrArray } from '../utils/ts_utils';
import { Resource } from '../game/Resources';
import { PlayerFavorDisplay, PlayerResourceCounter } from './PlayerSummaryView';
import { InflatedGame, InflatedPlayer } from '../game/Game';
import { totalProductionCounters } from '../game/AIUtilities';
import invariant from 'invariant';
import { nonNull } from '../utils/utils';

const TOKEN_ANIM_DURATION = 0.8;
const TOKEN_ANIM_DELAY = 0.03;
type TokenExplosionStyle = 'explosion' | 'train' | 'l_explosion';
export function tokenExplosionAnimations(params: {
  symbol: Symbols;
  count: number;
  symbolAnchorPoint?: AnimationPoint;

  startElement: AnimationPosition;
  endElement: AnimationPosition;

  startScale?: number;
  endScale?: number;

  delayUntilFinished?: RecursiveItemOrArray<AddAnimationParams>;
  delaySeconds?: number;

  // default explosion
  style?: TokenExplosionStyle;

  incrementor?: (i: number) => void;
}): AddAnimationParams[] {
  const style = params.style || 'explosion';
  const startPoint = nullthrows(
    CalculateAnimationPosition(params.startElement),
  );
  const endPoint = CalculateAnimationPosition(params.endElement) || startPoint;

  return times(params.count, (i) => {
    let midpoint: AnimationPoint | null = null;
    let durationSeconds = TOKEN_ANIM_DURATION;
    let curveType: AnimationCurveType = 'linear';
    if (style === 'explosion') {
      let length = 200;
      length *= 0.4 + 1.2 * Math.random();
      midpoint = [Math.random() - 0.5, Math.random() - 0.5];
      let randVec2Length = Math.sqrt(
        midpoint[0] * midpoint[0] + midpoint[1] * midpoint[1],
      );
      midpoint[0] *= length / randVec2Length;
      midpoint[1] *= length / randVec2Length;

      midpoint[0] += startPoint[0];
      midpoint[1] += startPoint[1];
      curveType = 'quadratic-bezier';
    } else if (style === 'l_explosion') {
      let length = 50;
      length *= 0.4 + 1.2 * Math.random();
      midpoint = [Math.random() - 0.5, Math.random() - 0.5];
      let randVec2Length = Math.sqrt(
        midpoint[0] * midpoint[0] + midpoint[1] * midpoint[1],
      );
      midpoint[0] *= length / randVec2Length;
      midpoint[1] *= length / randVec2Length;

      midpoint[0] += startPoint[0];
      midpoint[1] += endPoint[1];
      curveType = 'quadratic-bezier';
    } else if (style === 'train') {
      durationSeconds = TOKEN_ANIM_DURATION / 2;
    } else {
      invariant(false, 'unknown style %s', style);
    }

    let controlPoints: AnimationPoint[] = [
      startPoint,
      midpoint,
      endPoint,
    ].filter(nonNull);

    const tokenAnimation: AddAnimationParams = {
      startElement: params.startElement,
      endElement: params.endElement,
      target: NodeTarget(
        <SymbolView
          symbol={params.symbol}
          style={{
            height: 25,
            width: 'auto',
          }}
        />,
        {
          anchorPoint: params.symbolAnchorPoint,
        },
      ),
      delayUntilFinished: params.delayUntilFinished,
      durationSeconds,
      delaySeconds: TOKEN_ANIM_DELAY * i,
      finishPauseSeconds: 0.1,
      curve: {
        type: curveType,
        controlPoints: controlPoints,
      },

      startScale: params.startScale || 1,
      endScale: params.endScale || 1,

      onFinished: () => params.incrementor?.(1),
    };
    return tokenAnimation;
  });
}

export function HideElementAnimation(params: {
  element: AnimationElement;

  delayUntilFinished?: RecursiveItemOrArray<AddAnimationParams>;
  delaySeconds?: number;
}): AddAnimationParams {
  return {
    target: ElementTarget(params.element),
    startElement: ElementPosition(params.element),
    startOpacity: 0,
    endOpacity: 0,
    durationSeconds: 0,
    delayUntilFinished: params.delayUntilFinished,
    delaySeconds: params.delaySeconds,
    finishPauseSeconds: 1000,
    nonBlocking: true,
  };
}

export function DelayAnimation(params: {
  delaySeconds: number;
  delayUntilFinished?: RecursiveItemOrArray<AddAnimationParams>;
}): AddAnimationParams {
  return {
    target: null,

    durationSeconds: 0,
    delaySeconds: params.delaySeconds,
    delayUntilFinished: params.delayUntilFinished,
    nonBlocking: true,
  };
}

export function DiscardCardAnimation(params: {
  node: ReactNode;
  cardElement: AnimationElement;

  delayUntilFinished?: RecursiveItemOrArray<AddAnimationParams>;
  delaySeconds?: number;
}): AnimationBP {
  const animations: AddAnimationParams[] = [];
  const { cardElement } = params;
  animations.push(
    HideElementAnimation({
      element: cardElement,
      delayUntilFinished: params.delayUntilFinished,
      delaySeconds: params.delaySeconds,
    }),
  );
  const disappearCardAnimation: AddAnimationParams = {
    target: NodeTarget(params.node),
    startElement: ElementPosition(cardElement),

    startOpacity: 1,
    endOpacity: 0,
    // endScale: 0,

    delayUntilFinished: params.delayUntilFinished,
    durationSeconds: 0.6,
  };
  animations.push(disappearCardAnimation);
  return {
    animations,
    blockingAnimation: disappearCardAnimation,
  };
}

const TOKEN_SUMMARY_SCALE = 15 / 25;
export function CountersExplosionAnimation(params: {
  counters: Partial<CounterDelta>;

  playerAnimationRefs: Map<PlayerAnimationRefs, HTMLDivElement>;
  playerResourceIncrementors: PlayerResourceIncrementors;

  style?: TokenExplosionStyle;

  delayUntilFinished?: RecursiveItemOrArray<AddAnimationParams>;
}): AnimationBP | null {
  const { gold, military, favor } = params.counters;
  const { playerAnimationRefs, playerResourceIncrementors, style } = params;
  let blockingAnimation = params.delayUntilFinished;
  let delay = 0;
  const animations: AddAnimationParams[] = [];
  if (favor) {
    animations.push(
      ...tokenExplosionAnimations({
        symbol: Symbols.FAVOR,
        count: favor,

        startElement: ElementPosition(
          playerAnimationRefs.get(PlayerAnimationRefs.board)!,
        ),
        endElement: ElementPosition(
          playerAnimationRefs.get(PlayerAnimationRefs.favor)!,
        ),
        delayUntilFinished: blockingAnimation,
        delaySeconds: delay,

        style,

        incrementor: playerResourceIncrementors.get('favor'),
      }),
    );
    delay += 0.1;
  }

  if (gold) {
    animations.push(
      ...tokenExplosionAnimations({
        symbol: Symbols.GOLD,
        count: gold,

        startElement: ElementPosition(
          playerAnimationRefs.get(PlayerAnimationRefs.board)!,
        ),
        endElement: ElementPosition(
          playerAnimationRefs.get(PlayerAnimationRefs.gold)!,
        ),
        delayUntilFinished: blockingAnimation,
        delaySeconds: delay,
        endScale: TOKEN_SUMMARY_SCALE,

        style,

        incrementor: playerResourceIncrementors.get('gold'),
      }),
    );
    delay += 0.1;
  }

  if (military) {
    animations.push(
      ...tokenExplosionAnimations({
        symbol: Symbols.MILITARY,
        count: military,

        startElement: ElementPosition(
          playerAnimationRefs.get(PlayerAnimationRefs.board)!,
        ),
        endElement: ElementPosition(
          playerAnimationRefs.get(PlayerAnimationRefs.military)!,
        ),
        delayUntilFinished: blockingAnimation,
        delaySeconds: delay,
        endScale: TOKEN_SUMMARY_SCALE,

        style,

        incrementor: playerResourceIncrementors.get('military'),
      }),
    );
    delay += 0.1;
  }

  blockingAnimation = animations[animations.length - 1] || blockingAnimation;

  if (animations.length === 0) {
    return null;
  }
  return {
    animations,
    blockingAnimation,
  };
}

const CARD_ANIM_DURATION = 0.4;

export type AnimationBP = {
  animations: AddAnimationParams[];
  blockingAnimation: AddAnimationParams;
};

export function GainCardAnimation(params: {
  card: CardWithID;
  reward: CounterDelta;
  goldCost: number;

  cardIDToAnimationRef: Map<string, HTMLDivElement>;
  playerAnimationRefs: Map<PlayerAnimationRefs, HTMLDivElement>;
  playerResourceIncrementors: PlayerResourceIncrementors;

  delayUntilFinished?: RecursiveItemOrArray<AddAnimationParams>;
}): AnimationBP | null {
  const { card, reward, goldCost } = params;
  const {
    cardIDToAnimationRef,
    playerAnimationRefs,
    playerResourceIncrementors,
  } = params;
  let startCard = cardIDToAnimationRef.get(card.id);
  if (!startCard) {
    console.warn('No start card for', card);
    console.log(cardIDToAnimationRef);
    return null;
  }

  const animations: AddAnimationParams[] = [];
  let blockingAnimation = params.delayUntilFinished;

  animations.push(
    ...tokenExplosionAnimations({
      symbol: Symbols.GOLD,
      count: goldCost,
      style: 'train',

      startElement: ElementPosition(
        playerAnimationRefs.get(PlayerAnimationRefs.gold)!,
      ),
      endElement: ElementPosition(startCard, {}),
      startScale: TOKEN_SUMMARY_SCALE,

      delayUntilFinished: blockingAnimation,

      incrementor: (count) => playerResourceIncrementors.get('gold')?.(-count),
    }),
  );
  blockingAnimation = animations[animations.length - 1] || blockingAnimation;

  if (card.type !== CardType.Basic) {
    animations.push(
      HideElementAnimation({
        element: startCard,
        delayUntilFinished: blockingAnimation,
      }),
    );
  }
  const animation: AddAnimationParams = {
    startElement: ElementPosition(startCard),
    endElement: ElementPosition(
      playerAnimationRefs.get(PlayerAnimationRefs.board)!,
    ),
    target: NodeTarget(<CardView card={card} />),
    delayUntilFinished: blockingAnimation,
    durationSeconds: CARD_ANIM_DURATION,
    finishPauseSeconds: 100,
    nonBlocking: true,
  };
  animations.push(animation);
  blockingAnimation = animation;

  const ret = CountersExplosionAnimation({
    counters: reward,
    playerAnimationRefs,
    playerResourceIncrementors,
    style: 'explosion',
    delayUntilFinished: blockingAnimation,
  });
  if (ret) {
    animations.push(...ret.animations);
    blockingAnimation = ret.blockingAnimation;
  }

  return {
    animations,
    blockingAnimation,
  };
}

export function CardRowAnimations<TCard extends { id: string }>(params: {
  alreadyRemovedCardIDs: Set<string>;
  cardIDsToRemove: Set<string>;
  cardIDsToAdd: string[];
  cardsByID: Record<string, TCard>;

  row: TCard[];
  rowSize: number;
  cardRenderer: (card: TCard) => ReactNode;

  cardIDToAnimationRef: Map<string, HTMLDivElement>;
  rowIndexToAnimationRef: Map<number, HTMLDivElement>;

  delayUntilFinished?: AddAnimationParams;
  delaySeconds?: number;
}): AnimationBP | null {
  const {
    alreadyRemovedCardIDs,
    cardIDsToRemove,
    cardIDsToAdd,
    cardsByID,
    row,
    rowSize,
    cardRenderer,
    cardIDToAnimationRef,
    rowIndexToAnimationRef,

    delayUntilFinished,
    delaySeconds,
  } = params;

  const animations: AddAnimationParams[] = [];
  let targetIndex = row.length - 1;
  for (let i = row.length - 1; i >= 0; i--) {
    const card = row[i];
    const cardElement = cardIDToAnimationRef.get(card.id)!;
    if (alreadyRemovedCardIDs.has(card.id)) {
      continue;
    }
    if (cardIDsToRemove.has(card.id)) {
      // console.log('discard card', card.id);
      const bp = DiscardCardAnimation({
        node: cardRenderer(card),
        cardElement,
        delayUntilFinished,
        delaySeconds,
      });
      animations.push(...bp.animations);
      continue;
    }
    if (i === targetIndex) {
      targetIndex--;
      continue;
    }
    // console.log('slide card', card.id, i, targetIndex);
    animations.push(
      HideElementAnimation({
        element: cardElement,
        delayUntilFinished,
      }),
    );
    const endCard = cardIDToAnimationRef.get(row[targetIndex].id)!;
    const slideCardAnimation: AddAnimationParams = {
      startElement: ElementPosition(cardElement),
      endElement: ElementPosition(endCard),
      target: NodeTarget(cardRenderer(card)),
      delayUntilFinished,
      delaySeconds,
      durationSeconds: CARD_ANIM_DURATION,
      finishPauseSeconds: 100,
      nonBlocking: true,
    };
    animations.push(slideCardAnimation);
    targetIndex--;
  }

  targetIndex += rowSize - row.length + 1;

  const tributeDealAnims = cardIDsToAdd.map((cardID) => {
    const card = cardsByID[cardID];
    // TODO pass ths in
    const startCard = rowIndexToAnimationRef.get(0);
    const endCard = rowIndexToAnimationRef.get(targetIndex);
    targetIndex -= 1;

    // console.log('deal card', card.id, targetIndex);

    return {
      target: NodeTarget(cardRenderer(card)),

      startElement: ElementPosition(startCard!),
      endElement: ElementPosition(endCard!),

      delayUntilFinished,
      delaySeconds,
      durationSeconds: 0.6,
      finishPauseSeconds: 100,
      nonBlocking: true,
    };
  });
  animations.push(...tributeDealAnims);

  if (animations.length === 0) {
    return null;
  }

  const delayAnimation = DelayAnimation({
    delaySeconds: 0.5,
    delayUntilFinished: [...animations],
  });
  animations.push(delayAnimation);

  return {
    animations,
    blockingAnimation: delayAnimation,
  };
}

export type PlayerResourceIncrementors = Map<Resource, (count: number) => void>;
const INCREMENTOR_DELAY_MS = 50;
export function MakePlayerIncrementors(params: {
  player: InflatedPlayer;
  playerAnimationRefs: Map<PlayerAnimationRefs, HTMLDivElement>;
  game: InflatedGame;
}): [PlayerResourceIncrementors, AddAnimationParams[]] {
  const { player, playerAnimationRefs, game } = params;

  const animations: AddAnimationParams[] = [];
  const resourceIncrementors: PlayerResourceIncrementors = new Map();

  const context = makeContextFromGame(player, game);
  const productionCounts = totalProductionCounters(context);
  const baseCounts = computeBaseCounters(context);

  let favorText: HTMLSpanElement | null = null;
  let displayedFavorValue: number = player.counters.favor;
  const favorView = playerAnimationRefs.get(PlayerAnimationRefs.favor)!;
  const favorOverlay: AddAnimationParams = {
    startElement: ElementPosition(favorView),
    target: NodeTarget(
      <PlayerFavorDisplay
        ref={(node) => {
          favorText = (node?.children[1] as HTMLSpanElement) || null;
        }}
        count={player.counters.favor}
      />,
    ),
    durationSeconds: 0,
    finishPauseSeconds: 100,
    nonBlocking: true,
  };
  animations.push(favorOverlay);
  animations.push(HideElementAnimation({ element: favorView }));

  let targetFavorDelta: number = 0;
  let lastFavorIncrementTime: number = 0;
  const updateFavorValue = () => {
    const now = Date.now();
    if (now - lastFavorIncrementTime < INCREMENTOR_DELAY_MS) {
      requestAnimationFrame(updateFavorValue);
      return;
    }
    lastFavorIncrementTime = now;

    if (!favorText || targetFavorDelta === 0 || displayedFavorValue === null) {
      return;
    }

    const step = Math.sign(targetFavorDelta);
    targetFavorDelta -= step;
    displayedFavorValue += step;
    favorText.innerText = displayedFavorValue.toString();
    if (targetFavorDelta !== 0) {
      requestAnimationFrame(updateFavorValue);
    }
  };
  const favorIncrementor = (count: number) => {
    if (count === 0) {
      return;
    }
    if (targetFavorDelta === 0) {
      requestAnimationFrame(updateFavorValue);
    }
    targetFavorDelta += count;
  };
  resourceIncrementors.set('favor', favorIncrementor);

  let goldText: HTMLSpanElement | null = null;
  const goldView = playerAnimationRefs.get(PlayerAnimationRefs.gold)!;
  const goldOverlay: AddAnimationParams = {
    startElement: ElementPosition(goldView),
    target: NodeTarget(
      <PlayerResourceCounter
        resource="gold"
        tooltip="Gold"
        count={player.counters.gold}
        generateCount={productionCounts.gold}
        baseCount={baseCounts.gold}
        countMode="counters"
        toggleCountMode={() => {}}
        ref={(node) => {
          goldText = (node?.children[1] as HTMLSpanElement) || null;
        }}
      />,
    ),
    durationSeconds: 0,
    finishPauseSeconds: 100,
    nonBlocking: true,
  };
  animations.push(goldOverlay);
  animations.push(HideElementAnimation({ element: goldView }));

  const goldIncrementor = (count: number = 1) => {
    if (goldText) {
      goldText.textContent = (parseInt(goldText.innerText) + count).toString();
    }
  };
  resourceIncrementors.set('gold', goldIncrementor);

  let militaryText: HTMLSpanElement | null = null;
  const militaryView = playerAnimationRefs.get(PlayerAnimationRefs.military)!;
  const militaryOverlay: AddAnimationParams = {
    startElement: ElementPosition(militaryView),
    target: NodeTarget(
      <PlayerResourceCounter
        resource="military"
        tooltip="Military"
        count={player.counters.military}
        generateCount={productionCounts.military}
        baseCount={baseCounts.military}
        countMode="counters"
        toggleCountMode={() => {}}
        ref={(node) => {
          militaryText = (node?.children[1] as HTMLSpanElement) || null;
        }}
      />,
    ),
    durationSeconds: 0,
    finishPauseSeconds: 100,
    nonBlocking: true,
  };
  animations.push(militaryOverlay);
  animations.push(HideElementAnimation({ element: militaryView }));

  const militaryIncrementor = (count: number = 1) => {
    if (militaryText) {
      militaryText.textContent = (
        parseInt(militaryText.innerText) + count
      ).toString();
    }
  };
  resourceIncrementors.set('military', militaryIncrementor);

  return [resourceIncrementors, animations];
}

import _ from 'underscore';

import Bot from '../game/Bot';
import { InflatedGame } from 'game/Game';
import * as Rules from '../game/Rules';
import { nonNull, randomSelect } from '../utils/utils';

export default class RandomCardBot extends Bot {
  override computeBid(game_state: InflatedGame, user_id: string): Rules.Bid {
    const player = this.getPlayer(game_state, user_id);
    const context = Rules.makeContextFromGame(player, game_state);
    let tradeRowSize = game_state.table.length;
    let botMilitary = player.counters.military;
    let affordableIndices = game_state.table
      .map((card, index) => {
        if (Rules.canAffordCard(context, card).canAfford) {
          return index;
        }
        return null;
      })
      .filter(nonNull);
    if (affordableIndices.length === 0) {
      affordableIndices = _.range(tradeRowSize);
    }
    return {
      military: botMilitary,
      tradeRowIndex: randomSelect(affordableIndices),
    };
  }
  override computeResolutionSelection(
    game_state: InflatedGame,
    user_id: string,
  ): Rules.ResolutionSelection {
    const player = this.getPlayer(game_state, user_id);
    const context = Rules.makeContextFromGame(player, game_state);
    let selectedCardID = player.selectedCard?.id;
    let affordableBasicCards = game_state.basicPile.filter(
      (card) => Rules.canAffordCard(context, card).canAfford,
    );
    let basicCardID = randomSelect(affordableBasicCards).id;
    let isAffordable =
      selectedCardID &&
      Rules.canAffordCard(context, game_state.cardsByID[selectedCardID])
        .canAfford;

    return {
      cardIDToGain:
        isAffordable && selectedCardID ? selectedCardID : basicCardID,
    };
  }
}

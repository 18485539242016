/** @jsx jsx */
/** @jsxFrag */
import { jsx, css } from '@emotion/react';

export default function CardBanner(props: {
  children?: React.ReactNode;
  className?: string;
  borderColor: string;
  backgroundColor?: string;
  bannerType:
    | 'left'
    | 'left-flag'
    | 'right'
    | 'right-bottom-shoulder'
    | 'left-bottom-shoulder'
    | 'right-top-shoulder'
    | 'left-top-shoulder';
}) {
  const direction = props.bannerType;
  const innerStyle = css({
    '&::before': {
      backgroundColor: props.backgroundColor || 'rgba(255, 255, 255, 0.5)',
    },
  });
  return (
    <div
      css={[
        CardBannerStyles.container,
        innerStyle,
        CardBannerStyles[direction],
      ]}
      className={props.className}
      style={{
        backgroundColor: props.borderColor,
      }}
    >
      {props.children}
    </div>
  );
}

const RIGHT_CLIP_PATH =
  'polygon(0% 50%, var(--notch-size) 0%, 100% 0%, 100% 100%, var(--notch-size) 100%);';
const LEFT_CLIP_PATH =
  'polygon(100% 50%, calc(100% - var(--notch-size)) 0%, 0% 0%, 0% 1000%, calc(100% - var(--notch-size)) 100%);';
const LEFT_FLAG_CLIP_PATH =
  'polygon(calc(100% - var(--notch-size)) 50%, 100% 0%, 0% 0%, 0% 1000%, 100% 100%);';
const RIGHT_BOTTOM_SHOULDER_CLIP_PATH =
  'polygon(100% 0%, 100% 100%, 0% 100%, 0% var(--notch-size), var(--notch-size) 0%);';
const LEFT_BOTTOM_SHOULDER_CLIP_PATH =
  'polygon(0% 0%, 0% 100%, 100% 100%, 100% var(--notch-size), calc(100% - var(--notch-size)) 0%);';
const RIGHT_TOP_SHOULDER_CLIP_PATH =
  'polygon(100% 100%, var(--notch-size) 100%, 0% calc(100% - var(--notch-size)), 0% var(--notch-size), var(--notch-size) 0%, 100% 0%);';
const LEFT_TOP_SHOULDER_CLIP_PATH =
  'polygon(0% 0%, calc(100% - var(--notch-size)) 0%, 100% var(--notch-size), 100% calc(100% - var(--notch-size)), calc(100% - var(--notch-size)) 100%, 0% 1000%);';

const CardBannerStyles = {
  container: css({
    backgroundColor: 'black',
    paddingLeft: 1,
    paddingRight: 1,
    paddingTop: 1,
    paddingBottom: 1,

    '--notch-size': '3px',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',

      '--notch-size': '2.5px',

      left: 1,
      top: 1,
      right: 1,
      bottom: 1,
      zIndex: -1,
    },

    marginTop: -1,
    '&:first-of-type': {
      marginTop: 0,
    },
  }),
  left: css({
    paddingRight: 2,
    clipPath: LEFT_CLIP_PATH,
    '&::before': {
      clipPath: LEFT_CLIP_PATH,
    },
  }),
  'left-flag': css({
    paddingRight: 4,
    clipPath: LEFT_FLAG_CLIP_PATH,
    '&::before': {
      '--notch-size': '3px',
      clipPath: LEFT_FLAG_CLIP_PATH,
    },
  }),
  right: css({
    paddingLeft: 2,
    clipPath: RIGHT_CLIP_PATH,
    '&::before': {
      clipPath: RIGHT_CLIP_PATH,
    },
  }),
  'right-bottom-shoulder': css({
    paddingLeft: 1,
    clipPath: RIGHT_BOTTOM_SHOULDER_CLIP_PATH,
    '&::before': {
      clipPath: RIGHT_BOTTOM_SHOULDER_CLIP_PATH,
    },
  }),
  'left-bottom-shoulder': css({
    paddingRight: 1,
    clipPath: LEFT_BOTTOM_SHOULDER_CLIP_PATH,
    '&::before': {
      clipPath: LEFT_BOTTOM_SHOULDER_CLIP_PATH,
    },
  }),
  'right-top-shoulder': css({
    paddingLeft: 1,
    clipPath: RIGHT_TOP_SHOULDER_CLIP_PATH,
    '&::before': {
      clipPath: RIGHT_TOP_SHOULDER_CLIP_PATH,
    },
  }),
  'left-top-shoulder': css({
    paddingRight: 1,
    clipPath: LEFT_TOP_SHOULDER_CLIP_PATH,
    '&::before': {
      clipPath: LEFT_TOP_SHOULDER_CLIP_PATH,
    },
  }),
};

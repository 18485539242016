import * as _ from 'underscore';
import { combineReducers } from 'redux';
import { List, Map } from 'immutable';
import Game, { InflatedGame } from '../game/Game';

type User = any;

import {
  SET_SESSION,
  STORE_LOBBY,
  STORE_LOBBY_LIST,
  STORE_USERS,
  STORE_GAME,
  STORE_GAME_DEFINITIONS,
  ADD_CHANNEL_SUBSCRIPTION,
  REMOVE_CHANNEL_SUBSCRIPTION,
  ADD_CHAT_MESSAGE,
  ChatMessage,
} from './actions';

function session(state = null, action) {
  switch (action.type) {
    case SET_SESSION:
      return action.session;
    default:
      return state;
  }
}

function lobbyByID(state = Map(), action) {
  switch (action.type) {
    case STORE_LOBBY:
      const { lobby } = action;
      return state.set(lobby.id, lobby);
    default:
      return state;
  }
}

function lobbyList(state = null, action) {
  switch (action.type) {
    case STORE_LOBBY_LIST:
      return action.lobby_list;
    default:
      return state;
  }
}

function gameDefinitions(state = null, action) {
  switch (action.type) {
    case STORE_GAME_DEFINITIONS:
      return action.game_definitions;
    default:
      return state;
  }
}

function userByID(state = Map<string, User>(), action) {
  switch (action.type) {
    case STORE_USERS:
      return state.withMutations((map) => {
        _.each(action.users, (user: User) => {
          map.set(user.id, user);
        });
      });
    default:
      return state;
  }
}

function gameByID(state = Map<string, InflatedGame>(), action) {
  switch (action.type) {
    case STORE_GAME: {
      let { game } = action;
      if (!game) {
        return state;
      }
      const existingGame = state.get(game.id);
      if (existingGame && existingGame.sequenceID >= game.sequenceID) {
        return state;
      }

      game = Game.inflateGame(game);

      return state.set(game.id, game);
    }
    default:
      return state;
  }
}

function channelSubscriptions(state = List(), action) {
  switch (action.type) {
    case ADD_CHANNEL_SUBSCRIPTION:
      return state.push(action.subscription);
    case REMOVE_CHANNEL_SUBSCRIPTION: {
      let index = state.indexOf(action.subscription);
      if (index !== -1) {
        return state.remove(index);
      }
      return state;
    }
    default:
      return state;
  }
}

function chatMessagesByRoom(state: Map<string, ChatMessage[]> = Map(), action) {
  if (action.type === ADD_CHAT_MESSAGE) {
    return state.set(
      action.message.room,
      state.get(action.message.room, []).concat(action.message),
    );
  }
  return state;
}

export default combineReducers({
  session,
  lobbyByID,
  lobbyList,
  userByID,
  gameByID,
  channelSubscriptions,
  gameDefinitions,
  chatMessagesByRoom,
});

'use strict';

import * as _ from 'underscore';

// returns \sum_{i = start}^{end} iterator(i)
function sum(start: number, end: number, iterator: (x: number) => number) {
  let ret = 0;
  for (let i = start; i <= end; i++) {
    ret += iterator(i);
  }
  return ret;
}

function array_sum(array: number[]) {
  let ret = 0;
  _.each(array, (x) => (ret += x));
  return ret;
}

function array_product(array: number[]) {
  let ret = 1;
  _.each(array, (x) => (ret *= x));
  return ret;
}
function array_select_indices<T>(array: T[], indices: number[]): T[] {
  return _.map(indices, (i) => array[i]);
}

// returns [ permuted_array ]
// example [1, 2] => [[1, 2], [1], [2], []]
function permutations(array: number[]): number[][] {
  if (array.length === 0) {
    return [[]];
  }

  let right_side = permutations(array.slice(1));
  let left_side = _.map(right_side, (arr) => array.slice(0, 1).concat(arr));
  return left_side.concat(right_side);
}

// returns [min, max]
function roll_range(dice_count: number) {
  return [dice_count, dice_count * 6];
}

// from http://math.stackexchange.com/questions/397689/why-convolution-regularize-functions/398146#398146
function p_rolling_value(num_dice: number, value: number): number {
  // base cases
  if (num_dice === 0) {
    return 0;
  }
  if (num_dice === 1) {
    return value >= 1 && value <= 6 ? 1 / 6 : 0;
  }

  return sum(1, 6, (j: number) => {
    return p_rolling_value(num_dice - 1, value - j) * p_rolling_value(1, j);
  });
}

function p_rolling_less_than_value(num_dice: number, value: number) {
  return sum(1, value - 1, (i: number) => p_rolling_value(num_dice, i));
}

function p_win_multi_roll(
  self_dice_count: number,
  self_bonus: number,
  opponent_dice_bonus_tuples: [number, number][],
) {
  let [start, end] = roll_range(self_dice_count);
  return sum(start, end, (i: number) => {
    let my_p = p_rolling_value(self_dice_count, i);
    let opponent_ps = _.map(
      opponent_dice_bonus_tuples,
      ([opponent_dice_count, opponent_bonus]) => {
        return p_rolling_less_than_value(
          opponent_dice_count,
          i + self_bonus - opponent_bonus,
        );
      },
    );
    return my_p * array_product(opponent_ps);
  });
}

// returns [ (p, tied_indices ]
function p_all_ties_multi_roll(
  self_dice_count: number,
  self_bonus: number,
  opponent_dice_bonus_tuples: [number, number][],
): [number, number[]][] {
  let [start, end] = roll_range(self_dice_count);
  let index_permutations = permutations(
    _.range(opponent_dice_bonus_tuples.length),
  );
  index_permutations = _.filter(index_permutations, (arr) => arr.length > 0);
  return _.map(index_permutations, (tied_indices) => {
    let p = sum(start, end, (i: number) => {
      let my_p = p_rolling_value(self_dice_count, i);
      let opponent_ps = _.map(
        opponent_dice_bonus_tuples,
        ([opponent_dice_count, opponent_bonus], index) => {
          if (_.contains(tied_indices, index)) {
            return p_rolling_value(
              opponent_dice_count,
              i + self_bonus - opponent_bonus,
            );
          } else {
            return p_rolling_less_than_value(
              opponent_dice_count,
              i + self_bonus - opponent_bonus,
            );
          }
        },
      );
      return my_p * array_product(opponent_ps);
    });

    return [p, tied_indices];
  });
}

function p_win_multi_battle(
  self_dice_count: number,
  self_bonus: number,
  opponent_dice_bonus_tuples: [number, number][],
) {
  if (opponent_dice_bonus_tuples.length === 0) {
    return 1;
  }

  let p_win_all = p_win_multi_roll(
    self_dice_count,
    self_bonus,
    opponent_dice_bonus_tuples,
  );

  let p_tie_indices_tuples = p_all_ties_multi_roll(
    self_dice_count,
    self_bonus,
    opponent_dice_bonus_tuples,
  );

  let p_tie_all = 0;
  let p_win_after_tie = array_sum(
    _.map(p_tie_indices_tuples, ([p, tied_indices]) => {
      if (tied_indices.length === opponent_dice_bonus_tuples.length) {
        p_tie_all = p;
        return 0;
      }
      return (
        p *
        p_win_multi_battle(
          self_dice_count,
          self_bonus,
          array_select_indices(opponent_dice_bonus_tuples, tied_indices),
        )
      );
    }),
  );

  return (p_win_all + p_win_after_tie) / (1 - p_tie_all);
}

export const probabilityWinningMultiBattle = p_win_multi_battle;
